import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Api from "../../helpers/Api";
import AppLayout from '../../components/layout/AppLayout';
import SubHeader from '../../components/subheader/SubHeader';
import styled from "styled-components";
import CommonTable from '../components/table/CommonTable';
import LoadingPage from "../loading/LoadingPage";
import AppTheme from '../../themes/AppTheme';
import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import SearchIcon from '../../images/search-icon.svg';
import CopyIcon from '../../images/duplicate-icon.svg';
import RevokeIcon from '../../images/revoke-icon.svg';
import NotFoundIcon from '../../images/not-found.svg';
import DraftIcon from '../../images/draft-icon.svg';
import IssueIcon from '../../images/issue-icon.svg';
import RevokedIcon from '../../images/revoked-icon.svg';
import DuplicateGraphics from '../../images/duplicate-graphics.svg'
import ConfirmationModal from '../components/modal/Modal';
import {toProperCase} from "../../helpers/Utils";
import NotFound from '../components/Notfound/NotFound';

export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  float: left;
  width: 100%;
  padding: 0;

  > table {
    >thead{
        >tr{
            > th:nth-child(4) {
                width: 130px;
            }
        }
    }
    > tbody {
      > tr {
        > td:nth-child(2) {
          word-break: break-all;
          min-width:140px;
        }
        
      }
    }
  }
`;

export const FiltersBox = styled.div`
  width: 100%;
  float: left;
  padding: 16px 15px;
  border-top: 1px solid ${AppTheme.header.colors.inputBorder};

  > .filters-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    > .search-box {
      > div {
        margin-bottom: 0px;

        > div {
          border-radius: 8px;
          border: 1px solid ${AppTheme.header.colors.inputBorder};
          background: ${AppTheme.header.colors.whiteColor};
          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          height: 44px;
          width: 230px;
        }
      }
    }

    > .box-col {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > div {
        margin-bottom: 0px;
        margin-top: 0px;

        > select {
          border-radius: 8px;
          border: 1px solid ${AppTheme.header.colors.inputBorder};
          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          height: 44px;
          min-width: 210px;
        }
      }

      > .buttons {
        margin-right: 10px;

        > div {
          margin-top: 0px;

          > button {
            min-width: 160px;
            height: 44px;
          }
        }

      }
    }
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  max-width: 600px;
`;

export const EmbedHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding-bottom: 0px;

  > img {
    margin-right: 10px;
  }

  > span {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: ${AppTheme.header.colors.blackColor};
  }
`;

export const DescriptionCol = styled.div`
  padding-bottom: 20px;

  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: ${AppTheme.header.colors.lightGreyColor};
  }
`;

export const CopyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > a {
    text-decoration: none;
    border-radius: 8px;
    border: 1px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 44px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blackColor};
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${AppTheme.header.colors.whiteColor};
    height: 44px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    background: ${AppTheme.header.colors.blueColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: ${AppTheme.header.colors.blueColor};
    border: 1px solid ${AppTheme.header.colors.blueColor};
    width: 50%;

    > img {
      margin-right: 6px;
    }
  }
`;
export const CodeTextarea = styled.textarea`
  width: 100%;
  height: 126px;
  margin-bottom: 10px;
  font-family: monospace;
  border-radius: 12px;
  background: ${AppTheme.header.colors.blackColor};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${AppTheme.header.colors.whiteColor};
  padding: 0 10px;
`;

export const TabsList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 15px 20px 15px;

  > .inactive {
    padding: 8px 12px;
    background: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${AppTheme.header.colors.blackColor};
    border-radius: 6px;
    display: block;
    text-transform: capitalize;
    text-decoration: none;
  }

  > .active {
    color: ${AppTheme.header.colors.blueColor};
    background: #F0F5FF;
    display: block;
    padding: 8px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 6px;
    text-transform: capitalize;
    text-decoration: none;
  }
`;

export const Form = styled.div`
  padding-top: 15px;
  padding-bottom: 10px;

  > input {
    border-radius: 8.876px;
    border: 1.109px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 1.109px 2.219px 0px rgba(16, 24, 40, 0.05);
    padding: 0 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 48px;
  }
`;

const ListCertificates = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let {type = 'all', templateId} = useParams();

    const navigate = useNavigate();

    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [filters, setFilters] = useState({
        status: searchParams.get('status') || '',
        query: searchParams.get('query') || ''
    });

    const [template, setTemplate] = useState(null);
    const [selected, setSelected] = useState([]);
    const [revokeModal, setRevokeModal] = useState(false);
    const [duplicateModal, setDuplicateModal] = useState(false);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState(null);

    const [showCancelScheduleModal, setShowCancelScheduleModal] = useState(false);

    useEffect(() => {
        if (templateId) {
            Api.getTemplate(templateId).then(data => {
                setTemplate(data);
            });
        }
    }, [templateId]);
    const getFormattedDateTime = () => {

        const now = new Date();
        const localDateTime = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString();
        return localDateTime.slice(0, 16); // Returns 'YYYY-MM-DDTHH:MM'
    };
    const [dateTime, setDateTime] = useState(getFormattedDateTime());

    const certificateColumns = [
        {key: 'awardeeName', label: 'Name'},
        {key: 'awardeeEmail', label: 'Email'},
        {key: 'awardeeRollNumber', label: 'Roll Number'},
        {key: 'templateData.name', label: 'Template'},
        // {key: 'status', label: 'Status'},
        {key: 'issuedAt', label: 'Issued On', type: 'datetime'}
    ];
    if (type === 'issued' || type === 'duplicate') {
        certificateColumns.push({key: 'issuedAt', label: 'Issued At', type: 'datetime'});
        //Add quantum ledger id as first column
        certificateColumns.unshift({key: 'quantumLedgerId', label: 'Quantum Ledger ID'});
    }
    if (type === 'scheduled') {
        certificateColumns.push({key: 'scheduledAt', label: 'Scheduled At'});
    }

    useEffect(() => {
        fetchCertificates();
    }, [type]);

    const fetchCertificates = async () => {
        setLoading(true);
        try {
            const data = await Api.listCertificates(type, templateId);
            setCertificates(data);
            setError('');
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch templates. Please try again later.');
        } finally {
            setLoading(false);
            setLoading(false);
        }
    };

    const handleAward = (id) => {
        setLoading(true);
        Api.awardCertificate(id).then(() => {
            fetchCertificates();
        }).catch(err => {
            setError('Failed to award certificate. Please try again.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleAwardMulti = () => {
        setLoading(true);
        Api.awardCertificateMulti(selected).then(() => {
            fetchCertificates();
        }).catch(err => {
            setError('Failed to award certificates. Please try again.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleDeleteMulti = () => {
        setLoading(true);
        Api.deleteCertificateMulti(selected).then(() => {
            fetchCertificates();
        }).catch(err => {
            setError('Failed to delete certificates. Please try again.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleSchedule = () => {
        setLoading(true);
        const dateTimeObject = new Date(dateTime);

        const utcDateTime = dateTimeObject.toISOString();


        Api.scheduleCertificateMulti(selected, utcDateTime).then(() => {
            fetchCertificates();
        }).catch(err => {
            setError('Failed to award certificate. Please try again.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleCancelSchedule = () => {
        setLoading(true);
        Api.cancelScheduleCertificateMulti(selected).then(() => {
            fetchCertificates();
        }).catch(err => {
            setError('Failed to award certificate. Please try again.');
        }).finally(() => {
            setLoading(false);
        })
    }

    function revokeCertificate(certificate) {
        setLoading(true);
        Api.revokeCertificate(certificate.id).then(() => {
            fetchCertificates();
        }).catch(err => {
            setError('Failed to revoke certificate. Please try again.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleRevoke = (certificate) => {
        confirmAlert({
            title: "Revoke Certificate",
            message: "Are you sure you want to revoke this certificate?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        revokeCertificate(certificate);
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                    },
                },
            ],
        });
    }

    function duplicateCertificate(certificate) {
        setLoading(true);
        Api.duplicateCertificate(certificate.id).then(() => {
            fetchCertificates();
        }).catch(err => {
            setError('Failed to duplicate certificate. Please try again.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleDuplicate = (certificate) => {
        confirmAlert({
            title: "Duplicate Certificate",
            message: "Are you sure you want to duplicate this certificate?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        duplicateCertificate(certificate);
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                    },
                },
            ],
        });
    }
    const openModal = (certificate) => {
        setSelectedCertificate(certificate);
        setRevokeModal(true);
    };

    const closeModal = () => {
        setRevokeModal(false);
    };

    const openDuplicateModal = (certificate) => {
        setSelectedCertificate(certificate);
        setDuplicateModal(true);
    };

    const closeDuplicateModal = () => {
        setDuplicateModal(false);
    };

    if (loading) return <LoadingPage/>
    if (error) return <div>Error: {error}</div>;

    const filteredCertificates = certificates.filter(certificate => {
        if (filters.query && !certificate.awardeeName.toLowerCase().includes(filters.query.toLowerCase())
            && !certificate.awardeeEmail.toLowerCase().includes(filters.query.toLowerCase())
            && !certificate.awardeeRollNumber.toLowerCase().includes(filters.query.toLowerCase())) {
            return false;
        }
        if (filters.status && certificate.status !== filters.status) {
            return false;
        }
        return true;
    });

    const types = [
        'all',
        'draft',
        'issued',
        'scheduled',
        'revoked',
        'duplicate',
        'expired'
    ];

    let Icon = NotFoundIcon;
    if(type === 'draft'){
        Icon = DraftIcon;
    } else if (type === 'scheduled') {
        Icon = IssueIcon;
    } else if (type === 'revoked') {
        Icon = RevokedIcon;
    }else if (type === 'duplicate') {
        Icon = DuplicateGraphics;
    }
    return (
        <AppLayout>
            <Container>
                {templateId && <SubHeader heading={template?.name}
                                          description={'List of each certificate awarded'}
                                          href={`/templates/${templateId}/award`}
                                          label={'Award Certificate'}/>}
                {!template && <SubHeader
                    heading={toProperCase(type) + ' Certificates'}
                />}

                <TabsList>
                    {types.map((t, i) => {
                        let url = '/certificates/' + t;
                        if (templateId) {
                            url = '/templates/' + templateId + '/certificates/' + t;
                        }
                        return (
                            <Link
                                key={i}
                                to={url}
                                className={t === type ? 'active' : 'inactive'}
                                style={{marginRight: '10px'}}
                            >
                                {t}
                            </Link>
                        );
                    })}
                </TabsList>

                <FiltersBox>
                    <div className='filters-box'>
                        <di className='search-box'>
                            <Input type='text' placeholder='Search'
                                   Icon={SearchIcon}
                                   value={filters.query} name={'query'} onChange={e => {
                                setFilters({
                                    ...filters,
                                    query: e.target.value
                                })
                            }}
                            />
                        </di>
                        <div className='box-col'>

                            {selected.length > 0 && type === 'draft' && (
                                <div className='buttons'>
                                    <Button onClick={handleDeleteMulti} label={`Delete Selected (${selected.length})`}/>
                                </div>
                            )}
                            {selected.length > 0 && type === 'draft' && (
                                <div className='buttons'>
                                    <Button onClick={handleAwardMulti} label={`Issue Selected (${selected.length})`}/>
                                </div>
                            )}
                            {selected.length > 0 && type === 'draft' && (
                                <div className='buttons'>
                                    <Button onClick={() => {
                                        setShowScheduleModal(true)
                                    }} label={`Schedule Selected (${selected.length})`}/></div>
                            )}
                            {selected.length > 0 && type === 'scheduled' && (
                                <div className='buttons'>
                                    <Button onClick={() => {
                                        setShowCancelScheduleModal(true)
                                    }} label={`Cancel Scheduled (${selected.length})`}/></div>
                            )}
                            {selected.length > 0 && (type === 'issued' || type === 'duplicate') && (
                                <div className='buttons'>
                                    <Button onClick={() => {
                                        Api.exportCertificates(selected,'default').then((response) => {
                                            navigate(`/exports/${response.id}`)
                                        });
                                    }} label={`Export (${selected.length})`}/></div>
                            )}
                            {selected.length > 0 && (type === 'issued' || type === 'duplicate') && (
                                <div className='buttons'>
                                    <Button onClick={() => {
                                        Api.exportCertificates(selected, 'print').then((response) => {
                                            navigate(`/exports/${response.id}`)
                                        });
                                    }} label={`Export for print (${selected.length})`}/></div>
                            )}
                        </div>
                    </div>
                </FiltersBox>
                {filteredCertificates.length > 0 ? (
                    <CommonTable
                        selectable={['draft', 'scheduled','issued','duplicate'].includes(type)}
                        onSelectionChange={(s) => {
                            setSelected(s);
                        }}
                        columns={certificateColumns}
                        data={filteredCertificates}
                        onIssued={(certificate) =>
                            certificate.status !== 'draft'
                                ? '/credential/' + certificate.quantumLedgerId
                                : null
                        }
                        onPreview={(certificate) =>
                            certificate.status === 'draft'
                                ? '/certificates/' + certificate.id + '/preview'
                                : null
                        }
                        onRevoke={openModal}
                        onDuplicate={openDuplicateModal}
                        onAward={handleAward}
                    />
                ) : (
                    <NotFound
                        icon={Icon}
                        message={'Looks like you have no certificate  yet'}
                    />
                )}
            </Container>
            {/* Revoke certificate Modal */}
            <ConfirmationModal
                isOpen={revokeModal}
                closeModal={closeModal}
            >
                <div>
                    <ModalContent>
                        <EmbedHeader>
                            <img src={RevokeIcon}/>
                            <span>Revoke Certificate</span>
                        </EmbedHeader>
                        <DescriptionCol>
                            <span>Are you sure you want to revoke this certificate?</span>
                        </DescriptionCol>
                        <CopyButton>
                            <Link onClick={closeModal}>
                                No
                            </Link>
                            <button
                                onClick={() => {
                                    closeModal();
                                    revokeCertificate(selectedCertificate)
                                }}
                            >
                                Yes
                            </button>
                        </CopyButton>
                    </ModalContent>
                </div>
            </ConfirmationModal>

            {/* Duplicate certificate Modal */}
            <ConfirmationModal
                isOpen={duplicateModal}
                closeModal={closeDuplicateModal}
            >
                <div>
                    <ModalContent>
                        <EmbedHeader>
                            <img src={CopyIcon}/>
                            <span>Duplicate Certificate</span>
                        </EmbedHeader>
                        <DescriptionCol>
                            <span>Are you sure you want to duplicate this certificate?</span>
                        </DescriptionCol>
                        <CopyButton>
                            <Link onClick={closeDuplicateModal}>
                                No
                            </Link>
                            <button
                                onClick={() => {
                                    closeDuplicateModal();
                                    duplicateCertificate(selectedCertificate);
                                }}
                            >
                                Yes
                            </button>
                        </CopyButton>
                    </ModalContent>
                </div>
            </ConfirmationModal>

            <ConfirmationModal
                isOpen={showScheduleModal}
                closeModal={() => {
                    setShowScheduleModal(false);
                }}
            >
                <div>
                    <ModalContent>
                        <EmbedHeader>
                            <img src={CopyIcon}/>
                            <span>Schedule Certificates</span>
                        </EmbedHeader>
                        <Form>
                            <input type={'datetime-local'} placeholder={'Select Date & Time'} value={
                                dateTime
                            } onChange={(e) => {
                                setDateTime(e.target.value);
                            }}/>

                        </Form>
                        <CopyButton>
                            <Link onClick={() => {
                                setShowScheduleModal(false);

                            }}>
                                Cancel
                            </Link>
                            <button
                                onClick={() => {
                                    setShowScheduleModal(false);
                                    handleSchedule();
                                }}
                            >
                                Schedule
                            </button>
                        </CopyButton>
                    </ModalContent>
                </div>
            </ConfirmationModal>

            <ConfirmationModal
                isOpen={showCancelScheduleModal}
                closeModal={() => {
                    setShowCancelScheduleModal(false);
                }}
            >
                <div>
                    <ModalContent>
                        <EmbedHeader>
                            <img src={CopyIcon}/>
                            <span>Cancel Schedule Certificates?</span>
                        </EmbedHeader>
                        <DescriptionCol>
                            <span>Are you sure you want to cancel the schedule for the selected certificates?</span>
                        </DescriptionCol>
                        <CopyButton>
                            <Link onClick={() => {
                                setShowCancelScheduleModal(false);

                            }}>
                                No
                            </Link>
                            <button
                                onClick={() => {
                                    setShowCancelScheduleModal(false);
                                    handleCancelSchedule()
                                }}
                            >
                                Yes
                            </button>
                        </CopyButton>
                    </ModalContent>
                </div>
            </ConfirmationModal>
        </AppLayout>
    );
};

export default ListCertificates;
