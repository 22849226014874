import {observer} from 'mobx-react-lite';
import {SectionTab} from 'polotno/side-panel';
import SizesIcon from '../../../../images/size-new.svg';

const AVAILABLE_SIZES = [
    {width: 595, height: 842, name: 'A4 Portrait'},
    {width: 842, height: 595, name: 'A4 Landscape'}
];

// define the new custom section
const SizesPanel = {
    name: 'sizes',
    Tab: (props) => (
        <SectionTab name="Sizes" {...props}>
            <img src={SizesIcon} />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({store}) => {
        // we will just render buttons for each size
        // but you also can add your own controls
        // size inputs, etc
        return (
            <div>
                {AVAILABLE_SIZES.map(({width, height, name}, i) => (
                    <button
                        key={i}
                        style={{width: '100%', marginBottom: '20px'}}
                        onClick={() => {
                            store.setSize(width, height, 'px');
                        }}
                    >
                        {name} - {width}x{height}
                    </button>
                ))}
            </div>
        );
    }),
};

export default SizesPanel;
