import React, {useEffect, useState} from "react";
import Api from "../../helpers/Api";
import {Link} from "react-router-dom";
import Button from "../../components/form/Button";
import AppLayout from "../../components/layout/AppLayout";
import SubHeader from "../../components/subheader/SubHeader";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';
import LoadingPage from "../loading/LoadingPage";

export const Container = styled.div`
    border-radius: 5px;
    border: 1px solid  ${AppTheme.header.colors.tableBorder};
    background:  ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    float:left;
    width:100%;
    padding:0;
    >form{
        background:${AppTheme.header.colors.whiteColor};
        padding:0px 15px 20px 15px;
    }
`;

export const FormGroup = styled.div`
    padding: 20px 0px;
    border-bottom:1px solid ${AppTheme.header.colors.tableBorder};
    display:flex;
    align-items:center;
    justify-content:flex-start;
    >div{
        margin-bottom:0px;
    }
    >label{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: ${AppTheme.header.colors.blackColor};
        margin-bottom: 0px;
        text-align: left;
        width: 280px;
        padding-right: 20px;
    }
    >span{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: ${AppTheme.header.colors.blackColor};
        margin-bottom: 0px;
        text-align: left;
    }
`;

export const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    margin-top:20px;
    >a{
        text-decoration:none;
        border-radius: 6px;
        background:${AppTheme.header.colors.blueColor};
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height:40px;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:0 10px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color:${AppTheme.header.colors.whiteColor};
    }
    >div{
        margin-top:0px;
        >button{
            margin-bottom:0px;
            height:40px;
            padding:0 15px;
            border-radius:6px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
        }
    } 
`;

const Profile = () => {

    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');


    const fetchUser = async () => {
        setLoading(true);
        try {
            const data = await Api.getProfile();
            setUser(data);
        } catch (err) {
            setError('Failed to fetch user. Please try again later.');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchUser();
    }, []);


    if (loading) return <LoadingPage/>
    if (error) return <div>Error: {error}</div>;

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={'User Profile'}
                    description={'Manage your account settings and personalize your profile'}
                />
                <form>
                    <FormGroup>
                        <label>Name:</label>
                        <span>{user?.name}</span>
                    </FormGroup>
                    <FormGroup>
                        <label>Email:</label>
                        <span>{user?.email}</span>
                    </FormGroup>
                    <FormGroup>
                        <label>Phone:</label>
                        <span>{user?.phone}</span>
                    </FormGroup>
                    <ButtonContainer>
                        <Link to={'/profile/edit'}>
                            Edit Profile
                        </Link>
                    </ButtonContainer>
                </form>
            </Container>
        </AppLayout>
    );
}

export default Profile;
