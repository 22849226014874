import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom'; // Import useParams
import Api from "../../../helpers/Api";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import ACLInput from "../../../components/form/ACLInput";
import AppLayout from '../../../components/layout/AppLayout';
import styled from "styled-components";
import SubHeader from '../../../components/subheader/SubHeader';
import AppTheme from "../../../themes/AppTheme";

export const Container = styled.div`
    border-radius: 5px;
    border: 1px solid  ${AppTheme.header.colors.tableBorder};
    background:  ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    float:left;
    width:100%;
    padding:0;
    >form{
        background:${AppTheme.header.colors.whiteColor};
        padding:0px 15px 20px 15px;
    }
`;

export const FormGroup = styled.div`
    padding:20px 0px;
    border-bottom:1px solid ${AppTheme.header.colors.tableBorder};
`;

export const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    margin-top:20px;
    >a{
        text-decoration:none;
        border-radius: 6px;
        border: 1px solid  ${AppTheme.header.colors.inputBorder};
        background:${AppTheme.header.colors.whiteColor};
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height:40px;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:0 10px;
        margin-right:15px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color:${AppTheme.header.colors.darkGrey};
    }
    >div{
        margin-top:0px;
        >button{
            margin-bottom:0px;
            height:40px;
            padding:0 15px;
            border-radius:6px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
        }
    }
    
`;

const CreateRole = () => {
    const {id} = useParams(); // Get the :id parameter from the route
    const navigate = useNavigate();
    const [roleName, setRoleName] = useState('');
    const [acl, setAcl] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        // If id is present, load the existing role data
        if (id) {
            setLoading(true);
            Api.getRole(id).then(role => {
                setRoleName(role.name);
                setAcl(role.acl);
                setLoading(false);
            }).catch(err => {
                setError('Failed to load role. Please try again.');
                setLoading(false);
            });
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let role;
        try {
            // Check if role name is empty
            if (!roleName.trim()) {
                setError('Role name cannot be empty.');
                setLoading(false);
                return;
            }
            if (id) {
                // If id is present, update the existing role
                role = await Api.updateRole(id, roleName, acl);
            } else {
                // If no id, create a new role
                role = await Api.createRole(roleName, acl);
            }
            navigate(`/roles`);
        } catch (error) {
            setError('Failed to process role. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={'Create Role'}
                    description={'Customize user roles and permissions to streamline access and responsibilities'}
                />
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Input
                            label="Role Name"
                            astricSign='*'
                            type="horizontalinput"
                            value={roleName}
                            onChange={(e) => setRoleName(e.target.value)}
                            name="roleName"
                            placeholder={'Enter role name'}
                            //  error={error} 
                        />
                    </FormGroup>
                    <ACLInput value={acl} onChange={e => setAcl(e.target.value)}/>
                    <ButtonContainer>
                    <Link to={`/roles`}>
                        Cancel
                    </Link>
                    <Button disabled={loading} type={'submit'} loading={loading}
                            label={id ? "Update Role" : "Create Role"}
                    />
                    </ButtonContainer>
                    {error && <div style={{color: 'red'}}>{error}</div>}
                </form>
            </Container>
        </AppLayout>
    );
};

export default CreateRole;
