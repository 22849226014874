import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom'; // Import useParams
import Api from "../../../helpers/Api";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import AppLayout from '../../../components/layout/AppLayout';
import SubHeader from '../../../components/subheader/SubHeader';
import styled from "styled-components";
import AppTheme from '../../../themes/AppTheme';
import LoadingPage from "../../loading/LoadingPage";

export const Container = styled.div`
    border-radius: 5px;
    border: 1px solid  ${AppTheme.header.colors.tableBorder};
    background:  ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    float:left;
    width:100%;
    padding:0;
    >form{
        background:${AppTheme.header.colors.whiteColor};
        padding:0px 15px 20px 15px;
    }
`;

export const FormGroup = styled.div`
    padding:20px 0px;
    border-bottom:1px solid ${AppTheme.header.colors.tableBorder};
    >div{
        margin-bottom:0px;
    }
`;

export const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    margin-top:20px;
    >a{
        text-decoration:none;
        border-radius: 6px;
        border: 1px solid  ${AppTheme.header.colors.inputBorder};
        background:${AppTheme.header.colors.whiteColor};
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height:40px;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:0 10px;
        margin-right:15px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color:${AppTheme.header.colors.blackColor};
    }
    >div{
        margin-top:0px;
        >button{
            margin-bottom:0px;
            height:40px;
            padding:0 15px;
            border-radius:6px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
        }
    }
    
`;

const CreateDepartment = () => {
    const {id} = useParams(); // Get the :id parameter from the route
    const navigate = useNavigate();
    const [departmentName, setDepartmentName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [initialising, setInitialising] = useState(true);

    useEffect(() => {
        // If id is present, load the existing department data
        if (!id) {
            setInitialising(false);
            return;
        }

            setLoading(true);
            Api.getDepartment(id).then(department => {
                setDepartmentName(department.name);
                setLoading(false);
            }).catch(err => {
                setError('Failed to load department. Please try again.');
                setLoading(false);
            }).finally(()=>{
                setInitialising(false);
            })

    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let department;
        try {
            if (!departmentName.trim()) {
                setError('Department name cannot be empty.');
                setLoading(false);
                return;
            }
            if (id) {
                // If id is present, update the existing department
                department = await Api.updateDepartment(id, departmentName);
            } else {
                // If no id, create a new department
                department = await Api.createDepartment(departmentName);
            }
            navigate(`/departments`);
        } catch (error) {
            setError('Failed to process department. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (initialising) return <LoadingPage/>

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={id ? 'Edit Department':'Create Department'}
                    description={id ? 'Edit a Credential Issuing Department' : 'Set Up New Credential Issuing Department'}
                />           
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Input
                            label="Name of Department"
                            astricSign='*'
                            type="horizontalinput"
                            value={departmentName}
                            onChange={(e) => setDepartmentName(e.target.value)}
                            name="departmentName"
                            placeholder={'Enter department name'}
                            //  error={error.departmentName}
                        />
                    </FormGroup>
                    {/* <FormGroup>
                        <Input
                            label="About, A write-up about the event, programme, or credential (less than 50 words)."
                            type="textarea"
                            name="about"
                            placeholder={'Example: Speaker Badge Global DevOps Summit or Degree certificate'}
                            error={error}
                        />
                    </FormGroup> */}
                    <ButtonContainer>
                        <Link to={`/departments`}>
                            Cancel
                        </Link>
                        <Button disabled={loading} type={'submit'} loading={loading}
                                label={id ? "Update Department" : "Create"}/>


                    </ButtonContainer>

                    {error && <div style={{color: 'red'}}>{error}</div>}
                </form>
            </Container>
        </AppLayout>
    );
};

export default CreateDepartment;
