import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Api from "../../../helpers/Api";
import Button from "../../../components/form/Button";
import AppLayout from '../../../components/layout/AppLayout';
import AppTheme from '../../../themes/AppTheme';
import styled from "styled-components";
import SubHeader from '../../../components/subheader/SubHeader';
import LoadingPage from "../../loading/LoadingPage";
import Input from "../../../components/form/Input";
import {getAttributesFromCanvas} from "../../../helpers/Utils";

export const Container = styled.div`
  width: 100%;
  padding: 0 14px;

  > form {
    background: ${AppTheme.header.colors.whiteColor};
    padding: 10px 20px;
    width: 100%;
    float: left;
  }
`;

export const StepsCol = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Col = styled.div`
  height: 60px;
  background: ${AppTheme.header.colors.lightBlueColor};
  display: flex;
  align-items: center;
  padding: 0 20px 0 50px;
  position: relative;

  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blueColor};
  }

  &.active {
    background: ${AppTheme.header.colors.blueColor};
    padding: 0 40px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -30px; /* Adjust the distance of the arrow from the right edge */
      width: 0;
      height: 0px;
      border-top: 30px solid transparent; /* Adjust size of arrow */
      border-bottom: 30px solid transparent; /* Adjust size of arrow */
      border-left: 30px solid ${AppTheme.header.colors.blueColor}; /* Adjust color and size of arrow */
      transform: translateY(-50%);
      z-index: 999;
    }
  }

  &.active {
    > span {
      color: ${AppTheme.header.colors.whiteColor};
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -30px; /* Adjust the distance of the arrow from the right edge */
    width: 0;
    height: 0px;
    border-top: 30px solid transparent; /* Adjust size of arrow */
    border-bottom: 30px solid transparent; /* Adjust size of arrow */
    border-left: 30px solid ${AppTheme.header.colors.lightBlueColor}; /* Adjust color and size of arrow */
    transform: translateY(-50%);
    z-index: 999;
  }

`;

export const FormGroup = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};

  > div {
    margin-bottom: 0px;

    > span {
      width: calc(100% - 224px);
      margin: 8px 0 0 auto;
    }
  }

  > .select-col {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    > div {
      margin-bottom: 0px;

      > select {
        width: 100%;
        border-radius: 2px;
        border: 1px solid ${AppTheme.header.colors.inputBorder};
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height: 50px;
      }
    }

    > label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: ${AppTheme.header.colors.blackColor};
      margin-bottom: 0px;
      text-align: left;
      width: 280px;
      padding-right: 20px;

      > span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: ${AppTheme.header.colors.errorTextColor};
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  > a {
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blackColor};
  }

  > div {
    margin-top: 0px;

    > button {
      margin-bottom: 0px;
      height: 40px;
      padding: 0 15px;
      border-radius: 6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
`;

export const VerifyFieldContainer = styled.div`
  width:100%;
  padding:20px 0;
  >h4{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: ${AppTheme.header.colors.blackColor};
    margin-bottom:20px;
  }
  >.required-field{
    width:100%;
    >.col{
      display:flex;
      align-items:center;
      width:25%;
      padding-bottom:15px;
      float:left;
      >.check-input{
        padding-right:10px;
        >div{
          margin-bottom:0px;
          >input{
          }
        }
      }
      >span{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: ${AppTheme.header.colors.blackColor};
      }
    }
  }
  
`;

const ConfigureVerification = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [template, setTemplate] = useState({
        name: '',
        departmentId: '',
        data: {}
    });
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialising, setInitialising] = useState(true);
    const [error, setError] = useState('');
    const [verificationConfiguration, setVerificationConfiguration] = useState({attributes: []});
    const [errors, setErrors] = useState({})


    useEffect(() => {
        if (!id) {
            setInitialising(false);
            return;
        }

        setLoading(true);
        Api.getTemplate(id).then(templateData => {
            setTemplate({
                name: templateData.name,
                data: templateData.data,
                departmentId: templateData.department.id,
                canvasData: templateData.canvasData,
            });
            setVerificationConfiguration(templateData.data.verificationConfiguration || {attributes: []});
            setLoading(false);
        }).catch(err => {
            setError('Failed to load template. Please try again.');
            setLoading(false);
        }).finally(() => setInitialising(false));

    }, [id]);

    const attributes = getAttributesFromCanvas(template.canvasData);

    if (initialising) {
        return <LoadingPage/>
    }

    return <AppLayout>
        <SubHeader
            heading={'Credential Template Editor'}
            description={'Create Professional Credentials with Ease'}
        />
        <Container>
            <StepsCol>
                <Col>
                    <span>{id ? 'Step 1: Update Program Details' : 'Step 1: Enter Program Details'}</span>
                </Col>
                <Col>
                    <span>Step 2: Design Your Template</span>
                </Col>
                <Col className='active'>
                    <span>Step 3: Configure verification</span>
                </Col>
            </StepsCol>
            <form onSubmit={async e=>{
                e.preventDefault();
                setLoading(true);
                const result = await Api.updateTemplateVerificationConfiguration(id, verificationConfiguration);
                navigate(`/templates`);
            }}>
                <VerifyFieldContainer>
                    <h4>Field Required for verification</h4>
                    <div className='required-field'>
                      {attributes.map(attribute => {
                          return <div className='col'>
                              
                              <div className='check-input'>
                                  <Input type={'checkbox'} name={attribute} value={verificationConfiguration.attributes.includes(attribute)}
                                        onChange={(e) => {
                                              if (e.target.checked) {
                                                    setVerificationConfiguration({
                                                      ...verificationConfiguration,
                                                      attributes: [...verificationConfiguration.attributes, attribute]
                                                    });
                                              } else {
                                                    setVerificationConfiguration({
                                                      ...verificationConfiguration,
                                                      attributes: verificationConfiguration.attributes.filter(attr => attr !== attribute)
                                                    });
                                              }
                                        }}/>
                              </div>
                              <span>{attribute}</span>
                          </div>
                      })}
                    </div>
                </VerifyFieldContainer>

                <ButtonContainer>
                    <Link to={`/templates`}>
                        Cancel
                    </Link>
                    <Button disabled={loading} type={'submit'} loading={loading}
                            label={id ? "Save" : "Create Template"}/>
                </ButtonContainer>

                {error && <div style={{color: 'red'}}>{error}</div>}
            </form>
        </Container>

    </AppLayout>
};

export default ConfigureVerification;
