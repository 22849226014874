import React from 'react'
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';

// Assets
import Logo from '../../images/award-logo.svg';
import ChitkaraLogo from '../../images/chitkara-logo.svg';

export const Layout = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100vh;
    flex:1;
`

export const LoginLeft = styled.div`
    width:60%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction:column;
    background:${AppTheme.header.colors.lightBlueColor};
    flex:1;
    height:100%;
    padding:20px 20px;
`;

export const LogoContainer = styled.div`
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    
`;

export const BasicInfo = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    flex:1;
    width:60%;
    >img{
        margin-bottom:34px;
    }
    >h1{
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        color:${AppTheme.header.colors.blackColor};
        margin-bottom:15px;
        text-align:left;
    }
    p{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        color:${AppTheme.header.colors.blackColor};
        margin:0px;
        text-align:left;
    }
`;

export const LoginRight = styled.div`
    width: 40%;
    overflow: auto;
    background: ${AppTheme.header.colors.whiteColor};
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const AuthLayout = ({ children }) => {

    return <Layout>
            <LoginLeft>
                <BasicInfo>
                    <img src={ChitkaraLogo} alt="chitkara-logo" />
                    <h1> Chitkara University Document Verification </h1>
                    <p>Welcome to the Generate and Verify Certificates Portal! This secure portal provides you with the ability to generate and verify certificates effortlessly.
                        Whether you're issuing certificates for authentication, encryption, or any other purpose, our platform streamlines the process for you</p>
                </BasicInfo>
            </LoginLeft>
            <LoginRight>
                {children}
            </LoginRight>
    </Layout >

}

export default AuthLayout;
