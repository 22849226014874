import React from 'react';
import AppLayout from '../../components/layout/AppLayout';
import SubHeader from '../../components/subheader/SubHeader';
import styled from "styled-components";
import Loader from 'react-js-loader';

export const Container = styled.div`
  width: 100%;
  padding: 0 14px;
  display:flex;
  align-items:center;
  justify-content:center;
  height:calc(80vh - 60px);
`;

const LoadingPage = ({heading}) => {
    return (
        <AppLayout>
            <SubHeader
                heading={heading}
            />

            {/* Table component */}
            <Container>
                {/* <progress value={null}/>
                <br/>
                Loading.. */}
                <Loader
                    type="bubble-scale"
                    bgColor={'#000000'}
                    color={'#000000'}
                    size={64}
                />
            </Container>
        </AppLayout>
    );
};

export default LoadingPage;
