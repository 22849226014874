import React from 'react';
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';

export const ButtonContainer = styled.div`
    margin-top:12px;
    >a{
        border-radius: 6px;
        background:${AppTheme.header.colors.blueColor};
        height:48px;
        display:flex;
        align-items:center;
        justify-content:center;
        font-size: 14px;
        font-weight:700;
        font-style: normal;
        letter-spacing: -0.14px;
        color:${AppTheme.header.colors.whiteColor};
        padding:0 12px;
        text-decoration:none;
        >img{
            margin-right:10px;
        }

        &:focus{
            outline:none;
        }
    }
    >button{
        border-radius: 6px;
        background: ${AppTheme.header.colors.blueColor};
        height:48px;
        display:flex;
        align-items:center;
        justify-content:center;
        font-size: 14px;
        font-weight:700;
        font-style: normal;
        letter-spacing: -0.14px;
        color:${AppTheme.header.colors.whiteColor};
        border:none;
        width:100%;

        &:focus{
            outline:none;
        }
    }
`;

const Button = ({disabled, loading, label, onClick,icon, type = 'button', href}) => {
    const buttonContent = loading ? 'Loading...' : label;

    if (type === 'link' && href) {
        return (
            <ButtonContainer>
                <a
                    href={href}
                    onClick={(e) => {
                        if (disabled || loading) {
                            e.preventDefault();
                        }
                        if(onClick){
                            e.preventDefault();
                            onClick(e);
                        }
                    }}
                    style={{pointerEvents: disabled || loading ? 'none' : 'auto'}}
                >
                    {icon &&
                        <img src={icon} alt="Icon" />
                    }
                    {buttonContent}
                </a>
            </ButtonContainer>
        );
    }

    return (
        <ButtonContainer>
            <button
                disabled={disabled || loading}
                onClick={onClick}
                type={type === 'submit' ? 'submit' : 'button'}
            >
                {buttonContent}
            </button>
        </ButtonContainer>
    );
};

export default Button;
