import React, {useEffect, useState} from 'react';
import Api, {API_BASE_URL} from "../../helpers/Api";
import {Link, useParams} from "react-router-dom";
import styled from "styled-components";
import AppTheme from "../../themes/AppTheme";
import Header from './components/header/Header';

// Assets
import LinkdinIcon from '../../images/linkdin-blue.svg';
import TwitterIcon from '../../images/twitter-black.svg';
import WhatsappIcon from '../../images/whatsapp-icon.svg';
import Inboxicon from '../../images/inbox-grey.svg';
import FillSocial from '../../images/linkdin-fill.svg';
import FillSDownload from '../../images/download-red.svg';
import FillShare from '../../images/share-fill.svg';
import Issue1 from '../../images/issue1.svg';
import Issue2 from '../../images/issue2.svg';
import Issue3 from '../../images/issue3.svg';
import Issue4 from '../../images/issue4.svg';
import Issue5 from '../../images/issue5.svg';
import Issue6 from '../../images/issue6.svg';
import SecureIcon from '../../images/secure-icon.svg';
import CloseIcon from '../../images/modal-close.svg';
import ConfirmationModal from '../components/modal/Modal';
import Input from '../../components/form/Input';
import SuccessIcon from '../../images/success-icon.svg';
import VerifiedIcon from '../../images/verified.svg';
import EmbedHeaderIcon from '../../images/embed-header-icon.svg';
import CopyIcon from '../../images/copy-icon.svg';
import DuplicateIcon from '../../images/duplicate-icon.svg';
import RevokeIcon from '../../images/revoke-new.svg';
import RedUser from '../../images/red-user.svg';
import jsPDF from "jspdf";
import * as PropTypes from "prop-types";
import {textToBase64Barcode} from "../templates/design/Utils";
import {serverToLocalDateTime} from "../../helpers/Utils";
import Logo from '../../images/chitkara-logo.svg';
import SampleImage from '../../images/sample.svg';


export const Container = styled.div`
  width: 100%;
  background: ${AppTheme.header.colors.backgroundLight};
  float: left;
  height: 100%;
  @media screen and (max-width: 991px) {
    height: ${(props) => (props.hasIssued ? 'calc(100vh - 135px)' : 'calc(100vh - 0px)')};
    overflow-y: auto;
  }
`;
export const HeaderContainer = styled.div`
  width: 100%;
  background: ${AppTheme.header.colors.whiteColor};
  height: 58px;
  display: none;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 20px 0px;
  @media screen and (max-width: 991px) {
    display: flex;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > img {
    width: 110px;
  }
`;
export const BodyContainer = styled.div`
  width: 100%;
  float: left;
  height: 100%;
`;

export const ColLeft = styled.div`
  width: 50%;
  float: left;
  background: ${AppTheme.header.colors.backgroundLight};
  padding: 32px 60px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const ColRight = styled.div`
  width: 50%;
  float: left;
  background: ${AppTheme.header.colors.whiteColor};
  padding: 32px 50px;
  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 0
  }
`;

export const ListOfCertificate = styled.div`
  width: 130px;
  background: ${AppTheme.header.colors.backgroundLight};
  max-height: calc(90vh - 20px);
  overflow-y: auto;
  display: block;
  @media screen and (max-width: 991px) {
    display: none;
  }

  > ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    @media screen and (max-width: 991px) {
      flex-direction: row;
      max-width: 340px;
      overflow-x: auto;
    }

    > li {
      padding: 2px;
      list-style: none;
      @media screen and (max-width: 991px) {
        padding: 2px 5px;
      }

      > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        margin-bottom: 10px;
        text-decoration: none;

        &.selected {
          border: 5px solid ${AppTheme.header.colors.blueColor};
        }

        > img {
          max-height: 120px;
          height: 100%;
          margin-bottom: 2px;
          @media screen and (max-width: 991px) {
            max-height: 103px;
            height: 100%;
            margin-bottom: 0px;
          }
        }

        > span {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: ${AppTheme.header.colors.blackColor};
        }

        @media screen and (max-width: 991px) {
          margin-bottom: 0px;
        }
      }

    }
  }
`;

export const ListOfCertificateMobile = styled.div`
  width: 130px;
  background: ${AppTheme.header.colors.backgroundLight};
  max-height: calc(90vh - 20px);
  overflow-y: auto;
  display: none;
  @media screen and (max-width: 991px) {
    width: 100%;
    background: ${AppTheme.header.colors.whiteColor};
    max-height: unset;
    overflow-y: hidden;
    padding: 15px;
    display: block;
  }

  > ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    @media screen and (max-width: 991px) {
      flex-direction: row;
      max-width: 340px;
      overflow-x: auto;
    }

    > li {
      padding: 2px;
      list-style: none;
      @media screen and (max-width: 991px) {
        padding: 2px 5px;
      }

      > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        margin-bottom: 10px;
        text-decoration: none;

        &.selected {
          border: 5px solid ${AppTheme.header.colors.blueColor};
        }

        > img {
          max-height: 120px;
          height: 100%;
          margin-bottom: 2px;
          @media screen and (max-width: 991px) {
            max-height: 103px;
            height: 100%;
            margin-bottom: 0px;
          }
        }

        > span {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: ${AppTheme.header.colors.blackColor};
        }

        @media screen and (max-width: 991px) {
          margin-bottom: 0px;
        }
      }

    }
  }
`;

export const MobileShares = styled.div`
  display: none;

  > ul.share-options {
    margin: 0;
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    border-bottom: 1px solid rgba(204, 204, 204, 0.80);

    > li {
      margin: 0;
      padding: 0 5px;
    }
  }

  @media screen and (max-width: 991px) {
    display: block;
  }
`;

export const CertificateBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > .certificate-col {
    max-height:650px;
    overflow-y:auto;
    width:80%;
    margin:0 auto;
    >embed{
      width:100%;
    }
    >img{
      width:100%;
      margin-bottom:10px;
    }
    > div {
      padding-bottom: 0px;
    }
  }

  > ul.share-options {
    margin: 0;
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    border-bottom: 1px solid rgba(204, 204, 204, 0.80);

    > li {
      margin: 0;
      padding: 0 5px;
    }

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  > .credential-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 25px;

    > .info-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > .icon-col {
        margin-right: 15px;

        > img {

        }
      }

      > .user-info {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        > span {
          color: ${AppTheme.header.colors.blackColor};
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }

        > a {
          color: ${AppTheme.header.colors.blueColor};
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          text-decoration: none;
        }
      }
    }
  }
`;

export const MetaInfo = styled.div`
  > .shadow-col {
    > h3 {
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: ${AppTheme.header.colors.blackColor};
      text-align: left;
      margin-bottom: 15px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: wrap;
      @media screen and (max-width: 991px) {
        gap: 8px;
      }

      > li {
        padding-right: 10px;
        list-style: none;
        @media screen and (max-width: 991px) {
          padding-right: 0px;
        }

        > a {
          display: flex;
          align-items: center;
          justify-content:: center;
          height: 40px;
          padding: 0 16px;
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          border: 1px solid #D5D5D5;
          background: #FCFCFC;
          color: ${AppTheme.header.colors.blackColor};

          > img {
            margin-right: 6px;
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      background: #FFF;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
      padding: 15px;
    }
  }

  > .read-only-canvas-mobile {
    display: none;
    max-height:500px;
    overflow-y:auto;
    padding:20px 20px;
    @media screen and (max-width: 991px) {
      display: block;
      background: #FAFAFA;
      >embed{
        width:100%;
      }
      >img{
        width:100%;
      }
    }
  }
`;

export const IssuedByBOX = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid rgba(204, 204, 204, 0.80);
  padding: 0 18px;
  @media screen and (max-width: 991px) {
    margin: 20px 15px 0px 15px;
  }

  > .issue-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 0;
    border-bottom: 1px solid rgba(204, 204, 204, 0.80);

    > img {
      margin-right: 8px;
    }

    > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.5px;
      color: ${AppTheme.header.colors.blackColor};
      text-align: left;
      @media screen and (max-width: 991px) {
        text-align: left;
      }
    }
  }

  > .combined-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > .issue-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 10px 8px 0px;
      border-right: 1px solid rgba(204, 204, 204, 0.80);

      > img {
        margin-right: 8px;
      }

      > span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.5px;
        color: ${AppTheme.header.colors.blackColor};
      }

      &.last-col {
        border-right: none;
        padding-left: 20px;
      }
    }
  }
`;

export const VerifyButton = styled.div`
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.80);

  > a {
    background: ${AppTheme.header.colors.blueColor};
    color: ${AppTheme.header.colors.whiteColor} !important;;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    > img {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    position: fixed;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 8px 25px;
    left: 0;
    width: 100%;
    bottom: 0px;
    margin-top:0px;
  }
`;

export const BarcodeBox = styled.div`
  width: 100%;
  margin-top: 20px;
  border: 1px solid rgba(204, 204, 204, 0.80);
  background: linear-gradient(90deg, #CDFFE0 0%, #55D887 100%);
  padding-bottom: 23px;
  margin-bottom: 20px;
  @media screen and (max-width: 991px) {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }

  > .secure-box {
    margin-top: 16px;
    background: ${AppTheme.header.colors.whiteColor};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 30px;
    padding: 4px 10px;

    > img {
      margin-right: 10px;
    }

    > span {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      color: ${AppTheme.header.colors.blackColor};
      word-break: break-all;
    }
    @media screen and (max-width: 991px) {
      height:auto;
      padding:6px 10px;
    }
  }

  > .barcode {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > img {
      width: 280px;
    }

    > span {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      color: ${AppTheme.header.colors.blackColor};
      margin-top: 13px;
      display: block;
    }
  }
`;

export const DescriptionBox = styled.div`
  width: 100%;
  border-top: 1px solid rgba(204, 204, 204, 0.80);
  margin: 20px 0;
  padding-top: 15px;
  @media screen and (max-width: 991px) {
    margin: 20px 15px;
    width: auto;
  }

  > .icon-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > img {
      margin-right: 10px;
    }

    > span {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: ${AppTheme.header.colors.blackColor};

    }
  }

  > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${AppTheme.header.colors.blackColor};
    text-align: left;
  }
`;

export const VerifyModal = styled.div`
  > .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h4 {
      margin-bottom: 0px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 31.065px;
      color: ${AppTheme.header.colors.blackColor};
    }

    > button {
      background: transparent;
      border: none;
      height: auto;
      padding: 0;
    }
  }

  > .modal-body {
    > .credential-info {
      border-bottom: 1.109px solid #EFEFEF;
      padding: 15px 0px;

      > .col {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 5px;

        > span {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22.189px;
          color: ${AppTheme.header.colors.blackColor};
          min-width:135px;
        }

        > h5 {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22.189px;
          color: ${AppTheme.header.colors.blackColor};
          margin: 0 0px 0px 10px;
        }
      }
    }

    > .form {
      padding: 15px 0;

      > div {
        > label {
          margin-bottom: 6px;
        }

        > div {
          background: transparent;
          padding: 0;

          > input {
            border-radius: 8.876px;
            border: 1.109px solid ${AppTheme.header.colors.inputBorder};
            background: ${AppTheme.header.colors.whiteColor};
            box-shadow: 0px 1.109px 2.219px 0px rgba(16, 24, 40, 0.05);
            padding: 0 10px;
          }
        }
      }

      > span {
        color: ${AppTheme.header.colors.lightGreyColor};
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.189px;
      }
    }

    > .successfull-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > img {
        margin-top: -34px;
      }

      > h4 {
        color: ${AppTheme.header.colors.greenColor};
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 8px;
      }

      > span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: ${AppTheme.header.colors.lightGreyColor};
      }

      > button {
        margin-bottom: 0px;
        height: 48px;
        padding: 0 15px;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        width: 100%;
        background: ${AppTheme.header.colors.greenColor};
        border: none;
        color: ${AppTheme.header.colors.whiteColor};
        margin-top: 20px;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  > a {
    text-decoration: none;
    border-radius: 8px;
    border: 1px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    width: 50%;
    color: ${AppTheme.header.colors.darkGrey};
  }

  > button {
    margin-bottom: 0px;
    height: 48px;
    padding: 0 15px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    width: 50%;
    background: ${AppTheme.header.colors.blueColor};
    border: none;
    color: ${AppTheme.header.colors.whiteColor};
  }

`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  max-width: 600px;
`;

export const EmbedHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};
  padding-bottom: 10px;

  > img {
    margin-bottom: 12px;
  }

  > span {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: ${AppTheme.header.colors.blackColor};
  }
`;

export const DescriptionCol = styled.div`
  padding-bottom: 20px;

  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: ${AppTheme.header.colors.lightGreyColor};
  }
`;

export const CopyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > a {
    text-decoration: none;
    border-radius: 8px;
    border: 1px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 44px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blackColor};
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${AppTheme.header.colors.whiteColor};
    height: 44px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    background: ${AppTheme.header.colors.blueColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: ${AppTheme.header.colors.blueColor};
    border: 1px solid ${AppTheme.header.colors.blueColor};
    width: 50%;

    > img {
      margin-right: 6px;
    }
  }
`;
export const CodeTextarea = styled.textarea`
  width: 100%;
  height: 126px;
  margin-bottom: 10px;
  font-family: monospace;
  border-radius: 12px;
  background: ${AppTheme.header.colors.blackColor};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${AppTheme.header.colors.whiteColor};
  padding: 0 10px;
`;

export const DuplicateBox = styled.div`
  width: 100%;
  background: #FFE267;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
  @media screen and (max-width: 991px) {
    width: auto;
    margin: 10px 15px 0 15px;
  }

  > span {
    color: ${AppTheme.header.colors.blackColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  > img {
    margin-right: 10px;
  }
`;

export const RevokeBox = styled.div`
  width: 100%;
  background: ${AppTheme.header.colors.errorBackground};
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
  @media screen and (max-width: 991px) {
    width: auto;
    margin: 10px 15px 0 15px;
  }

  > span {
    color: ${AppTheme.header.colors.blackColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  > img {
    margin-right: 10px;
  }
`;

export const RevokedBoxed = styled.div`
  width: 100%;
  background: #FFF5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 58px);

  > span {
    color: ${AppTheme.header.colors.errorTextColor};
    text-align: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 30px;
  }

  > p {
    color: ${AppTheme.header.colors.blackColor};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
    margin-top: 25px;
    width: 800px;
  }
`;

function VerifyModalComponent({closeModal, certificate, openVerifyModal,openFailureModal}) {
    const [emailEntered, setEmailEntered] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const verify = (e) => {
        e.preventDefault();
        setVerifying(true);
        setOtpVerified(false);
        setVerificationError(null);
        Api.sendVerificationOtp(certificate.quantumLedgerId, verificationPayload.email).then(response => {
            setEmailEntered(true);
        }).catch(err => {
            setVerificationError(err.response.data.message);
        }).finally(() => {
            setVerifying(false);
        })
    }
    const verifyUsingOtp = (e) => {
        e.preventDefault();
        setVerificationError(null);
        setVerifying(true);
        Api.verifyCertificate(certificate.quantumLedgerId, verificationPayload.email, verificationPayload.otp).then(response => {
            setOtpVerified(true);

        }).catch(err => {
            setVerificationError(err.response.data.message);
        }).finally(() => {
            setVerifying(false);
        })
    }
    const verifyCertificate = (e) => {
        e.preventDefault();
        setVerificationError(null);
        setVerifying(true);
        Api.verifyCertificateFields(certificate.quantumLedgerId, verificationPayload).then(response => {
            openVerifyModal()
        }).catch(err => {
            openFailureModal();
        }).finally(() => {
            setVerifying(false);
        })
    }
    const formatDateTime = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-GB', options); // e.g., "24 May 2024"
        const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }); // e.g., "15:30"
        return `${formattedDate}, ${formattedTime}`;
    };

    const handleSubmit = (e)=>{
        if(otpVerified){
            verifyCertificate(e)
            return
        }
        if(emailEntered){
            verifyUsingOtp(e);
            return;
        }
        verify(e);
    }

    const [verificationPayload, setVerificationPayload] = useState({attributes:{}});
    const [verifying, setVerifying] = useState(false);
    const [verificationError, setVerificationError] = useState(null);

    return <VerifyModal>
        <div className='modal-header'>
            <h4>Verify Credential</h4>
            <button onClick={closeModal}><img src={CloseIcon}/></button>
        </div>
        <div className='modal-body'>
            {!otpVerified &&
            <div className='credential-info'>
                <div className='col'>
                    <span>Credential Issued to :</span>
                    <h5>{certificate.awardeeName}</h5>
                </div>
                <div className='col'>
                    <span>Date of issue :</span>
                    <h5>{formatDateTime(certificate.createdAt)}</h5>
                </div>
            </div>
            }
            {
                otpVerified && <div className='credential-info'>
                    <div className='col'>
                        <span>Please enter the following information from the certificate:</span>

                    </div>

                </div>
            }
            {emailEntered && !otpVerified && <div className='form'>
                <Input
                    onChange={(e) => {
                        setVerificationPayload({...verificationPayload, [e.target.name]: e.target.value});
                    }}
                    value={verificationPayload.otp}
                    name='otp'
                    type='text'
                    error={verificationError}
                    placeholder='Example: 879090'
                    label={'Enter OTP'}
                />
                <span>Please enter the otp sent to your email '{verificationPayload.email}'</span>
            </div>}
            {!emailEntered && <div className='form'>
                <Input
                    onChange={(e) => {
                        setVerificationPayload({...verificationPayload, [e.target.name]: e.target.value});
                    }}
                    value={verificationPayload.email}
                    name='email'
                    type='text'
                    error={verificationError}
                    placeholder='Example: abc@gmail.com'
                    label={'Enter email'}
                />
                <span>Please enter your Email ID to verify this credential</span>
            </div>}

            {otpVerified && <div className='successfull-info'>
                {certificate.templateData.data.verificationConfiguration?.attributes?.map(attribute=>{
                    return <Input
                        onChange={(e) => {
                            let attributes = verificationPayload.attributes;
                            attributes[attribute] = e.target.value;
                            setVerificationPayload({...verificationPayload, attributes});
                        }}
                        value={verificationPayload.attributes[attribute]}
                        name='attribute'
                        type='text'
                        error={verificationError}
                        label={`Enter ${attribute}`}
                    />
                })}
            </div>}

        </div>
        <div className='modal-footer'>
            <ButtonContainer>
                <a onClick={closeModal}>
                    Cancel
                </a>
                <button disabled={verifying} onClick={handleSubmit}>
                    {verifying && emailEntered && 'Verifying...'}
                    {verifying && !emailEntered && 'Sending OTP...'}
                    {!verifying && emailEntered && 'Verify'}
                    {!verifying && !emailEntered && 'Send OTP'}
                </button>
            </ButtonContainer>
        </div>
    </VerifyModal>
}

VerifyModalComponent.propTypes = {onClose: PropTypes.func};
const PublicCertificateViewer = () => {
    const {id} = useParams(); // Get the :id parameter from the route
    const [certificate, setCertificate] = useState(null); // Updated state for template
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [successModal, setSuccessMOdal] = useState(false);
    const [failureModal, setFailureMOdal] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [selectedPageIndex, setSelectedPageIndex] = useState(0);
    const [verifying, setVerifying] = useState(false);
    const [verificationError, setVerificationError] = useState(null);
    const [embedModal, setEmbedModal] = useState(false);
    const [verificationPayload, setVerificationPayload] = useState({email: '', 'otp': ''})
    const [stageRef, setStageRef] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const code = `<iframe width="560" height="315" src="${window.location.href}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;


    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const copyCodeToClipboard = () => {
        // Copy code to clipboard
        navigator.clipboard.writeText(code);

        // Update button text and style
        setIsCopied(true);

        // Reset button text and style after 2 seconds
        setTimeout(() => {
            setIsCopied(false);
        }, 6000);
    };

    const openVerifyModal = () => {
        setSuccessMOdal(true);
        setIsOpen(false); // Close the previous modal
    };

    const openFailureModal = () => {
        setFailureMOdal(true);
        setIsOpen(false); // Close the previous modal
    };

    const closeVerifyModal = () => setSuccessMOdal(false);
    const handleVerifySuccess = () => {
        setIsVerified(true);
        setIsFailed(false);
        closeVerifyModal();
    };
    const handleVerifyFailure = () => {
        setIsFailed(true);
        setIsVerified(false);
        setFailureMOdal(false);
    };


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.getPublicCertificate(id);
                setCertificate(response);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        }

        fetchData();
    }, [id]);

    if (loading) {
        return <div>Loading..</div>
    }

    return (

        <Container hasIssued={certificate.status === 'issued'}>
            <Header/>
            {certificate.status === 'revoked' &&
                <RevokedBoxed>
                    <img src={RevokeIcon}/>
                    <span>Certificate Revoked</span>
                    <p>We regret to inform you that the certificate associated with your account has been revoked by the
                        issuing authority. Please contact the issuing authority for further assistance.</p>
                </RevokedBoxed>
            }
            {certificate.status === 'expired' &&
                <RevokedBoxed>
                    <img src={RevokeIcon}/>
                    <span>Certificate Expired</span>
                    <p>This certificate has expired on {serverToLocalDateTime(certificate.expiresAt)}. Please contact the issuing authority for further assistance.</p>
                </RevokedBoxed>
            }
            {certificate.status === 'issued' &&
                <BodyContainer>
                    <ColLeft>
                        {/* <ListOfCertificate>
                          <ul>
                              {
                                  certificate.templateData.canvasData.pages.map((page, index) => {
                                      return <li>
                                          <a onClick={e => {
                                              e.preventDefault();
                                              setSelectedPageIndex(index);

                                          }} className={index === selectedPageIndex ? 'selected' : ''}>
                                              <div style={{background: page.background}}/>
                                              <span>Page {index + 1}</span>
                                          </a>
                                      </li>
                                  })
                              }
                          </ul>
                      </ListOfCertificate> */}

                        {/* OrignalCertificate */}
                        <CertificateBox>
                            <div className='certificate-col'>
                                {/* <embed
                                    src={`${API_BASE_URL}/public/certificates/${certificate.quantumLedgerId}/pdf#toolbar=0&navpanes=0&scrollbar=0`}
                                    width="500" height="690"
                                    type="application/pdf"/> */}
                                {
                                    certificate.previewUrls.map((url, index) => {
                                        return <img src={url} key={`preview-image-${index}`}/>
                                    })
                                }
                            </div>

                            {/* Share button List */}
                            <ul className='share-options'>
                                <li>
                                    <a onClick={e => {
                                        e.preventDefault();
                                        window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${certificate.templateData.name}&summary=${certificate.templateData.data.description}&source=LinkedIn`)

                                    }}><img src={LinkdinIcon}/></a>
                                </li>
                                <li>
                                    <a onClick={e => {
                                        e.preventDefault();
                                        window.open(`https://twitter.com/intent/tweet?text=${certificate.templateData.name}&url=${window.location.href}`)
                                    }}><img src={TwitterIcon}/></a>
                                </li>
                                <li>
                                    <a onClick={e => {
                                        e.preventDefault();
                                        window.open(`https://wa.me/?text=${certificate.templateData.name} ${window.location.href}`)
                                    }}><img src={WhatsappIcon}/></a>
                                </li>
                                <li>
                                    <a onClick={e => {
                                        e.preventDefault();
                                        window.open(`mailto:?subject=${certificate.templateData.name}&body=${certificate.templateData.data.description} ${window.location.href}`)

                                    }}><img src={Inboxicon}/></a>
                                </li>
                            </ul>
                        </CertificateBox>
                    </ColLeft>
                    <ColRight>
                        <MetaInfo>
                            <div className='shadow-col'>
                                <HeaderContainer>
                                    <LogoContainer>
                                        <img src={Logo} alt='Logo'/>
                                    </LogoContainer>
                                </HeaderContainer>
                                <h3>{certificate.templateData.name}</h3>

                                {/* share button list */}
                                <ul>
                                    <li>
                                        <a onClick={e => {
                                            e.preventDefault();
                                            window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${certificate.templateData.name}&summary=${certificate.templateData.data.description}&source=LinkedIn`)

                                        }}>
                                            <img src={FillSocial}/>
                                            Share
                                        </a>
                                    </li>
                                    {/* <li>
                                    <a>
                                        <img src={FillView}/>
                                        VIEW OPEN BADGE
                                    </a>
                                </li> */}
                                    <li>
                                        <a target="_blank"
                                           href={`${API_BASE_URL}/public/certificates/${certificate.quantumLedgerId}/pdf?client=${window.location.hostname}&download=true`}>
                                            <img src={FillSDownload}/>
                                            Download
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => setEmbedModal(true)}>
                                            <img src={FillShare}/>
                                            Embed
                                        </a>
                                        {/* Embed Modal */}
                                        {embedModal && (
                                            <ModalContainer>
                                                <ModalContent>
                                                    <EmbedHeader>
                                                        <img src={EmbedHeaderIcon}/>
                                                        <span>Embed Code</span>
                                                    </EmbedHeader>
                                                    <DescriptionCol>
                                                        <span>Here's the embedded code for your degree. You can copy and paste it to validate your academic achievements.</span>
                                                    </DescriptionCol>
                                                    <CodeTextarea
                                                        rows={10}
                                                        cols={50}
                                                        readOnly
                                                        value={code}
                                                        style={{background: isCopied ? '#667085' : '#000000'}}/>
                                                    <CopyButton>
                                                        <Link onClick={() => setEmbedModal(false)}>
                                                            Cancel
                                                        </Link>
                                                        <button
                                                            onClick={copyCodeToClipboard}
                                                            style={{background: isCopied ? '#64AA53' : '#2A85FF'}}
                                                        >
                                                            <img src={CopyIcon} alt="Copy icon"/>
                                                            {isCopied ? 'Copied!' : 'copy'}
                                                        </button>
                                                    </CopyButton>
                                                </ModalContent>
                                            </ModalContainer>
                                        )}
                                    </li>
                                </ul>
                            </div>

                            {/* Canvas for mobile only */}
                            <div className='read-only-canvas-mobile' style={{overFlowY: 'auto'}}>
                                {/* <embed
                                    src={`${API_BASE_URL}/public/certificates/${certificate.quantumLedgerId}/pdf#toolbar=0&navpanes=0&scrollbar=0`}
                                    width="425" height="650"/> */}
                                {
                                    certificate.previewUrls.map((url, index) => {
                                        return <img src={url} key={`preview-image-${index}`}/>
                                    })
                                }
                            </div>
                            {/* <ListOfCertificateMobile>
                            <ul>
                                {
                                    certificate.templateData.canvasData.pages.map((page, index) => {
                                        return <li>
                                            <a onClick={e => {
                                                e.preventDefault();
                                                setSelectedPageIndex(index);

                                            }} className={index === selectedPageIndex ? 'selected' : ''}>
                                                <img src={page.backgroundImage} alt='sample'/>
                                                <span>Page {index + 1}</span>
                                            </a>
                                        </li>
                                    })
                                }
                            </ul>
                          </ListOfCertificateMobile> */}
                            <MobileShares>
                                <ul className='share-options'>
                                    <li>
                                        <a onClick={e => {
                                            e.preventDefault();
                                            window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${certificate.templateData.name}&summary=${certificate.templateData.data.description}&source=LinkedIn`)

                                        }}><img src={LinkdinIcon}/></a>
                                    </li>
                                    <li>
                                        <a onClick={e => {
                                            e.preventDefault();
                                            window.open(`https://twitter.com/intent/tweet?text=${certificate.templateData.name}&url=${window.location.href}`)
                                        }}><img src={TwitterIcon}/></a>
                                    </li>
                                    <li>
                                        <a onClick={e => {
                                            e.preventDefault();
                                            window.open(`https://wa.me/?text=${certificate.templateData.name} ${window.location.href}`)
                                        }}><img src={WhatsappIcon}/></a>
                                    </li>
                                    <li>
                                        <a onClick={e => {
                                            e.preventDefault();
                                            window.open(`mailto:?subject=${certificate.templateData.name}&body=${certificate.templateData.data.description} ${window.location.href}`)

                                        }}><img src={Inboxicon}/></a>
                                    </li>
                                </ul>
                            </MobileShares>
                            {/* University info */}
                            <IssuedByBOX>
                                <div className='issue-col'>
                                    <img src={RedUser}/>
                                    <span>Issued to : {certificate.awardeeName}</span>
                                </div>
                                <div className='issue-col'>
                                    <img src={Issue1}/>
                                    <span>Issued by : {certificate.templateData.data.issuer}</span>
                                </div>
                                {certificate.templateData.data.signatories?.map((signatory, index) => {
                                    return <div className='issue-col' key={`signatory-${index}`}>
                                        <img src={Issue2}/>
                                        <span>Credential Signatory : {signatory}</span>
                                    </div>
                                })}
                                <div className='combined-box'>
                                    <div className='issue-col'
                                         style={{borderRight: !certificate.expiresAt ? 'none' : 'rgba(204, 204, 204, 0.80)'}}>
                                        <img src={Issue3}/>
                                        <span>Issued: {serverToLocalDateTime(certificate.createdAt)}</span>
                                    </div>
                                    {certificate.expiresAt &&
                                        <div className='issue-col last-col'>
                                            <img src={Issue4}/>
                                            <span>Expires At: {serverToLocalDateTime(certificate.expiresAt)} </span>
                                        </div>
                                    }
                                </div>
                            </IssuedByBOX>


                            {certificate.status === 'issued' && <>
                                {!isVerified && !isFailed && (
                                    <VerifyButton>
                                        <a onClick={openModal}>Verify This Credential</a>
                                    </VerifyButton>
                                )}
                                {isVerified &&  (
                                    <VerifyButton>
                                        <a onClick={openModal} style={{backgroundColor: '#64AA53'}}>
                                            <img src={VerifiedIcon}/>
                                            Credential Verified
                                        </a>
                                    </VerifyButton>
                                )}
                                {isFailed &&  (
                                    <VerifyButton>
                                        <a onClick={openModal} style={{backgroundColor: AppTheme.header.colors.errorTextColor}}>
                                            <img src={VerifiedIcon}/>
                                            Credential Verification Failed
                                        </a>
                                    </VerifyButton>
                                )}
                            </>
                            }
                            {certificate.isDuplicate && certificate.status === 'issued' && <DuplicateBox>
                                <img src={DuplicateIcon}/> <span>This is a duplicate certificate</span></DuplicateBox>
                            }


                            {/* Barcode Box */}
                            <BarcodeBox>
                                <div className='secure-box'>
                                    <img src={SecureIcon}/>
                                    <span> Immutable Credential ID: {certificate.quantumLedgerId}</span>
                                </div>
                                <div className='barcode'>
                                    <img src={textToBase64Barcode(certificate.quantumLedgerId)}/>
                                    <span> Immutable Crypto Credential</span>
                                </div>
                            </BarcodeBox>

                            {/* Description */}
                            <DescriptionBox>
                                <div className='icon-col'>
                                    <img src={Issue5}/>
                                    <span>About</span>
                                </div>
                                <p>{certificate.templateData.data.about}</p>
                            </DescriptionBox>
                            <DescriptionBox>
                                <div className='icon-col'>
                                    <img src={Issue6}/>
                                    <span>Earning Criteria</span>
                                </div>
                                <p>{certificate.templateData.data.earningCriteria}</p>
                            </DescriptionBox>
                        </MetaInfo>
                    </ColRight>
                </BodyContainer>
            }


            {/* Verify Credemtial Modal */}
            <ConfirmationModal
                isOpen={isOpen}
                closeModal={closeModal}
            >
                <VerifyModalComponent closeModal={closeModal} certificate={certificate}
                                      openVerifyModal={openVerifyModal} openFailureModal={openFailureModal}/>
            </ConfirmationModal>

            {/* Verify Successfull Modal */}
            <ConfirmationModal
                isOpen={successModal}
                closeModal={closeVerifyModal}
            >
                <VerifyModal>
                    <div className='modal-header' style={{justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                        <button onClick={closeVerifyModal}><img src={CloseIcon}/></button>
                    </div>
                    <div className='modal-body'>
                        <div className='successfull-info'>
                            <img src={SuccessIcon}/>
                            <h4>Verified Successfully!</h4>
                            <span>Congratulations! Your credentials has been successfully verified.</span>
                            <button onClick={handleVerifySuccess}>Okay</button>
                        </div>
                    </div>
                </VerifyModal>
            </ConfirmationModal>
            {/* Verify Successfull Modal */}
            <ConfirmationModal
                isOpen={failureModal}
                closeModal={()=>{
                    setFailureMOdal(false);
                }}
            >
                <VerifyModal>
                    <div className='modal-header' style={{justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                        <button onClick={()=>{
                            setFailureMOdal(false);
                        }}><img src={CloseIcon}/></button>
                    </div>
                    <div className='modal-body'>
                        <div className='successfull-info'>
                            <img src={RevokeIcon}/>
                            <h4 style={{color: AppTheme.header.colors.errorTextColor}}>Verification Failed!</h4>
                            <span>Sorry, The credential verification has failed.</span>
                            <button onClick={handleVerifyFailure} style={{background: AppTheme.header.colors.errorTextColor}}>Okay</button>
                        </div>
                    </div>
                </VerifyModal>
            </ConfirmationModal>


        </Container>
    );
};

export default PublicCertificateViewer;
