import React from "react";
import styled from "styled-components";
import CertificateIcon from '../../../images/default-template.svg';
import {Link} from "react-router-dom";

export const Container = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
`;

export const ListItems = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-start;
`;

export const PhotoBox = styled.div`
    width:160px;
    height:98px;
`;

export const ListMeta = styled.div`
    display:flex;
    align-items:flex-start;;
    flex-direction:column;
    padding:0 24px;
    >h3{
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: #101828;
        margin-bottom:6px;
    }
    >span{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #475467;
        margin-bottom:6px;
    }
    >a{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: #2970FF;
    }
`;

export const Actions = styled.div``;

const TemplateListItems = ({id,name,canvasData,date,renderActions,totalAwarded,previewImageUrl}) => {
let background = canvasData?.pages?.[0].backgroundImage;
    return (
        <Container>
            <ListItems>
                <PhotoBox>
                    <img src={previewImageUrl || CertificateIcon} style={{
                        height:98,
                    }} alt='Certificate Pic' />
                </PhotoBox>
                <ListMeta>
                    <h3>{name}</h3>
                    {date &&
                        <span>{date}</span>
                    }
                    <Link to={`/templates/${id}/certificates`} >{totalAwarded} Awarded</Link>
                </ListMeta>
            </ListItems>
            <Actions>
                {renderActions && renderActions()}
            </Actions>
        </Container>
    );
}

export default TemplateListItems;
