import React, {useImperativeHandle, useRef} from 'react';
import {Circle, Image, Layer, Rect, Stage, Text} from 'react-konva';
import useImage from 'use-image';
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
`;

const CustomImage = ({src, x, y, id, width, height}) => {
    const [image] = useImage(src,'anonymous');
    return <Image image={image} x={x} y={y} width={width} height={height} id={id}/>;
};


const ReadOnlyCanvas = React.forwardRef(({
                                             quantumLedgerId,
                                             data,
                                             loading,
                                             widthParent,
                                             heightParent,
                                             widthChild,
                                             heightChild,
                                             showEmptyAttributes = false,
                                             attributes = {}
                                         }, ref)=>{

    useImperativeHandle(ref, () => ({
        exportAsSvg: exportAsSvg
    }));

    if (!data) {
        data = {backgroundImage: '', items: []}
    }
    let {backgroundImage = '', items = []} = data;

    const [bgImage] = useImage(backgroundImage,'anonymous');


    const stageRef = useRef();
    const finalAttributes = {...attributes};


    const exportAsSvg = () => {
        return stageRef.current.toDataURL();
    }


    if (loading) return 'Loading..';

    if (!data) {
        return null;
    }

    const actualWidth = data.width;
    const actualHeight = data.height;
    const aspectRatio = actualWidth / actualHeight;

    const visibleWidth = widthParent;
    const visibleHeight = widthParent / aspectRatio;

    let _items = [];
    try{
        _items = JSON.parse(JSON.stringify(data.items));
    }
    catch (e) {
        console.error(e);
    }
    _items = _items.map((item, index) => {
        if (item.type === 'text') {
            item.text.x = item.text.x * (visibleWidth / actualWidth);
            item.text.y = item.text.y * (visibleHeight / actualHeight);
            item.text.fontSize = item.text.fontSize * (visibleWidth / actualWidth);
            return item;
        }
        if (item.type === 'attribute') {
            item.attribute.x = item.attribute.x * (visibleWidth / actualWidth);
            item.attribute.y = item.attribute.y * (visibleHeight / actualHeight);
            item.attribute.fontSize = item.attribute.fontSize * (visibleWidth / actualWidth);
            return item;
        }
        if (item.type === 'shape') {
            item.shape.x = item.shape.x * (visibleWidth / actualWidth);
            item.shape.y = item.shape.y * (visibleHeight / actualHeight);
            item.shape.width = item.shape.width * (visibleWidth / actualWidth);
            item.shape.height = item.shape.height * (visibleHeight / actualHeight);
            return item;
        }
        if (item.type === 'image') {
            item.image.x = item.image.x * (visibleWidth / actualWidth);
            item.image.y = item.image.y * (visibleHeight / actualHeight);
            item.image.width = item.image.width * (visibleWidth / actualWidth);
            item.image.height = item.image.height * (visibleHeight / actualHeight);
            return item;
        }
        if (item.type === 'code') {
            item.code.x = item.code.x * (visibleWidth / actualWidth);
            item.code.y = item.code.y * (visibleHeight / actualHeight);
            item.code.width = item.code.width * (visibleWidth / actualWidth);
            item.code.height = item.code.height * (visibleHeight / actualHeight);
            return item;
        }
        return item;
    });



    return (
        <Container>
            <Stage width={visibleWidth} height={visibleHeight} ref={stageRef}>
                <Layer>
                    <Image image={bgImage} width={visibleWidth} height={visibleHeight}/>

                    {
                        _items.map(({type, text, attribute, shape, code, image}, index) => {
                                switch (type) {
                                    case 'text':
                                        return <Text
                                            key={`item-${index}`}
                                            id={text.id}
                                            text={text.text}
                                            textDecoration={text.underline ? 'underline' : 'none'}
                                            x={text.x}
                                            y={text.y}
                                            fontFamily={text.fontFamily}
                                            fontSize={text.fontSize}
                                            fill={text.fill}
                                            fontStyle={text.fontStyle}
                                        />
                                    case 'attribute':
                                        return <Text
                                            key={`item-${index}`}
                                            id={attribute.id}
                                            textDecoration={attribute.underline ? 'underline' : 'none'}
                                            text={finalAttributes[attribute.text]}
                                            x={attribute.x}
                                            y={attribute.y}
                                            fontFamily={attribute.fontFamily}
                                            fontSize={attribute.fontSize}
                                            fill={attribute.fill}
                                            fontStyle={attribute.fontStyle}
                                        />
                                    case 'code':
                                        if (code.type === 'qr') {
                                            return <CustomImage
                                                key={`item-${index}`}
                                                id={code.id}
                                                x={code.x}
                                                y={code.y}
                                                height={code.height}
                                                width={code.width}
                                                src={`https://barcodeapi.org/api/qr/${quantumLedgerId || '123456'}`}
                                            />
                                        }
                                        if (code.type === 'bar') {
                                            return <CustomImage
                                                key={`item-${index}`}
                                                id={code.id}
                                                x={code.x}
                                                y={code.y}
                                                height={code.height}
                                                width={code.width}
                                                src={`https://barcode.orcascan.com/?type=code128&data=${quantumLedgerId || '123456'}`}
                                            />
                                        }
                                        break;
                                    case 'image':
                                        return <CustomImage key={`item-${index}`} id={image.id} {...image}/>

                                    case 'shape':
                                        if (shape.type === 'Circle') {
                                            return <Circle
                                                key={`item-${index}`}
                                                id={shape.id}
                                                width={shape.width}
                                                height={shape.height}
                                                x={shape.x}
                                                y={shape.y}
                                                fill={shape.fill}
                                            />
                                        }

                                        if (shape.type === 'Rectangle') {
                                            return <Rect
                                                key={`item-${index}`}
                                                id={shape.id}
                                                width={shape.width}
                                                height={shape.height}
                                                x={shape.x}
                                                y={shape.y}
                                                fill={shape.fill}
                                            />
                                        }
                                        break;
                                    default:
                                        return null;
                                }
                            }
                        )}
                </Layer>
            </Stage>
        </Container>
    );
});

export default ReadOnlyCanvas;
