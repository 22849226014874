import React, {useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';
import SubHeader from '../../components/subheader/SubHeader';
import AppLayout from '../../components/layout/AppLayout';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import Api from "../../helpers/Api";

export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  float: left;
  width: 100%;
  padding: 0;

  > form {
    background: ${AppTheme.header.colors.whiteColor};
    padding: 0px 15px 20px 15px;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

`;

export const FormGroup = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};
    >div{
        margin-bottom:0px;
        >span{
            width: calc(100% - 224px);
            margin: 8px 0 0 auto;
        }
    }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  > a {
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.darkGrey};
  }

  > div {
    margin-top: 0px;

    > button {
      margin-bottom: 0px;
      height: 40px;
      padding: 0 15px;
      border-radius: 6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
`;

const ChangePassword = () => {
    const {id} = useParams(); // Get the :id parameter from the route
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({new_password: '', confirm_password: '', current_password: ''});
    const [errors, setErrors] = useState({new_password: '', confirm_password: '', current_password: ''});

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        Api.changePassword(formData).then(e => {
            alert('Password changed successfully');
            navigate(`/dashboard`);
        }).catch(e => {
            setErrors(e.response.data.errors);
        }).finally(()=>{
            setLoading(false);
        })
    }

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={'Change Password'}
                    description={'Secure your account by updating your password'}
                />
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Input
                            type="horizontalpasswordinput"
                            label="Current Password"
                            astricSign='*'
                            name="current_password"
                            placeholder={'Enter current password'}
                            error={errors.current_password}
                            onChange={(e) => {
                                setFormData({...formData, current_password: e.target.value});
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="horizontalpasswordinput"
                            label="New Password"
                            astricSign='*'
                            name="new_password"
                            placeholder={'Enter new password'}
                            error={errors.new_password}
                            onChange={(e) => {
                                setFormData({...formData, new_password: e.target.value});
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="horizontalpasswordinput"
                            label="Confirm Password"
                            astricSign='*'
                            name="confirm_password"
                            placeholder={'Enter confirm password'}
                            error={errors.confirm_password}
                            onChange={(e) => {
                                setFormData({...formData, confirm_password: e.target.value});
                            }}
                        />
                    </FormGroup>
                    <ButtonContainer>
                        <Link to={`/dashboard`}>
                            Cancel
                        </Link>
                        <Button disabled={loading} type={'submit'} loading={loading}
                                label={"Update Password"}
                        />
                    </ButtonContainer>
                </form>
            </Container>
        </AppLayout>
    );
};

export default ChangePassword;

