import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Api from "../../helpers/Api"; // Adjust the import path as necessary
import AppLayout from '../../components/layout/AppLayout';
import SubHeader from '../../components/subheader/SubHeader';
import styled from "styled-components";
import CommonTable from '../components/table/CommonTable';
import LoadingPage from "../loading/LoadingPage";
import AppTheme from '../../themes/AppTheme';
import NotFound from '../components/Notfound/NotFound';
import DefaultIcon from '../../images/not-found.svg';

export const Container = styled.div`
    border-radius: 5px;
    border: 1px solid  ${AppTheme.header.colors.tableBorder};
    background:  ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    float:left;
    width:100%;
    padding:0; 
    >table{
        >thead{
            >tr{
                >th:first-child{
                    width:150px;
                }
                >th:nth-child(7){
                    width:150px;
                }
            }
            
        }
    }
`;

const ListVerifications = () => {
    const [verifications, setVerifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const rolesColumns = [
        { key: 'certificate.awardeeName', label: 'Awardee Name' },
        { key: 'certificate.awardeeRollNumber', label: 'Roll Number' },
        { key: 'status', label: 'Status', render: (data) => {
                return data.status ? <span style={{color: AppTheme.header.colors.greenColor}}>Success</span> : <span style={{
                    color: AppTheme.header.colors.errorTextColor
                }}>Failed</span>
            }
        },
        { key: 'data.ip', label: 'IP Address' },
        { key: 'data.email', label: 'Verifier Email' },
        { key: 'createdAt', label: 'Verified On' , type: 'datetime'},

        { key: 'certificate', label: 'Certificate', render: (data) => {
                return <Link target='_blank' to={`/credential/${data.certificate.quantumLedgerId}`}>
                    View Certificate
                </Link>} },
    ];

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        setLoading(true);
        try {
            const data = await Api.listVerifications();
            setVerifications(data);
            setError('');
        } catch (err) {
            setError('Failed to fetch verifications. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handlePublish = async (id) => {
        await Api.publishRole(id);
        fetchRoles();
    };

    const handleUnpublish = async (id) => {
        await Api.unpublishRole(id);
        fetchRoles();
    };

    const handleDelete = async (id) => {
        await Api.deleteRole(id);
        fetchRoles();
    };

    const handleEdit = (id) => {
        navigate(`/roles/${id}`);
    };

    const handleViewCertificates = (id) => {
        navigate(`/verifications/${id}`);
    };

    if (loading) return <LoadingPage/>
    if (error) return <div>Error: {error}</div>;

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={'Verifications'}
                />

                {verifications.length > 0 ? (
                    <CommonTable
                        columns={rolesColumns}
                        data={verifications}
                        getActionsConfig={(item) => {
                            return {
                                edit: false,
                                delete: false,
                                view: true
                            }
                        }}
                        onView={handleViewCertificates}
                    />
                ) : (
                    <NotFound
                        message={'Looks like you have no verification done yet'}
                        icon={DefaultIcon}
                    />
                )}
            </Container>
        </AppLayout>
    );
};

export default ListVerifications;
