import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Api from "../../helpers/Api";
import {useParams} from "react-router-dom";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const ExportCertificate = () => {
    const {id} = useParams(); // Get the :id parameter from the route

    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);
        Api.downloadCertificate(id).then(({url}) => {
            setUrl(url);
            setLoading(false);
        }).catch((e) => {
            setError(e);
            setLoading(false);
        })
    }, [id]);

    return (
        <Container>
            <CenteredDiv>
                {loading && 'Please wait, exporting certificate...'}
                {!loading && !error && <a href={url} target="_blank">Download Certificate</a>}
                {!loading && error && 'An error occurred while exporting certificate.'}
            </CenteredDiv>
        </Container>
    )
}

export default ExportCertificate;
