import {createStore} from "polotno/model/store";

const store = createStore({
    key: '-ydCs4JMyM3-Grh_d0GK', // you can create it here: https://polotno.com/cabinet/
    // you can hide back-link on a paid license
    // but it will be good if you can keep it for Polotno project support
    showCredit: false,
    width: 595,
    height: 842
});

store.addFont({
    fontFamily: 'Arial',
    styles: [
        {
            src: 'url(/assets/fonts/arial/Arial.ttf)',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        {
            src: 'url(/assets/fonts/arial/Arial_Bold.ttf))',
            fontStyle: 'normal',
            fontWeight: 'bold',
        },
        {
            src: 'url(/assets/fonts/arial/Arial_Italic.ttf)',
            fontStyle: 'italic',
            fontWeight: 'normal',
        },
        {
            src: 'url(/assets/fonts/arial/Arial_Bold_Italic.ttf)',
            fontStyle: 'italic',
            fontWeight: 'bold',
        },
    ],
});

store.addFont({
    fontFamily: 'Times New Roman',
    styles: [
        {
            src: 'url(assets/fonts/tnr/Times_New_Roman.ttf)',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        {
            src: 'url(assets/fonts/tnr/Times_New_Roman Bold.ttf)',
            fontStyle: 'normal',
            fontWeight: 'bold',
        },
        {
            src: 'url(assets/fonts/tnr/Times_New_Roman Italic.ttf)',
            fontStyle: 'italic',
            fontWeight: 'normal',
        },
        {
            src: 'url(assets/fonts/tnr/Times_New_Roman Bold Italic.ttf)',
            fontStyle: 'italic',
            fontWeight: 'bold',
        },
    ],
});

export default store;
