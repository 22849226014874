import React, {useEffect, useState} from 'react';
import { useNavigate} from 'react-router-dom';
import Api from "../../helpers/Api"; // Adjust the import path as necessary
import Button from "../../components/form/Button";
import AppLayout from '../../components/layout/AppLayout';
import SubHeader from '../../components/subheader/SubHeader';
import styled from "styled-components";
import CommonTable from '../components/table/CommonTable';
import LoadingPage from "../loading/LoadingPage";
import AppTheme from '../../themes/AppTheme';
import NotFound from '../components/Notfound/NotFound';
import NotFoundIcon from '../../images/not-found.svg';

export const Container = styled.div`
    border-radius: 5px;
    border: 1px solid  ${AppTheme.header.colors.tableBorder};
    background:  ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    float:left;
    width:100%;
    padding:0; 
`;

const ListRoles = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const rolesColumns = [
        { key: 'name', label: 'Role' },
        { key: 'createdAt', label: 'Created At', type: 'datetime'},
        { key: 'updatedAt', label: 'Updated At', type: 'datetime' },
    ];

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        setLoading(true);
        try {
            const data = await Api.listRoles();
            setRoles(data);
            setError('');
        } catch (err) {
            setError('Failed to fetch roles. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handlePublish = async (id) => {
        await Api.publishRole(id);
        fetchRoles();
    };

    const handleUnpublish = async (id) => {
        await Api.unpublishRole(id);
        fetchRoles();
    };

    const handleDelete = async (id) => {
        await Api.deleteRole(id);
        fetchRoles();
    };

    const handleEdit = (id) => {
        navigate(`/roles/${id}`);
    };

    const handleViewCertificates = (id) => {
        navigate(`/roles/${id}/certificates`);
    };

    if (loading) return <LoadingPage/>
    if (error) return <div>Error: {error}</div>;

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={'Roles'}
                    href={'/roles/create'}
                    label={'Create Role'}
                />
                {roles.length > 0 ? (
                    <CommonTable
                        columns={rolesColumns}
                        data={roles}
                        onEdit={handleEdit}
                        getActionsConfig={(item) => {
                            return {
                                edit: true,
                                delete: true,
                                view: true
                            }
                        }}
                    />
                ) : (
                    <NotFound
                        message={'Looks like you have no roles created  yet'}
                        icon={NotFoundIcon}
                    />
                )}
            </Container>
        </AppLayout>
    );
};

export default ListRoles;
