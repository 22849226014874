import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import DeleteIcon from '../../../images/delete-icon.svg';
import EditIcon from '../../../images/edit-icon.svg';
import ViewIcon from '../../../images/view-icon.svg';
import AwardIcon from '../../../images/award-icon.svg';
import RevokeIcon from '../../../images/revoke-icon.svg';
import DownIcon from '../../../images/arrow-down.svg';
import DisableDelete from '../../../images/disabled-delete.svg';
import DuplicateIcon from '../../../images/duplicate-icon.svg';
import AppTheme from '../../../themes/AppTheme';
import {get} from "lodash/object";
import Input from "../../../components/form/Input";
import {serverToLocalDateTime} from "../../../helpers/Utils";

export const TableContainer = styled.table`
  width: 100%;
  border-radius: 0px;
  background: ${AppTheme.header.colors.whiteColor};
  border: 1px solid ${AppTheme.header.colors.tableBorder}`;

export const TableHeading = styled.thead`
  > tr {
    > th {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      padding: 12px 15px;
      color: ${AppTheme.header.colors.blackColor};
      border-bottom: 1px solid #EAECF0;
      background: ${AppTheme.header.colors.bodyBackground};
      text-align: left;

      &:last-child {
        text-align: center;
      }
      >.checkboxs-heading{
        >div{
            margin-bottom:0px;
            width:auto;
            >div{
                padding:0;
                background:transparent;
                >input{
                    width:20px;
                    height:20px;
                }
            }
        }
    }
    }
  }
`;

export const TableBody = styled.tbody`
    >tr {
        > td {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: ${AppTheme.header.colors.blackColor};
        border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};
        padding: 12px 15px;
        text-align: left;
        >a{
            color:${AppTheme.header.colors.blueColor};
        }
        
        &:last-child {
            text-align: center;
        }
        >.checkboxs-heading{
            >div{
                margin-bottom:0px;
                width:auto;
                >div{
                    padding:0;
                    background:transparent;
                    >input{
                        width:20px;
                        height:20px;
                    }
                }
            }
        }
    }

    &:last-child {
      > td {
        border-bottom: none;
        position:relative;
        
      }
    }
  }
`;

export const ActionButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    height: auto;
    border-radius: 0px;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blueColor};
    padding: 0 10px;
    cursor: pointer;
    min-width:148px;

    > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: ${AppTheme.header.colors.blueColor};
      padding-right: 0px;
    }

    .drop-icon {
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 5px;
    }
  }

  > .show-drop {
    border-radius: 8px;
    border: 1px solid ${AppTheme.header.colors.tableBorder};
    background: ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    position: absolute;
    z-index:999999;
    margin-top: 24px;
    width: 200px;
    right:21px;

    &.open-upwards {
    
    }

    > ul {
      list-style: none;
      margin: 0;
      padding: 10px 0px;

      > li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0px 10px;

        > button {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: ${AppTheme.header.colors.blackColor};
          padding: 10px 8px;
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          cursor: pointer;
          background: transparent;
          border: none;

          > img {
            margin-right: 10px;
            margin-top: 2px;
          }

          &:hover {
            border-radius: 6px;
            background: #F9FAFB;
          }
        }

        > a {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: ${AppTheme.header.colors.blackColor};
          padding: 10px 8px;
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          cursor: pointer;
          text-decoration: none;

          > img {
            margin-right: 10px;
            margin-top: 2px;
          }

          &:hover {
            border-radius: 6px;
            background: #F9FAFB;
          }
        }
      }
    }
  }
`;

const CommonTable = ({
                         data,
                         columns,
                         onDelete,
                         onEdit,
                         onView,
                         onIssued,
                         onPreview,
                         onAward,
                         getActionsConfig,
                         onRevoke,
                         onDuplicate,
                         showActions = true,
                         selectable = false,
                         onSelectionChange

                     }) => {

    const [showDrop, setShowDrop] = useState(false);

    const dropdownRefs = useRef({});
    const [selected, setSelected] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({});

    useEffect(() => {
        // Event listener to close dropdown when clicked outside of it
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (event) => {
        const newDropdownOpen = { ...dropdownOpen };
        Object.keys(dropdownRefs.current).forEach((key) => {
            if (dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)) {
                newDropdownOpen[key] = false;
            }
        });
        setDropdownOpen(newDropdownOpen);
    };
const toggleDropdown = (id) => {
        setDropdownOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };
    useEffect(() => {
        const checkDropdownPosition = () => {
            Object.keys(dropdownRefs.current).forEach(id => {
                const dropdown = dropdownRefs.current[id];
                if (dropdown) {
                    const rect = dropdown.getBoundingClientRect();
                    const windowHeight = window.innerHeight;
                    if (rect.bottom > windowHeight) {
                        dropdown.classList.add('open-upwards');
                    } else {
                        dropdown.classList.remove('open-upwards');
                    }
                }
            });
        };

        window.addEventListener('resize', checkDropdownPosition);
        window.addEventListener('scroll', checkDropdownPosition);
        document.addEventListener('mousedown', handleClickOutside);

        // Initial check
        checkDropdownPosition();

        return () => {
            window.removeEventListener('resize', checkDropdownPosition);
            window.removeEventListener('scroll', checkDropdownPosition);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);
    

    useEffect(() => {
        onSelectionChange && onSelectionChange(selected);
    }, [onSelectionChange, selected])

    const checkAll = (e) => {
        if (e.target.checked) {
            setSelected(data.map(d => d.id));
        } else {
            setSelected([]);
        }
    }


    return (
        <TableContainer>
            <TableHeading>
                <tr>
                    {selectable && <th>
                        <div className='checkboxs-heading'>
                            <Input type="checkbox" onChange={e=>{
                                checkAll(e);
                            }}/>
                        </div>
                    </th>}
                    {columns.map(column => (
                        <th key={column.key}>{column.label}</th>
                    ))}
                    {showActions && <th>Actions</th>}
                </tr>
            </TableHeading>
            <TableBody>
                {data.map(item => {
                        const actions = (getActionsConfig && getActionsConfig(item)) || {};
                        console.log({actions});
                        return <tr key={item.id}>
                            {selectable && <td>
                                <div className='checkboxs-heading'>
                                <Input type="checkbox" value={selected.includes(item.id)} onChange={e => {
                                    if (e.target.checked) {
                                        setSelected([...selected, item.id]);
                                    } else {
                                        setSelected(selected.filter(s => s !== item.id));
                                    }
                                }}/>
                                </div>
                            </td>}
                            {columns.map(column => {
                                let data = get(item, column.key);
                                if (column.render) {
                                    data = column.render(item);
                                }
                                if (column.type === 'datetime') {
                                    data = serverToLocalDateTime(data);
                                }
                                return <td key={column.key}>{data}</td>
                            })}
                            {showActions  &&
                                <td>
                                    {actions.actions !== false &&
                                    <ActionButton>
                                        <button onClick={() => toggleDropdown(item.id)}>
                                            <span>Take Action</span>
                                            <div className='drop-icon'>
                                                <img src={DownIcon} alt="Down icon "/>
                                            </div>
                                        </button>
                                        {dropdownOpen[item.id] && (
                                            <div className='show-drop'
                                                 ref={(node) => (dropdownRefs.current[item.id] = node)}>
                                                <ul>

                                                    {onEdit && !!actions.edit &&
                                                        <li>
                                                            <button disabled={actions.edit === 'disabled'}
                                                                    onClick={() => onEdit(item.id)}>
                                                                <img src={EditIcon} alt='Delete icon'/>
                                                                Edit
                                                            </button>
                                                        </li>
                                                    }
                                                    {onDelete &&
                                                        <li>
                                                            <button
                                                                disabled={actions.delete === 'disabled'}
                                                                onClick={() => onDelete(item.id)}
                                                                style={{
                                                                    color: actions.delete === 'disabled' ? '#98A2B3' : '#000000',
                                                                    cursor: actions.delete === 'disabled' ? 'unset' : 'pointer'

                                                                }}
                                                            >
                                                                {actions.delete === 'disabled' ? (
                                                                    <img src={DisableDelete} alt='Delete icon'/>
                                                                ) : (
                                                                    <img src={DeleteIcon} alt='Delete icon'/>
                                                                )}
                                                                Delete
                                                            </button>
                                                        </li>
                                                    }
                                                    {onView &&
                                                        <li>
                                                            <button disabled={actions.view === 'disabled'}
                                                                    onClick={() => onView(item.id)}>
                                                                <img src={ViewIcon} alt='View icon'/>
                                                                View
                                                            </button>
                                                        </li>
                                                    }
                                                    {onAward && item.status === 'draft' && (
                                                        <li>
                                                            <button disabled={actions.delete === 'award'}
                                                                    onClick={() => onAward(item.id)}>
                                                                <img src={AwardIcon} alt='Award icon'/>
                                                                Award
                                                            </button>
                                                        </li>
                                                    )}
                                                    {onIssued && item.status !== 'draft' && (
                                                        <li>
                                                            <a href={onIssued(item)}>
                                                                <img src={ViewIcon} alt='Issued icon'/>
                                                                View Certificate
                                                            </a>
                                                        </li>
                                                    )}
                                                    {onIssued && item.status === 'issued' && (
                                                        <li>
                                                            <a href="#" onClick={e => {
                                                                e.preventDefault();
                                                                onRevoke(item)
                                                            }}>
                                                                <img src={RevokeIcon} alt='Issued icon'/>
                                                                Revoke Certificate
                                                            </a>
                                                        </li>
                                                    )}
                                                    {onIssued && item.status === 'issued' && (
                                                        <li>
                                                            <a href={'#'} onClick={e => {
                                                                e.preventDefault();
                                                                onDuplicate(item)
                                                            }}>
                                                                <img src={DuplicateIcon} alt='Duplicate icon'/>
                                                                Issue Duplicate
                                                            </a>
                                                        </li>
                                                    )}
                                                    {onIssued && item.status === 'draft' && (
                                                        <li>
                                                            <a href={onPreview(item)}>
                                                                <img src={ViewIcon} alt='Issued icon'/>
                                                                Preview Certificate
                                                            </a>
                                                        </li>
                                                    )}

                                                </ul>
                                            </div>
                                        )}

                                    </ActionButton>
                                    }
                                </td>
                            }
                        </tr>
                    }
                )}

            </TableBody>
        </TableContainer>
    );
};

export default CommonTable;
