import React from 'react';
import styled from "styled-components";
import OtpInput from 'react-otp-input';
import AppTheme from '../../themes/AppTheme';

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    width:100%;
    >label{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; 
        color:${AppTheme.header.colors.blackColor};
        margin-bottom:12px;
        text-align:left;
    }
    >div{
        >input:focus-visible{
            outline-offset: 1px;
            outline: none;
            background: ${AppTheme.header.colors.whiteColor} !important;
            border: 1px solid #9A9FA5 !important;
        }
    }
`;

export const Heading = styled.div`
    margin-bottom:16px;
    >span{
       font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px; /* 122.222% */
        letter-spacing: -0.72px;
        color:${AppTheme.header.colors.blackColor};
    }
`;

export const ErrorContainer = styled.span`
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
    letter-spacing: -0.12px;
    color:${AppTheme.header.colors.errorTextColor};
    margin-top:12px;
`;

const Otp = ({ value, onChange, name, error,  label,  heading}) => {
    
    return (
        <Container>
            {heading &&
            <Heading>
                <span>{heading}</span>
            </Heading>
            }
            {label && <label htmlFor={name}>{label}</label>}
            <OtpInput
                value={value}
                onChange={onChange}
                numInputs={6}
                name="otp"
                renderSeparator={<span style={{ width: 8,display:'block' }}></span>}
                renderInput={(props) => <input {...props} />}
                isInputNum={true}
                inputStyle={{
                    borderRadius: "8px",
                    width: "40px",
                    height: "53px",
                    fontSize: "35px",
                    color: error ? "#F04438" : "#1A1D1F",
                    fontWeight: "600",
                    background:error ? "#FECDCA" : "#EFEFEF",
                    caretColor: "blue",
                    border:error ? "1px solid #FECDCA" : "1px solid #EFEFEF",
                }}
                focusStyle={{
                    border:"1px solid #9A9FA5",
                    background:"#FCFCFC",
                }}
            />
            {error && <ErrorContainer>{error}</ErrorContainer>}
        </Container>
    );
};

export default Otp;
