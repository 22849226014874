import styled from "styled-components";
import AppTheme from "../../themes/AppTheme";

export const Container = styled.div`
    
`;

export const FieldContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 0px;
    border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};
    >label{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: ${AppTheme.header.colors.blackColor};
        margin-bottom: 0px;
        text-align: left;
        width: 280px;
        padding-right: 20px;
        text-transform: capitalize;
    }
`;

export const FieldCol = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-start;
`;

export const Col = styled.div`
    display:flex;
    align-items:center;
    width:20%;
    >input{
        width:20px;
        height:20px;
        border-radius:6px;
        border:1px solid ${AppTheme.header.colors.inputBorder};
    }
    >label{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color:${AppTheme.header.colors.darkGrey};
        margin-left:12px;
        text-transform: capitalize;
    }
`;

const DEFAULT_PERMISSIONS = {
    templates: {
        read: false,
        write: false,
        delete: false,
        publish: false
    },
    certificates: {
        read: false,
        write: false,
        revoke: false,
        issue: false,
        update: false
    }
}
const ACLInput = ({value, onChange}) => {

    return <Container>

        {
            Object.keys(DEFAULT_PERMISSIONS).map(permission => {
                return <FieldContainer>
                    <label>
                        {permission}
                    </label>
                    <FieldCol>
                        {
                            Object.keys(DEFAULT_PERMISSIONS[permission]).map((subPermission, index) => {
                                return <Col>
                                            <input type="checkbox" checked={value?.[permission]?.[subPermission]} onChange={(e) => {
                                                onChange({
                                                    target: {
                                                        value: {
                                                            ...value,
                                                            [permission]: {
                                                                ...value[permission],
                                                                [subPermission]: e.target.checked
                                                            }
                                                        }
                                                    }
                                                })
                                            }}/>
                                            <label>{subPermission}</label>
                                </Col>
                            })
                        }
                    </FieldCol>
                </FieldContainer>
            })
        }

    </Container>
}

export default ACLInput;
