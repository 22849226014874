import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom'; // Import useParams
import Api from "../../../helpers/Api";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import AppLayout from '../../../components/layout/AppLayout';
import SubHeader from '../../../components/subheader/SubHeader';
import styled from "styled-components";
import AppTheme from '../../../themes/AppTheme';
import LoadingPage from "../../loading/LoadingPage";
import * as PropTypes from "prop-types";
import ArrowIcon from '../../../images/drop-black.svg';
import {useDetectClickOutside} from "react-detect-click-outside";


export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  float: left;
  width: 100%;
  padding: 0;

  > form {
    background: ${AppTheme.header.colors.whiteColor};
    padding: 0px 15px 20px 15px;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

`;

export const FormGroup = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};

  > .checkbox-col {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    > label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: ${AppTheme.header.colors.blackColor};
      margin-bottom: 0px;
      text-align: left;
      width: 225px;
      padding-right: 20px;
    }

    > .checkbox-input {
      > div {
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: center;

        > label {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: ${AppTheme.header.colors.blackColor};
          margin-bottom: 0px;
          text-align: left;
          margin-left: 8px;
        }

        > input {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  > div {
    margin-bottom: 0px;

    > span {
      width: calc(100% - 224px);
      margin: 8px 0 0 auto;
    }
  }

  > .select-col {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    > .options-box {
      width: 100%;
      display: flex;
      flex-direction: column;

      > .assign-role {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 18px;
        gap: 10px;

        > .checkbox-col {
          display: flex;
          align-items: center;
          width: 40%;
          border-radius: 2px;
          border: 1px solid ${AppTheme.header.colors.inputBorder};
          background: ${AppTheme.header.colors.whiteColor};
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          height: 50px;
          justify-content: flex-start;
          padding: 0 12px;

          > input {
            width: 20px;
            height: 20px;
          }

          > label {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: ${AppTheme.header.colors.blackColor};
            padding-left: 10px;
          }
        }

        > .drop-down-col {
          padding-left: 0px;
          width: 25%;

          > div {
            margin-bottom: 0px;

            > select {
              border-radius: 2px;
              height: 50px;
              width: 100%;
            }
          }
        }

        > .last-col-drop {
          width: 35%;
          position: relative;

          > div {
            > div {
              margin-bottom: 0px;
            }
          }

          > .clickDrop-down {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-radius: 0px;
            border: 1px solid ${AppTheme.header.colors.inputBorder};
            background: ${AppTheme.header.colors.whiteColor};
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            height: 50px;
            padding: 0px 10px;
            cursor: pointer;
            width: 100%;
          }
        }
      }

      > .assign-role-header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 18px;
        gap: 10px;
        font-weight: bold;
        > div:nth-child(1) {
          width: 40%;
        }
        > div:nth-child(2) {
          width: 25%;
        }
        > div:nth-child(3) {
          width: 35%;
        }
      }
    }

    > div {
      margin-bottom: 0px;

      > select {
        width: 100%;
        border-radius: 2px;
        border: 1px solid ${AppTheme.header.colors.inputBorder};
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height: 50px;
      }
    }

    > label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: ${AppTheme.header.colors.blackColor};
      margin-bottom: 0px;
      text-align: left;
      width: 280px;
      padding-right: 20px;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  > a {
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.darkGrey};
  }

  > div {
    margin-top: 0px;

    > button {
      margin-bottom: 0px;
      height: 40px;
      padding: 0 15px;
      border-radius: 6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
`;

export const MultiSelectContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  border: 1px solid ${AppTheme.header.colors.inputBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 210px;
  padding: 5px 10px;
  position: absolute;
  width: 299px;
  z-index: 10000;

  > label {
    padding-bottom: 13px;
    color: ${AppTheme.header.colors.blackColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    > input {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }
  }
`;

function Departments({value, onChange}) {

    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([])
    const [templates, setTemplates] = useState([]);
    const [showDrop, setShowDrop] = useState(false);


    const onOutsideClick = (e) => {
        if (!e.target.closest('.clickDrop-down')) {
            setShowDrop(false);
        }
    }

    const dropdownRef = useDetectClickOutside({ onTriggered: onOutsideClick });


    const handleDropdownClick = (departmentId) => {
        setShowDrop(prevState => ({
            ...prevState,
            [departmentId]: !prevState[departmentId]
        }));
    };

    const handleCheckboxChange = (departmentId, templateId) => {
        const departmentValue = value[departmentId]?.templates || [];
        const index = departmentValue.indexOf(templateId);

        if (index === -1) {
            onChange({
                ...value,
                [departmentId]: {
                    ...value[departmentId],
                    templates: [...departmentValue, templateId]
                }
            });
        } else {
            onChange({
                ...value,
                [departmentId]: {
                    ...value[departmentId],
                    templates: departmentValue.filter(id => id !== templateId)
                }
            });
        }

        // Update dropdown state when checkbox is clicked
        setShowDrop(prevState => ({
            ...prevState,
            [departmentId]: true
        }));
    };

    const selectedTemplatesCount = (departmentId) => {
        const selectedTemplates = value[departmentId]?.templates || [];
        return selectedTemplates.length;
    };

    useEffect(() => {
        Api.listDepartments().then(data => {
            setDepartments(data);
        }).catch(err => {
            console.log(err);
        });

        Api.listRoles().then(data => {
            setRoles(data);
        }).catch(err => {
            console.log(err);
        });

        Api.listTemplates().then(data => {
            setTemplates(data);
        }).catch(e => {
            console.log(e)

        }).finally(() => {
        })
    }, []);

    return <div className='select-col'>
        <label>Select Departments and Roles</label>
        <div className='options-box'>

            <div key={'header'} className='assign-role-header'>
                    <div className='checkbox-col'>
                        <label>Department</label>
                    </div>
                    <div className='drop-down-col'>
                        <label>Role</label>
                    </div>
                    <div className='last-col-drop'>
                        <label>Templates</label>
                    </div>
            </div>
            {departments.map((department) => {
                const active = !!value[department.id];
                const selectedTemplates = value[department.id]?.templates || [];
                return <div key={department.id} className='assign-role'>
                    <div className='checkbox-col'>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    let newValue = {...value};
                                    newValue[department.id] = {role: roles[0].id, templates: []};
                                    onChange(newValue)
                                } else {
                                    let newValue = {...value};
                                    delete newValue[department.id];
                                    onChange(newValue)
                                }
                            }}
                            checked={active}
                        />
                        <label>{department.name}</label>
                    </div>
                    <div className='drop-down-col'>
                        <Input
                            disabled={!active}
                            defaultText={'Select Role'}
                            value={value[department.id]?.role}
                            type="select"
                            options={roles.map(role => {
                                return {value: role.id, label: role.name}
                            })}
                            onChange={(e) => {
                                let newValue = {...value};
                                newValue[department.id].role = e.target.value;
                                onChange(newValue)
                            }}
                        />
                    </div>
                    <div className='last-col-drop'>
                        <div className='clickDrop-down' onClick={() => handleDropdownClick(department.id)}>
                            <span style={{fontWeight: showDrop[department.id] ? 600 : 400}}>{active ? selectedTemplates.length + ' selected' : 'Select Templates'}</span>
                            <img src={ArrowIcon}/>
                        </div>
                        {showDrop[department.id] && (
                            <MultiSelectContainer ref={dropdownRef}>
                                {templates.map(template => (
                                    <label key={template.id}>
                                        <input
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(department.id, template.id)}
                                            checked={selectedTemplates.includes(template.id)}
                                        />
                                        {template.name}
                                    </label>
                                ))}
                            </MultiSelectContainer>
                        )}
                    </div>
                </div>

            })}
        </div>
    </div>;
}

Departments.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object
};
const CreateUser = () => {
    const {id} = useParams(); // Get the :id parameter from the route
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({})
    const [enabled, setEnabled] = useState(true);

    const roles = [{'name': "User", 'value': "ROLE_USER"}, {'name': "Admin", 'value': "ROLE_ADMIN"}];
    const [role, setRole] = useState('ROLE_ADMIN');
    const [departments, setDepartments] = useState({});
    const [initialising, setInitialising] = useState(true);


    useEffect(() => {
        localStorage.setItem('dropdownRole', role);
    }, [role]);


    useEffect(() => {
        // If id is present, load the existing user data
        if (!id) {
            setInitialising(false);
            return;
        }
        setLoading(true);
        Api.getUser(id).then(user => {
            setEmail(user.email);
            setName(user.name);
            setPhone(user.phone);
            setLoading(false);
            setDepartments(user.departmentUsers.reduce((acc, departmentUser) => {
                acc[departmentUser.department.id] = {
                    role: departmentUser.role.id,
                    templates: departmentUser.templates.map(template => template.id)
                };
                return acc;
            }, {}));
            setEnabled(!user.disabled);
            setEmployeeId(user.employeeId);
            setRole(user.roles[0]);
        }).catch(err => {
            setError('Failed to load user. Please try again.');
            setLoading(false);
        }).finally(() => {
            setInitialising(false);
        });

    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let user;
        try {
            const errors = {};

            // Email validation regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            // Name validation
            if (!name.trim()) {
                errors.name = "Name cannot be empty.";
            }

            // Email validation
            if (!email.trim()) {
                errors.email = "Email cannot be empty.";
            } else if (!emailRegex.test(email)) {
                errors.email = "Invalid email format.";
            }

            // Phone number validation regex
            const phoneRegex = /^\d+$/;

            // Phone number validation
            if (!phone.trim()) {
                errors.phone = "Phone number cannot be empty.";
            } else if (!phoneRegex.test(phone)) {
                errors.phone = "Phone number must contain only numeric characters.";
            }

            // Employee ID validation
            if (!employeeId.trim()) {
                errors.employeeId = "Employee ID cannot be empty.";
            }

            setErrors(errors);

            // Check if there are any validation errors
            if (Object.keys(errors).length > 0) {
                setLoading(false);
                return;
            }
            if (id) {
                // If id is present, update the existing user
                user = await Api.updateUser(id, email, role, name, phone, departments, !enabled, employeeId);
            } else {
                // If no id, create a new user
                user = await Api.createUser(email, role, name, phone, departments, !enabled, employeeId);
            }
            navigate(`/users`);
        } catch (error) {
            setError('Failed to process user. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (initialising) {
        return <LoadingPage/>
    }

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={id ? 'Edit User' : 'Create User'}
                    description={id ? 'Edit a user profile with assigned roles, departments and programs.' :'Create a new user profile with assigned roles, departments and programs.'}
                />
                <form onSubmit={handleSubmit}>

                    <FormGroup>
                        <div className='checkbox-col'>
                            <label>Status of the user</label>
                            <div className='checkbox-input'>
                                <Input
                                    type="checkbox"
                                    label="Enabled"
                                    value={enabled}
                                    onChange={(e) => setEnabled(e.target.checked)}
                                    name="enabled"
                                    // error={error}
                                />
                            </div>
                        </div>

                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="horizontalinput"
                            label="Name of User"
                            astricSign='*'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            name="name"
                            placeholder={'Enter name '}
                            error={errors.name}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="horizontalinput"
                            label="Email of User"
                            astricSign='*'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                            placeholder={'Example: test@gmail.com'}
                            error={errors.email}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="horizontalinput"
                            label="Phone Number of User"
                            astricSign='*'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            name="phone"
                            placeholder={'Enter phone number'}
                            error={errors.phone}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="horizontalinput"
                            label="Employee ID of User"
                            astricSign='*'
                            value={employeeId}
                            onChange={(e) => setEmployeeId(e.target.value)}
                            name="employeeId"
                            placeholder={'Enter employee id'}
                            error={errors.employeeId}
                        />
                    </FormGroup>


                    <FormGroup>
                        <div className='select-col'>
                            <label>Role</label>
                            <Input type={'select'} value={role} name={'role'}
                                   options={roles.map(
                                       role => ({value: role.value, label: role.name})
                                   )}
                                   onChange={(e) => setRole(e.target.value)}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        {
                            role === 'ROLE_USER' && <Departments value={departments} onChange={(e) => {
                                setDepartments(e)
                            }}/>
                        }
                    </FormGroup>
                    <ButtonContainer>
                        <Link to={`/users`}>
                            Cancel
                        </Link>
                        <Button disabled={loading} type={'submit'} loading={loading}
                                label={id ? "Update User" : "Create"}
                        />
                    </ButtonContainer>
                    {error && <div style={{color: 'red'}}>{error}</div>}
                </form>
            </Container>
        </AppLayout>
    );
};

export default CreateUser;
//onChange={(e) => setDepartmentId(e.target.value)}
//defaultText={defaultText}


// {data.map(item => (
//     <tr key={item.id}>
//         {columns.map(column => (
//             <td key={column.key}>{item[column.key]}</td>
//         ))}
