import React, {useContext, useEffect, useState} from 'react'
import styled from "styled-components";
import SchoolLogo from '../../../images/chitkara-logo.svg';
import { Link } from 'react-router-dom';
import AppTheme from '../../../themes/AppTheme';

// Assets
import AvatarIcon from '../../../images/dummy-profile.svg';
import LogoutIcon from '../../../images/logout-icon.svg';
import Api from '../../../helpers/Api';
import SidebarListItem from './SubComponents/SidebarListItem';
import RevokeIcon from "../../../images/revoke-icon.svg";
import ConfirmationModal from "../modal/Modal";
import {CopyButton, DescriptionCol, EmbedHeader, ModalContent} from "../../certificates/ListCertificates";
import AppContext from "../../../context/AppContext";

export const SidebarContainer = styled.div`
    width:267px;
    background: ${AppTheme.header.colors.whiteColor};
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    border-right: 1px solid rgba(9, 30, 66, 0.141176);
    float:left;
    height:calc(100vh - 0px);
    @media screen and (max-width: 1240px) {
        width: 220px;
    }
   
`;

export const LogoContainer = styled.div`
    width:100%;
    padding:15px 24px;
    text-align:left;
    >a{
        >img{
            width:156px;
            height:52px;
        }
    }
`;

export  const LogoutContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-start;
    padding:20px ;
`

export const ProfileContainer = styled.div`
    padding-right:12px;
    >a{
        cursor:pointer;
        background:${AppTheme.header.colors.blueColor};
        width:40px;
        height:40px;
        border-radius:100px;
        display:flex;
        align-items:center;
        justify-content:center;
        color:${AppTheme.header.colors.whiteColor} !important;
        font-size:14px;
        font-weight:600;
        text-transform:uppercase;
    }
`;

export const InfoContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    width:100%;
    >.col{
        display:flex;
        align-items:center;
        justify-content:space-between;
        width:100%;
        >h4{
            color:${AppTheme.header.colors.greyColor};
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            margin-bottom:0px;
        }
        >a{
            cursor:pointer;
        }
    }
    >span{
        color:${AppTheme.header.colors.lightGreyColor};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
`;

export const NavigationBox = styled.div`
`;

const Sidebar = ({type}) => {

    let profileJson = localStorage.getItem('profile');
    if(!profileJson) {
        profileJson = {};
    }

    const {setUser} = useContext(AppContext);

    const [profile, setProfile] = useState(profileJson);
    const [logoutModal, setLogoutModal] = useState(false);

    useEffect(() => {
        Api.getProfile().then(p => {
            setProfile(p);
            localStorage.setItem('profile', JSON.stringify(p));
        }).catch(err => {
        });
    }, []);

    useEffect(() => {
        if(profile) {
            setUser(profile);
        }
    }, [profile, setUser]);

    const getInitials =(name) => {
        if (!name) return '';
        const names = name.split(' ');
        const initials = names.map((word) => word.charAt(0).toUpperCase()).join('');
        return initials;
    }

  return (
    <SidebarContainer>
        <NavigationBox>
            <LogoContainer>
                <Link>
                    <img src={SchoolLogo} alt="Logo" />
                </Link>
            </LogoContainer>

            {/* Sidebar Navigation */}
            <SidebarListItem />
      </NavigationBox>

     {/* Logout */}
     <LogoutContainer>
         <ConfirmationModal
             isOpen={logoutModal}
             closeModal={()=>{
                 setLogoutModal(false)
             }}
         >
             <div>
                 <ModalContent>
                     <EmbedHeader>
                         <img src={RevokeIcon}/>
                         <span>Logout</span>
                     </EmbedHeader>
                     <DescriptionCol>
                         <span>Are you sure you want to logout?</span>
                     </DescriptionCol>
                     <CopyButton>
                         <Link onClick={()=>{
                                setLogoutModal(false)
                         }}>
                             No
                         </Link>
                         <button
                             onClick={()=>{
                                 localStorage.clear();
                                 window.location.href = '/auth';
                             }}
                         >
                             Yes
                         </button>
                     </CopyButton>
                 </ModalContent>
             </div>
         </ConfirmationModal>
        <ProfileContainer>
            <a
                onClick={() => {
                    window.location.href = '/profile';
                }}
            >
                {profile?.name ? getInitials(profile.name) : ''}
            </a>
        </ProfileContainer>
        <InfoContainer>
            <div className='col'>
                <h4>{profile?.name}</h4>
                <a
                    onClick={() => {
                        setLogoutModal(true);
                    }}
                >
                    <img src={LogoutIcon} alt='Logout icon' />
                </a>
            </div>
            <span>{profile?.email}</span>
        </InfoContainer>
     </LogoutContainer>
    </SidebarContainer>
  )
}

export default Sidebar;
