import React, {useEffect} from 'react';
import {PolotnoContainer, WorkspaceWrap} from "polotno";
import {Workspace} from "polotno/canvas/workspace";
import {enrichWithAttributes} from "../../helpers/Utils";
import store from "../../pages/templates/design/components/store";



const ReadOnlyCanvasV2 = ({data, widthParent, width, height,  heightParent, attributes = {}, quantumLedgerId, firstPageOnly = false, viewMode}) => {

    useEffect(() => {
        if(!data) return;
        console.log({data})
        if (firstPageOnly) {
            data.pages = [data.pages[0]];
        }
        enrichWithAttributes(data, attributes, quantumLedgerId, viewMode).then(enrichedData => {
            store.loadJSON(enrichedData);
        });
    }, [attributes, data, firstPageOnly, quantumLedgerId, viewMode]);

    console.log({widthParent, width, height, heightParent})
    console.log({scale: store.scale})

    if(!data) return null;
    return (
        <PolotnoContainer style={{width:  widthParent || width, height: heightParent || height}}>
            <WorkspaceWrap>
                <Workspace store={store} pageControlsEnabled={false}/>
            </WorkspaceWrap>
        </PolotnoContainer>
    );
}

export default ReadOnlyCanvasV2;
