import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Api from "../../../helpers/Api"; // Adjust the import path as necessary
import Button from "../../../components/form/Button";
import styled from "styled-components";
import CommonTable from '../../components/table/CommonTable';

export const Container = styled.div`
    width:100%;
    padding:0 14px;
`;

const ListDepartmentUsers = ({userId}) => {
    const [departmentUsers, setDepartmentUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const departmentColumns = [
        { key: 'departmentName', render:(item)=>{
            return item.department.name
        } ,label: 'Department Name' },
        { key: 'roleName', render:(item)=>{
            return item.role.name
        }, label: 'Roles' },
    ];

    useEffect(() => {
        fetchDepartmentUsers();
    }, []);

    const fetchDepartmentUsers = async () => {
        setLoading(true);
        try {
            const data = await Api.listDepartmentUsers(userId);
            setDepartmentUsers(data);
            setError('');
        } catch (err) {
            setError('Failed to fetch departmentUsers. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (userId, id) => {
        navigate(`/users/${userId}/departments/${id}`);
    };
    const handleDelete =() =>{}

    if (loading) return 'Loading...';
    if (error) return <div>Error: {error}</div>;

    return (
        <Container>

            {departmentUsers.length > 0 ? (
                <CommonTable
                    columns={departmentColumns}
                    data={departmentUsers}
                    rowKey={(record) => `${record.department.name}-${record.role.name}`}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
                // <ul>
                //     {departmentUsers.map(({user, department, role, id}) => (
                //         <li key={id}>
                //              {department.name} - {role.name}
                //             <div>
                //                 <Button label="Edit" onClick={() => handleEdit(user.id, id)}/>
                //             </div>
                //         </li>
                //     ))}
                // </ul>
            ) : (
                <div>No departmentUsers found.</div>
            )}
        </Container>
    );
};

export default ListDepartmentUsers;
