import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Api from "../../helpers/Api"; // Adjust the import path as necessary
import AppLayout from '../../components/layout/AppLayout';
import SubHeader from '../../components/subheader/SubHeader';
import styled from "styled-components";
import CommonTable from '../components/table/CommonTable';
import LoadingPage from "../loading/LoadingPage";
import AppTheme from '../../themes/AppTheme';
import Input from "../../components/form/Input";
import SearchIcon from '../../images/search-icon.svg';
import DefaultIcon from '../../images/not-found.svg';
import AppContext from "../../context/AppContext";
import NotFound from '../components/Notfound/NotFound';

export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  float: left;
  width: 100%;
  padding: 0;

`;


export const Filters = styled.div`
  width: 100%;
  float: left;
  padding: 16px 15px;
  border-top: 1px solid ${AppTheme.header.colors.inputBorder};

  > .filters-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    > .search-box {
      > div {
        margin-bottom: 0px;

        > div {
          border-radius: 8px;
          border: 1px solid ${AppTheme.header.colors.inputBorder};
          background: ${AppTheme.header.colors.whiteColor};
          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          height: 44px;
          width: 230px;
        }
      }
    }

    > .box-col {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > div {
        margin-bottom: 0px;

        > select {
          border-radius: 8px;
          border: 1px solid ${AppTheme.header.colors.inputBorder};
          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          height: 44px;
          min-width: 210px;
          color:#000000 !important;
        }
      }
    }

    > div {
      gap: 10px;

      > label {
        margin-bottom: -3px;
      }

      > select {
        width: 100%;
      }
    }
  }
`;

const ListUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [query, setQuery] = useState('');
    const navigate = useNavigate();

    const {user} = React.useContext(AppContext);
    console.log({user});

    const [filters, setFilters] = useState({disabled: false});

    const userColumns = [
        {key: 'name', label: 'Name'},
        {
            key: 'departments', label: 'Departments', render: (user) => {
                if (user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_SUPER_ADMIN')) return 'All Departments';
                return user.departmentUsers.map(({department}) => department.name).join(', ')
            }
        },
        {key: 'roles', label: 'Roles'},
        {key: 'employeeId', label: 'Employee Id'},
        {key: 'status', label: 'Status', render: (user) => user.disabled === false ? 'Active' : 'Inactive'},
        {key: 'createdAt', label: 'Created At', type: 'datetime'},
    ];

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const data = await Api.listUsers();
            setUsers(data);
            setError('');
        } catch (err) {
            setError('Failed to fetch users. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (id) => {
        navigate(`/users/${id}`);
    };
    const handleView = (id) => {
        navigate(`/users/${id}/view`);
    };

    if (loading) return <LoadingPage/>
    if (error) return <div>Error: {error}</div>;

    const filteredUsers = users.filter((user) => {
        const match = user.name?.toLowerCase().includes(query.toLowerCase()) || user.email?.toLowerCase().includes(query.toLowerCase()) || user.phone?.toLowerCase().includes(query.toLowerCase());
        return match && (filters.disabled === null || user.disabled === filters.disabled);
    });

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading='Users List'
                    description={'View and Organize All Users'}
                    href={'/users/create'}
                    label={'Add New User'}
                />
                <Filters>
                    <div className='filters-box'>
                        <div className='search-box'>
                            <Input
                                type='text'
                                value={query}
                                Icon={SearchIcon}
                                placeholder='Search'
                                onChange={(e) => setQuery(e.target.value)}
                            />
                        </div>
                        <div className='box-col'>
                            <Input type={'select'} value={filters.disabled ? 'true' : ''} onChange={e => {
                                setFilters({...filters, disabled: !!e.target.value});
                            }} options={[
                                {label: 'Active', value: ''},
                                {label: 'Inactive', value: 'true'},
                            ]} defaultText={null}/>
                        </div>
                    </div>
                </Filters>


                {/* Table component */}
                {filteredUsers.length > 0 ? (
                    <CommonTable
                        columns={userColumns}
                        data={filteredUsers}
                        onEdit={handleEdit}
                        getActionsConfig={(u) => {
                            if (!user || user?.roles?.includes('ROLE_USER')) {
                                return {edit: false, actions: false};
                            }
                            if(u.id === user.id){
                                return {edit: false, actions: false};
                            }
                            if (u.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_SUPER_ADMIN')) {
                                return {edit: false, actions: false};
                            }
                            if (u.roles.includes('ROLE_SUPER_ADMIN') && !user.roles.includes('ROLE_SUPER_ADMIN')) {
                                return {edit: false, actions: false};
                            }
                            return {edit: true};
                        }}
                    />
                ) : (
                    <NotFound
                        icon={DefaultIcon}
                        message={'Looks like you have no user  yet'}
                    />
                )}
            </Container>
        </AppLayout>
    );
};

export default ListUsers;
