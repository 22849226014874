import React, {useEffect} from 'react';
import Api from "../../helpers/Api";
import {useNavigate} from "react-router-dom";

const AuthCheck = () => {

    const navigate = useNavigate();
    useEffect(() => {
        let token = localStorage.getItem('accessToken');
        if (token) {
            Api.ACCESS_TOKEN = token;
            navigate('/dashboard');
        } else {
            navigate('/auth/login');
        }
    }, [navigate]);
    return (
        <div>
            Please wait..
        </div>
    )
}
export default AuthCheck;
