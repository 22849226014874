import React, {useEffect, useState} from 'react';
import {PolotnoContainer, WorkspaceWrap} from 'polotno';
import {Toolbar} from 'polotno/toolbar/toolbar';
import {ZoomButtons} from 'polotno/toolbar/zoom-buttons';
import {Workspace} from 'polotno/canvas/workspace';

import '@blueprintjs/core/lib/css/blueprint.css';

import SidePanel from "./components/SidePanel";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../../helpers/Api";
import styled from "styled-components";
import AppTheme from "../../../themes/AppTheme";
import SaveIcon from '../../../images/save-blue.svg';
import store from "./components/store";

export const ContainerSidebar = styled.div`
  float: left;
  height: 100%;

  > div {
    > .bp5-navbar {
      background: #19191b !important;

      > div {
        > div {
          > .polotno-side-panel-tab {
            > div {
              color: ${AppTheme.header.colors.whiteColor};
              font-size: 11px;
              font-family: "Inter", sans-serif !important;
            }

            &.active {
              background: #252627;

              > div {
                color: #ffffff;
              }
            }
          }
        }
      }

      > .bp5-navbar.polotno-panel-container {
        width: 320px;

        > div {
          > .bp5-tabs {
            > .bp5-tab-list {
              > div#bp5-tab-title_undefined_font {
                display: none !important;
              }

              > .bp5-tab-indicator-wrapper {
                > .bp5-tab-indicator {
                  display: none !important;
                }
              }

              > div#bp5-tab-title_undefined_text {
                font-weight: 600;
                color: #000000;
              }
            }
          }

          > button {
            border-radius: 2px;
            background: ${AppTheme.header.colors.blackColor};
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: auto;
            padding: 15px;
            border: none;
            margin-top: 5px !important;
            color: ${AppTheme.header.colors.whiteColor};
          }

          > .bp5-input-group {
            > span.bp5-icon.bp5-icon-search {
              display: none;
            }

            > .bp5-input[type=search] {
              display: none;
            }
          }

          > div {
            > p {
              margin-bottom: 0px;
              font-size: 16px;
              font-weight: 600;
              color: ${AppTheme.header.colors.blackColor};
            }

            > button {
              background: #F0F0F0;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              display: flex;
              align-items: center;
              justify-content: flex-start;
              height: auto;
              padding: 15px;
              margin-top: 5px !important;
            }
          }
        }
      }
    }
  }

  > .bp5-navbar {
    background: #252627 !important;
  }
`;

export const HeaderToolBar = styled.div`
  width: 100%;

  > .bp5-navbar {
    background: ${AppTheme.header.colors.blueColor} !important;
    height: 62px;

    > div {
      > .bp5-navbar-group.bp5-align-left {
        height: 62px !important;
        width: auto !important;

        > button {
          > span.bp5-icon.bp5-icon-undo {
            color: #ffffff !important;
          }

          > span.bp5-icon.bp5-icon-redo {
            color: #ffffff !important;
          }
        }

        > .bp5-overflow-list {

          justify-content: flex-end !important;

          > .bp5-button.bp5-minimal {
            > span.bp5-button-text {
              color: #ffffff !important;
            }
          }

          > .bp5-button-group {
            > button {
              > span.bp5-icon.bp5-icon-align-right {
                > svg {
                  color: #ffffff;
                }
              }

              > span.bp5-icon.bp5-icon-align-justify {
                > svg {
                  color: #ffffff;
                }
              }

              > span.bp5-icon.bp5-icon-align-center {
                color: #ffffff !important;
              }

              > span.bp5-icon.bp5-icon-bold {
                color: #ffffff !important;
              }

              > span.bp5-icon.bp5-icon-italic {
                color: #ffffff !important;
              }

              > span.bp5-icon.bp5-icon-underline {
                color: #ffffff !important;
              }

              > span.bp5-icon.bp5-icon-strikethrough {
                color: #ffffff !important;
              }

              > span.bp5-icon.bp5-icon-align-left {
                color: #ffffff !important;
              }
            }
          }

          > span.bp5-popover-target {
            > div {
              width: 20px !important;
              height: 20px !important;
              margin-bottom: 0px !important;

              > div {
                width: 20px !important;
                height: 20px !important;
                margin-top: 5px !important;
                border-radius: 4px;
              }
            }

            > button {
              > svg {
                color: #ffffff !important;
              }

              > span.bp5-icon.bp5-icon-left-join {
                color: #ffffff !important;
              }

              > span.bp5-icon.bp5-icon-caret-down {
                color: #ffffff !important;
              }

              > .bp5-button-text {
                color: #ffffff !important;

                > svg {
                  color: #ffffff !important;
                }
              }
            }
          }
        }

      }

      > .bp5-navbar-group.bp5-align-right {
        height: 62px !important;
        float: initial;
        display: flex;
        justify-content: flex-end;

        > span.bp5-popover-target {
          > button {
            > span.bp5-icon.bp5-icon-layers {
              color: #ffffff !important;
            }

            > .bp5-button-text {
              color: #ffffff !important;
            }

            > span.bp5-button-text {
              > svg {
                color: #ffffff !important;
              }
            }

            > span.bp5-icon.bp5-icon-unlock {
              > svg {
                color: #ffffff !important;
              }
            }

            > span.bp5-icon.bp5-icon-duplicate {
              > svg {
                color: #ffffff !important;
              }
            }

            > span.bp5-icon.bp5-icon-trash {
              > svg {
                color: #ffffff !important;
              }
            }

            > .bp5-button-text {
              color: #ffffff !important;

              > svg {
                color: #ffffff !important;
              }
            }
          }

          > span.bp5-popover-target {
            > button {
              > .bp5-button-text {
                color: #ffffff !important;

                > svg {
                  color: #ffffff !important;
                }
              }
            }
          }
        }
      }

       
    }

     
  }
`;

export const TemplatePanel = styled.div`
  width: 100%;
  float: left;
  height: 100%
`;

export const TemplateViewr = styled.div`
  float: right;
  width: calc(100% - 392px);
  height: 100%
`;

export const ButtonsCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button {
    border: none;
    background: ${AppTheme.header.colors.whiteColor};
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    font-size: 14px;
    padding: 0 10px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blueColor};

    > img {
      margin-right: 8px;
    }
  }

  > a {
    border-radius: 6px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    color: ${AppTheme.header.colors.whiteColor};
  }
`;

export const HidePrintView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;

  > label {
    color: ${AppTheme.header.colors.whiteColor};
    padding-left: 5px;
    font-size: 14px;
  }

  > input {
    width: 16px;
    height: 16px;
  }
`;

const ImageCustomControls = ({store, element, elements}) => {
    const [checked, setChecked] = useState(element.custom?.hideFromPrintView)
    return (
        <HidePrintView>
            <input type={"checkbox"}
                   id={"hideFromPrintView"}
                   name={"hideFromPrintView"}
                   checked={checked}
                   onChange={(e) => {
                       setChecked(!!e.target.checked)
                       element.set({
                           custom: {
                               ...element.custom,
                               hideFromPrintView: !!e.target.checked
                           }
                       })
                   }}
            />
            <label htmlFor={"hideFromPrintView"}>Hide from print view</label>
        </HidePrintView>
    );

}

const ActionControls = ({store}) => {


    const navigate = useNavigate();
    const {id} = useParams(); // Get the :id parameter from the route
    const [template, setTemplate] = useState({canvasData: {}}); // Updated state for template
    const [loading, setLoading] = useState(true);

    const updateTemplate = async () => {
        setLoading(true);
        const json = store.toJSON();
        const previewImageBase64 = await store.toDataURL({
            pageId: store.pages?.[0]?.id,
            mimeType: 'image/png',
            pixelRatio: 0.5,
        });
        return Api.updateTemplate(id, template.name, template.department.id, template.school?.id, template.data, json, previewImageBase64);
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const t = await Api.getTemplate(id);

                setTemplate(t);
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }

        fetchData();
    }, [id]);

    return (
        <ButtonsCol>
            <a href={`/templates/${template.id}`}>
                Go Back
            </a>
            <button
                onClick={() => {
                    updateTemplate().then(() => {
                        alert('Template saved successfully')
                    });
                }}
            >
                <img src={SaveIcon} alt='save icon'/>
                Save
            </button>

            <button
                onClick={() => {
                    updateTemplate().then(() => {
                        navigate(`/templates/${template.id}/verification`);
                    })
                }}
            >
                <img src={SaveIcon} alt='save icon'/>
                Save & Next
            </button>
        </ButtonsCol>
    );
};


const DesignComponent = ({store}) => {
    const {id} = useParams(); // Get the :id parameter from the route

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchData() {
            try {
                const template = await Api.getTemplate(id);
                setLoading(false);
                store.loadJSON(template.canvasData);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }

        fetchData();
    }, [id, store]);

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <PolotnoContainer style={{width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column'}}>
            <HeaderToolBar>
                <Toolbar store={store} components={{
                    ActionControls,
                    ImageRemoveBackground: ImageCustomControls
                }}/>
            </HeaderToolBar>
            <TemplatePanel>
                <ContainerSidebar>
                    <SidePanel store={store}/>
                </ContainerSidebar>
                <TemplateViewr>
                    <WorkspaceWrap>
                        <Workspace store={store}/>
                        <ZoomButtons store={store}/>
                    </WorkspaceWrap>
                </TemplateViewr>
            </TemplatePanel>
        </PolotnoContainer>
    );

};

const DesignV2 = () => {
    return <DesignComponent store={store}/>
}

export default DesignV2;

