import React, {useEffect, useState} from 'react';
import Api from "../../helpers/Api";
import {useParams, useSearchParams} from "react-router-dom";
import styled from "styled-components";
import AppTheme from "../../themes/AppTheme";
import jsPDF from 'jspdf';
import {PolotnoContainer, WorkspaceWrap} from "polotno";
import SidePanel from "../templates/design/components/SidePanel";
import {Workspace} from "polotno/canvas/workspace";
import {ZoomButtons} from "polotno/toolbar/zoom-buttons";
import {enrichWithAttributes, scaleCanvas} from "../../helpers/Utils";
import store from "../templates/design/components/store";
import {unstable_setTextOverflow} from "polotno/config";

export const Container = styled.div`
  width: 100%;
  background: ${AppTheme.header.colors.backgroundLight};
  float: left;
  height: 100%;
`;




const DownloadCertificate = () => {
    const {id} = useParams(); // Get the :id parameter from the route
    const [searchParams] = useSearchParams(); // Get the :id parameter from the route

    const viewMode = searchParams.get('viewMode');

    const [certificate, setCertificate] = useState(null); // Updated state for template
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.getPublicFullCertificate(id);
                setCertificate(response);
                setLoading(false);

                enrichWithAttributes(response.templateData.canvasData, response.data, response.quantumLedgerId, viewMode).then(enrichedData => {
                    store.loadJSON(scaleCanvas(enrichedData,4));
                });
            } catch (err) {
                setLoading(false);
            }
        }

        fetchData();
        unstable_setTextOverflow('change-font-size');
    }, [id]);

    if (loading) {
        return <div>Loading..</div>
    }


    return (
        <Container>
            <PolotnoContainer style={{width: '100vw', height: '100vh'}}>
                <WorkspaceWrap>
                    <Workspace store={store} pageControlsEnabled={false}/>
                </WorkspaceWrap>
            </PolotnoContainer>
            <button id={"download"} onClick={() => {
                 store.saveAsPDF({ fileName: certificate.quantumLedgerId + '.pdf', pixelRatio: 2});
            }}>Download</button>
        </Container>
    );
};

export default DownloadCertificate;
