import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Api from "../../helpers/Api"; // Adjust the import path as necessary
import AppLayout from '../../components/layout/AppLayout';
import SubHeader from '../../components/subheader/SubHeader';
import styled from "styled-components";
import LoadingPage from "../loading/LoadingPage";
import AppTheme from '../../themes/AppTheme';

export const Container = styled.div`
  width: 100%;
  padding: 0 14px;
`;

export const BoxContainer = styled.div`
    width:100%;
    float:left;
    display:flex;
    flex-flow:wrap;
    gap:14px;
    >.dashboard-item{
        border-radius:3px;
        max-height:140px;
        height:140px;
        display:flex;
        align-items:center;
        >a{
            text-decoration:none;
            display:flex;
            flex-direction:column;
            width:100%;
            padding:20px;
            >label{
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                color:${AppTheme.header.colors.blackColor};
                margin-bottom:10px;
            }
            >span{
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 36px;
                color:${AppTheme.header.colors.blackColor};
            }
            >p{
                margin:10px 0 0 0;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
                text-decoration-line: underline;
                color:${AppTheme.header.colors.blackColor};
            }
        }
    }
`;

const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    let profileJson = localStorage.getItem('profile');
    if(!profileJson) {
        profileJson = {};
    }
    const [profile, setProfile] = useState(profileJson);

    useEffect(() => {
        Api.getProfile().then(p => {
            setProfile(p);
            localStorage.setItem('profile', JSON.stringify(p));
        }).catch(err => {
        });
    }, []);

    useEffect(() => {
        Api.getDashboardAnalytics().then(data => {
            setData(data);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    }, [])


    if (loading) return <LoadingPage heading={'Dashboard'}/>
    const BackColors = ['#D1FADF', '#F4F3FF', '#FFE4E8','#FEF7C3','#FDEAD7','#E0F2FE','#E6F4D7','rgba(241, 215, 249, 0.50)',];

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={`Good afternoon, ${profile?.name} 👋`}
                    description={'Here is what’s happening with your account today.'}
                />

                {/* Table component */}
                <BoxContainer>
                    {data.map((item, index) => {
                        console.log(item.link);
                        console.log(item.value);
                        return (
                            <div className={'dashboard-item'} style={{
                                float: 'left',
                                width: 'calc(100% / 4 - 14px)',
                                padding: '0px',
                                textAlign: 'left',
                                marginLeft: 0,
                                background: BackColors[index % 8],
                                marginBottom: '0px',
                            }}>
                                <Link to={item.link}>
                                    <label>
                                        {item.label}
                                    </label>
                                    <span>
                                        {item.value}
                                    </span>
                                    <p>View Details</p>
                                </Link>
                            </div>
                        )
                    })}
                </BoxContainer>
            </Container>
        </AppLayout>
    );
};

export default Dashboard;
