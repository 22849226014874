import React from 'react';
import Modal from 'react-modal';
import AppTheme from "../../../themes/AppTheme";

const ConfirmationModal = ({ isOpen, closeModal, children,width }) => {

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: '20px',
          backgroundColor: `${AppTheme.header.colors.whiteColor}`,
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          maxWidth:width ? width : 520,
          width:'100%'
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Modal"
      style={customStyles}
      ariaHideApp={false}
      width={width}
    >
      {children}
    </Modal>
  );
};

export default ConfirmationModal;
