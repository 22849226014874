import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Api from "../../helpers/Api"; // Adjust the import path as necessary
import AppLayout from '../../components/layout/AppLayout';
import styled from "styled-components";
import SubHeader from '../../components/subheader/SubHeader';
import TemplateListItems from './components/TemplateListItems';
import AppTheme from '../../themes/AppTheme';

// Assets
import EditIcon from '../../images/edit-icon.svg';
import DeleteIcon from '../../images/delete-icon.svg';
import DownIcon from '../../images/arrow-down.svg';
import DuplicateIcon from '../../images/duplicate-icon.svg';
import AwardIcon from '../../images/award-icon.svg';
import ViewIcon from '../../images/view-certificate.svg';
import SearchIcon from '../../images/search-icon.svg';
import DisableDelete from '../../images/disabled-delete.svg';
import LoadingPage from "../loading/LoadingPage";
import Input from "../../components/form/Input";
import {getSchoolOptionsForSelect} from "../../helpers/getSchools";
import NotFound from '../components/Notfound/NotFound';
import DefaultIcon from '../../images/not-found.svg';
import {serverToLocalDateTime} from "../../helpers/Utils";
import ConfirmationModal from "../components/modal/Modal";
import RevokeIcon from "../../images/revoke-icon.svg";
import {CopyButton, DescriptionCol, EmbedHeader, ModalContent} from "../certificates/ListCertificates";


export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  float: left;
  width: 100%;
  // max-height: calc(100vh - 100px);
  // overflow-y: auto;
`;

export const TemplateList = styled.div`
  width: 100%;
  float: left;

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      border-top: 1px solid ${AppTheme.header.colors.tableBorder};
      padding: 15px 20px;
    }
  }
`;

export const ActionButton = styled.div`
  > button {
    height: 40px;
    border-radius: 40px;
    border: 1px solid ${AppTheme.header.colors.blueColor};
    background: ${AppTheme.header.colors.lightBlueColor};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blueColor};
    padding: 0 10px;
    cursor: pointer;

    > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: ${AppTheme.header.colors.blueColor};
      padding-right: 10px;
    }

    .drop-icon {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid ${AppTheme.header.colors.blueColor};
      padding-left: 10px;
    }
  }

  > .show-drop {
    right: 37px;
    border-radius: 8px;
    border: 1px solid ${AppTheme.header.colors.tableBorder};
    background: ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    position: absolute;
    margin-top: 6px;

    > ul {
      list-style: none;
      margin: 0;
      padding: 10px 0px;

      > li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0px 10px;

        > a {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: ${AppTheme.header.colors.blackColor};
          padding: 10px 8px;
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          cursor: pointer;

          > img {
            margin-right: 10px;
            margin-top: 2px;
          }

          &:hover {
            border-radius: 6px;
            background: #F9FAFB;
          }
        }
      }
    }

    &.open-upwards {
        /* When dropdown is near the bottom, open upwards */
       
        
    }
  }
`;

export const Filters = styled.div`
  width: 100%;
  float: left;
  padding: 16px 15px;
  border-top: 1px solid ${AppTheme.header.colors.inputBorder};

  > .filters-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    > .search-box {
      > div {
        margin-bottom: 0px;

        > div {
          border-radius: 8px;
          border: 1px solid ${AppTheme.header.colors.inputBorder};
          background: ${AppTheme.header.colors.whiteColor};
          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          height: 44px;
          width: 230px;
        }
      }
    }

    > .box-col {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > div {
        margin-bottom: 0px;

        > select {
            border-radius: 8px;
            border: 1px solid ${AppTheme.header.colors.inputBorder};
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            height: 44px;
            min-width: 195px;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right:30px;
        }
      }
    }

    > div {
      gap: 10px;

      > label {
        margin-bottom: -3px;
      }

      > select {
        width: 100%;
      }
    }
  }
`;

export const Form = styled.div`
  padding-top:15px;
  padding-bottom:10px;
    > div {
        > label {
          margin-bottom: 3px;
        }

        > div {
          background: transparent;
          padding: 0;

          > input {
            border-radius: 8.876px;
            border: 1.109px solid ${AppTheme.header.colors.inputBorder};
            background: ${AppTheme.header.colors.whiteColor};
            box-shadow: 0px 1.109px 2.219px 0px rgba(16, 24, 40, 0.05);
            padding: 0 10px;
          }
        }
    }
`;


const ListTemplates = () => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [showDrop, setShowDrop] = useState(false);
    const [filters, setFilters] = useState({});
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [schoolOptions, setSchoolOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [programOptions, setProgramOptions] = useState([]);
    const [query, setQuery] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [name, setName] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState({});


    useEffect(() => {
        Api.listDepartments().then(departments => {
            setDepartmentOptions(departments.map(d => ({label: d.name, value: d.id})));
        });
        Api.listYears().then(years => {
            setYearOptions(years.map(d => ({label: d.name, value: d.id})));
        });
        Api.listPrograms().then(programs => {
            setProgramOptions(programs.map(d => ({label: d.name, value: d.id})));
        });

        Api.listSchools(true).then(schools => {
            const schoolsArray = schools.map(d => ({label: d.name, value: d.id}))
            setSchoolOptions(schoolsArray);
        });
    }, []);

    const dropdownRefs = useRef({});

    useEffect(() => {
        fetchTemplates();
        // Event listener to close dropdown when clicked outside of it
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchTemplates = async () => {
        setLoading(true);
        try {
            const data = await Api.listTemplates();
            setTemplates(data);
            setError('');
        } catch (err) {
            setError('Failed to fetch templates. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handlePublish = async (id) => {
        await Api.publishTemplate(id);
        fetchTemplates();
    };

    const handleUnpublish = async (id) => {
        await Api.unpublishTemplate(id);
        fetchTemplates();
    };

    const handleDelete = async (template) => {
        if (template.totalAwarded > 0) {
            //alert('Cannot delete template with awarded certificates');
            return;
        }
        setLoading(true);
        await Api.deleteTemplate(template.id);
        fetchTemplates();
    };

    const handleDuplicate = async (template) => {
        setName(template.name);
        setSelectedTemplate(template);
        setShowDuplicateModal(true);
    };

    const duplicateTemplate = async (template, name) => {
        setLoading(true);
        await Api.duplicateTemplate(template.id, name);
        fetchTemplates();
    }

    const handleEdit = (template) => {
        if (template.totalAwarded > 0) {
            //alert('Cannot delete template with awarded certificates');
            return;
        }
        navigate(`/templates/${template.id}`);
    };

    const handleViewCertificates = (id) => {
        navigate(`/templates/${id}/certificates`);
    };

    const handleClickOutside = (event) => {
        const newDropdownOpen = { ...dropdownOpen };
        Object.keys(dropdownRefs.current).forEach((key) => {
            if (dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)) {
                newDropdownOpen[key] = false;
            }
        });
        setDropdownOpen(newDropdownOpen);
    };
    const toggleDropdown = (id) => {
        setDropdownOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };
    useEffect(() => {
        const checkDropdownPosition = () => {
            Object.keys(dropdownRefs.current).forEach(id => {
                const dropdown = dropdownRefs.current[id];
                if (dropdown) {
                    const rect = dropdown.getBoundingClientRect();
                    const windowHeight = window.innerHeight;
                    if (rect.bottom > windowHeight) {
                        dropdown.classList.add('open-upwards');
                    } else {
                        dropdown.classList.remove('open-upwards');
                    }
                }
            });
        };

        window.addEventListener('resize', checkDropdownPosition);
        window.addEventListener('scroll', checkDropdownPosition);
        document.addEventListener('mousedown', handleClickOutside);

        // Initial check
        checkDropdownPosition();

        return () => {
            window.removeEventListener('resize', checkDropdownPosition);
            window.removeEventListener('scroll', checkDropdownPosition);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    const filterTemplates = (template) => {
        if (filters.school && template.school?.id != filters.school) {
            return false;
        }
        if (filters.department && template.department.id != filters.department) {
            return false;
        }
        if (filters.status && template.status !== filters.status) {
            return false;
        }
        if (filters.year && template.data.year !== filters.year) {
            return false;
        }
        if (filters.program && template.data.program !== filters.program) {
            return false;
        }
        if (query && !template.name.toLowerCase().includes(query.toLowerCase())) {
            return false;
        }
        return true;
    }

    if (loading) return <LoadingPage/>
    if (error) return <div>Error: {error}</div>;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {day: 'numeric', month: 'short', year: 'numeric'};
        const formattedDate = date.toLocaleDateString('en-GB', options);
        return formattedDate.replace(',', ''); // Remove comma after the day
    };

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={'All Templates'}
                    description={'Your Complete Credential Template Library'}
                    href={'/templates/create'}
                    label={'Add New Template'}
                />
                <Filters>
                    <div className='filters-box'>
                        <div className='search-box'>
                            <Input
                                type='text'
                                Icon={SearchIcon}
                                value={query}
                                placeholder='Search'
                                onChange={(e) => setQuery(e.target.value)}
                            />
                        </div>
                        <div className='box-col'>
                            <Input type={'select'} value={filters.department} onChange={e => {
                                setFilters({...filters, department: e.target.value});
                            }} options={departmentOptions} defaultText={'Select department'}/>
                            <Input type={'select'} value={filters.year} onChange={e => {
                                setFilters({...filters, year: e.target.value});
                            }} options={yearOptions} defaultText={'Select year'}/>
                            <Input type={'select'} value={filters.school} onChange={e => {
                                setFilters({...filters, school: e.target.value});
                            }} options={schoolOptions} defaultText={'Select school'}/>

                            <Input type={'select'} value={filters.program} onChange={e => {
                                setFilters({...filters, program: e.target.value});
                            }} options={programOptions} defaultText={'Select program'}/>
                            {/*<Input type={'select'} label={'Status'} value={filters.status } onChange={e=>{
                                setFilters({...filters, status:e.target.value});
                            }} options={[
                                { label: 'Draft', value: 'draft' },
                                { label: 'Published', value: 'published' },
                                { label: 'Unpublished', value: 'unpublished' },
                            ]} defaultText={'All'}/>*/}
                        </div>
                    </div>
                </Filters>
                {templates.length > 0 ? (
                    <TemplateList>
                        <ul>
                            {templates.filter(filterTemplates).map(template => (
                                <li key={template.id}>
                                    <TemplateListItems
                                        previewImageUrl={template.previewImageUrl}
                                        canvasData={template.canvasData}
                                        id={template.id}
                                        totalAwarded={template.totalAwarded}
                                        name={template.name}
                                        date={serverToLocalDateTime(template.createdAt)}
                                        renderActions={() => (
                                            <ActionButton>
                                                <button onClick={() => toggleDropdown(template.id)}>
                                                    <span>Take Action</span>
                                                    <div className='drop-icon'>
                                                        <img src={DownIcon} alt="Down icon "/>
                                                    </div>
                                                </button>
                                                {dropdownOpen[template.id] && (
                                                    <div className='show-drop'
                                                         ref={node => (dropdownRefs.current[template.id] = node)}>
                                                        <ul>
                                                            <li>
                                                                <a href={`/templates/${template.id}/award`}>
                                                                    <img src={AwardIcon} alt='Award icon'/>
                                                                    Award Credentials
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href={`/templates/${template.id}`}>
                                                                    <img src={EditIcon} alt='Edit icon'/>
                                                                    Edit Template
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => handleDuplicate(template)}>
                                                                    <img src={DuplicateIcon} alt='Duplicateicon'/>
                                                                    Duplicate
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => handleViewCertificates(template.id)}>
                                                                    <img src={ViewIcon} alt='View icon'/>
                                                                    View Certificates
                                                                </a>
                                                            </li>
                                                            {/*<li>
                                                            <a
                                                                onClick={() => handlePublish(template.id)}
                                                                disabled={template.status === 'published'}
                                                            >
                                                                <img src={PublishIcon} alt='Publish icon' />
                                                                Publish
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                onClick={() => handleUnpublish(template.id)}
                                                                disabled={template.status === 'unpublished'}
                                                            >
                                                                <img src={UnPublishIcon} alt='UnPublish icon' />
                                                                Unpublish
                                                            </a>
                                                        </li>*/}
                                                            <li>
                                                                <a aria-disabled={template.totalAwarded > 0}
                                                                   onClick={() => handleDelete(template)}
                                                                   style={{
                                                                       color: template.totalAwarded > 0 ? '#98A2B3' : '#000000'
                                                                   }}
                                                                >
                                                                    {template.totalAwarded > 0 ? (
                                                                        <img src={DisableDelete} alt='Edit icon'/>
                                                                    ) : (
                                                                        <img src={DeleteIcon} alt='Edit icon'/>
                                                                    )}
                                                                    Delete
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </ActionButton>
                                        )}
                                    />
                                    {/* {template.name} - {template.status}
                                <div>Created At: {new Date(template.createdAt).toLocaleString()}</div>
                                <div>Updated At: {new Date(template.updatedAt).toLocaleString()}</div>
                                 */}
                                </li>
                            ))}
                        </ul>
                    </TemplateList>
                ) : (
                    <NotFound
                        message={'Looks like you have no template  yet'}
                        icon={DefaultIcon}
                    />
                )}
            </Container>
            {/* Revoke certificate Modal */}
            <ConfirmationModal
                isOpen={showDuplicateModal}
                closeModal={() => {
                    setShowDuplicateModal(false);
                }}
            >
                <div>
                    <ModalContent>
                        <EmbedHeader>
                            <img src={RevokeIcon}/>
                            <span>Duplicate Certificate</span>
                        </EmbedHeader>
                        <Form>
                            <Input
                                value={name}
                                type='text'
                                placeholder='Enter name'
                                label={'Certificate Name'}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </Form>

                        <CopyButton>
                            <Link onClick={() => {
                                setShowDuplicateModal(false);

                            }}>
                                Cancel
                            </Link>
                            <button
                                onClick={() => {
                                    setShowDuplicateModal(false);
                                    duplicateTemplate(selectedTemplate, name);
                                }}
                            >
                                Yes
                            </button>
                        </CopyButton>
                    </ModalContent>
                </div>
            </ConfirmationModal>
        </AppLayout>
    );
};

export default ListTemplates;
