import {getQR} from "../pages/templates/design/components/QRSection";
import {getTableURL} from "../pages/templates/design/Utils";
import Api, {BASE_URL} from "./Api";

export const bringToFront = (arr, index) => {
    //Move index to the end of the array
    arr.push(arr.splice(index, 1)[0]);
    return arr;
}

export const getAspectRatio = (pageSize) => {
    const aspectRations = {
        'a4': 1.414,
        'letter': 1.29,
    }
    return aspectRations[pageSize];
}

const extractAttributesWithDoubleCurlies = (str) => {
    const regex = /{{(.*?)}}/g;
    const matches = [];
    let match;
    while (match = regex.exec(str)) {
        matches.push(match[1]);
    }
    return matches;
}


export const getAttributesFromCanvas = (data, skip = []) => {
    const allSkip = [...skip, 'quantumledgerid'];
    const attributes = [];
    data?.pages?.forEach(page => {
        page?.children?.forEach(child => {
            if (child?.type === 'text') {
                attributes.push(...extractAttributesWithDoubleCurlies(child?.text))
            }
            if (child?.type === 'image' && child?.custom?.type === 'dynamic') {
                attributes.push(child?.custom?.name)
            }
        });
    });
    const skp = allSkip.map(attribute => attribute.toLowerCase());
    return [...new Set(attributes.filter(attribute => !skp.includes(attribute.toLowerCase())))];
}

export const toProperCase = function (str) {
    if (!str) {
        return '';
    }
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};


export const enrichWithAttributes = async (data, attributes, quantumLedgerId, viewMode) => {
    const enrichedData = JSON.parse(JSON.stringify(data));
    const attributesInCanvas = getAttributesFromCanvas(data);
    console.log({attributes, attributesInCanvas, enrichedData})
    Object.keys(attributes).forEach(key => {
        attributes[key.toLowerCase()] = attributes[key];
    })
    attributesInCanvas.push('quantumledgerid');
    attributes['quantumledgerid'] = quantumLedgerId;
    let index = 0;
    for (const page of enrichedData.pages) {
        for (const child of page.children) {
            if (child.type === 'svg' && child.name === 'qr') {
                child.src = await getQR(BASE_URL+'public/certificates/' + quantumLedgerId);
            }
            if (child.type === 'text') {
                attributesInCanvas.forEach(attribute => {
                    //replace both lowercase and uppercase
                    const regex = new RegExp(`{{${attribute}}}`, 'gi');
                    console.log({regex, attribute, text: child.text})
                    child.text = child.text.replace(regex, attributes[attribute.toLowerCase()] || '');
                })
            }
            if (child.name === 'table' && attributes['Tables'] && attributes['Tables'].length > 0) {
                const dataUrl = await getTableURL(attributes['Tables'][index], child.width, child.custom);
                console.log({dataUrl})
                child.src = dataUrl;
            }
            if (child.type === 'image' && child.custom?.type === 'dynamic') {
                const imageUrl = attributes[child.custom.name.toLowerCase()];
                console.log({imageUrl})
                child.src = imageUrl;
            }
        }
        index++;
    }

    console.log({viewMode})

    if (viewMode === 'print') {
        enrichedData.pages.forEach(page => {
            page.children = page.children.filter(child => !child.custom?.hideFromPrintView);
        })
    }

    return enrichedData;
}

function formatDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12; // Convert 24h to 12h format
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
}

export const serverToLocalDateTime = (serverDateTime) => {
    if (!serverDateTime) return '-';
    const date = new Date(serverDateTime);
    return formatDate(date);
}

export const scaleCanvas = (data, scale) => {
    console.log({data});
    const scaledData = JSON.parse(JSON.stringify(data));
    scaledData.width = scaledData.width * scale;
    scaledData.height = scaledData.height * scale;
    scaledData.pages.forEach(page => {
        if (!isNaN(page.width)) {
            page.width = page.width * scale;
        }
        if (!isNaN(page.height)) {
            page.height = page.height * scale;
        }
        page.children.forEach(child => {
            child.x = child.x * scale;
            child.y = child.y * scale;
            if (!isNaN(child.width)) {
                child.width = child.width * scale;
            }
            if (!isNaN(child.height)) {
                child.height = child.height * scale;
            }
            if (!isNaN(child.fontSize)) {
                child.fontSize = child.fontSize * scale;
            }
        });
    });
    console.log({scaledData})
    return scaledData;
}
