import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Api from "../../../helpers/Api";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import AuthLayout from '../../../components/layout/AuthLayout';
import styled from "styled-components";
import AppTheme from '../../../themes/AppTheme';

// Assets
import MailIcon from '../../../images/mail-icon.svg';
import PasswordIcon from '../../../images/menu-icons/password-inactive.svg';
import BackArrow from '../../../images/back-arrow.svg';
import Otp from '../../../components/form/Otp';
import ReCAPTCHA from "react-google-recaptcha";

export const Container = styled.div`
    width:65%;
    float:left;
`;

export const StaticContent = styled.div`
    margin-top:16px;
    >span{
        color:${AppTheme.header.colors.lightGreyColor};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-align:left;
        display:block;
    }
`;

export const ResendOtpLink = styled.div`
    width:100%;
    display:flex;
    align-items:flex-start;
    margin-top:12px;
    >button{
        background:transparent;
        border:none;
        color: ${AppTheme.header.colors.blueColor};
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 8.778px; /* 73.153% */
        letter-spacing: -0.12px;
    }
    >span{
        background:transparent;
        border:none;
        color: ${AppTheme.header.colors.blueColor};
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 8.778px; /* 73.153% */
        letter-spacing: -0.12px;
    }
`;

export const BackButton = styled.div`
    margin-bottom:40px;
    >button{
        border:none;
        background:transparent;
        color:${AppTheme.header.colors.blackColor};
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 184.615% */
        letter-spacing: -0.13px;
        display:flex;
        >img{
            margin-right:10px;
        }
    }
    >span{
        border:none;
        background:transparent;
        color:${AppTheme.header.colors.blackColor};
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 184.615% */
        letter-spacing: -0.13px;
        display:flex;
        >img{
            margin-right:10px;
        }
    }
`;

export const ErrorContainer = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  letter-spacing: -0.12px;
  color: ${AppTheme.header.colors.errorTextColor};
  margin-top: 8px;
  text-align: left;
  display: block;
`;

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resending, setResending] = useState(false)
    const [error, setError] = useState('');
    const [resendTimer, setResendTimer] = useState(30);
    const [captchaToken, setCaptchaToken] = useState(false);
    const [captchaError, setCaptchaError] = useState('');

    const navigate = useNavigate();
    const captchaRef = useRef(null);

    useEffect(() => {
        let intervalId;
        if (isOtpSent && resendTimer > 0) {
            intervalId = setInterval(() => {
                setResendTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (resendTimer === 0) {
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [isOtpSent, resendTimer]);

    const resetCatcha = () => {
        captchaRef.current.reset();
        setCaptchaToken(null);
    }
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        if (!captchaToken) {
            setCaptchaError('Please solve the captcha');
            return;
        }
        setError(''); // Reset any previous errors
        setCaptchaError(''); // Reset captcha error
        setLoading(true);
        try {
            await Api.login(email, password, captchaToken); // Assuming this function triggers the OTP sending
            setIsOtpSent(true);
            setError(''); // Reset any previous errors
        } catch (err) {
            setError('Invalid credentials. Please try again.');
        } finally {
            setLoading(false);
            resetCatcha();
        }
    };

    const handleOtpSubmit = async (e) => {
        if(e) {
            e.preventDefault();
        }
        setLoading(true);
        try {
            const data = await Api.getAccessToken(email,password, otp);
            //Store access token in local storage
            localStorage.setItem('accessToken', data.token);
            setError(''); // Reset any previous errors
            navigate('/dashboard'); // Redirect to the dashboard
        } catch (err) {
            setError('Failed to login. Please check the OTP and try again.');
        } finally {
            setLoading(false);
        }
    };
    const handleResendOtp = async () => {
        if (resendTimer > 0) return;
        setError(''); // Reset any previous errors
        setResending(true);
        try {
            await Api.login(email, password); // Assuming this function triggers the OTP sending
            setResendTimer(30); 
            // Reset the timer
        } catch (err) {
            setError('Failed to resend OTP. Please try again.');
        } finally {
            setResending(false);
        }
    };
    const maskedEmail = email ? `${email.slice(0, 2)}******${email.slice(-2)}` : '';

    return (
        <AuthLayout>
            <Container>
                {!isOtpSent ? (
                    <form onSubmit={handleEmailSubmit}>
                        <Input
                            heading="Log In"
                            label={'Email'}
                            Icon={MailIcon}
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                            placeholder="Your email"
                            error={error}
                        />
                        <Input
                            label={'Password'}
                            Icon={PasswordIcon}
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            placeholder="*****"
                            error={error}
                        />

                        <ReCAPTCHA ref={captchaRef}
                            sitekey="6LeMsLQpAAAAAL9Eu25lwMTPfQ7IDrbVl_g4YYwb"
                            onChange={(token)=>{
                                setCaptchaToken(token);
                            }}
                        />
                        {captchaError && <ErrorContainer>{captchaError}</ErrorContainer>}
                        <Button disabled={loading} loading={loading} label="Continue" onClick={handleEmailSubmit}/>
                    </form>
                ) : (
                    <form onSubmit={handleOtpSubmit}>
                        <BackButton>
                            <button onClick={()=>{setIsOtpSent(false)}}>
                                <img src={BackArrow} alt-='Arrow' />
                                Back
                            </button>
                        </BackButton>
                        <Otp
                            heading="Log In"
                            label={isOtpSent ? `Please enter the OTP sent to ${maskedEmail}` : 'OTP'}
                            error={error}
                            value={otp}
                            onChange={setOtp}
                            name={otp}
                        />
                        <ResendOtpLink>
                            {
                                !resending && resendTimer > 0 && (
                                    <span>Resend OTP in {resendTimer}s</span>
                                )
                            }
                            <button onClick={handleResendOtp} type={'button'}>
                                {resending ? 'Resending OTP...' : resendTimer === 0 ? `Resend OTP` : ''}
                            </button>
                        </ResendOtpLink>
                        <Button disabled={loading} loading={loading} type='submit' label="Verify OTP" />
                    </form>
                )}
                <StaticContent>
                    <span>This site is protected by reCAPTCHA and the Google Privacy Policy.</span>
                </StaticContent>
            </Container>
        </AuthLayout>
    );
};

export default LoginPage;
