import React, {useState} from "react";

import {Outlet} from "react-router-dom";
import Sidebar from "../pages/components/sidebar/Sidebar";
import Header from "../components/header/Header";
import {Layout, ScreenBody, ScreensBodyContainer} from "../components/layout/AppLayout";
import AppContext from "../context/AppContext";

const AppRoutes = () => {

    const [user, setUser] = useState(null);

    return <AppContext.Provider value={{user, setUser}}>
        <Layout>
            <Sidebar/>
            <ScreensBodyContainer>
                <Header/>
                <ScreenBody>
                    <Outlet/>
                </ScreenBody>
            </ScreensBodyContainer>
        </Layout>
    </AppContext.Provider>
}

export default AppRoutes;
