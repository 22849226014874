import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom'; // Import useParams
import Api from "../../../helpers/Api";
import Button from "../../../components/form/Button";
import ListDepartmentUsers from "../../departments/users/ListDepartmentUsers";
import AppLayout from '../../../components/layout/AppLayout';
import SubHeader from '../../../components/subheader/SubHeader';
import styled from "styled-components";
import AppTheme from '../../../themes/AppTheme';
import AddIcon from '../../../images/add-icon.svg';

export const HeaderContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    flex-direction:row;
    padding:20px 15px;
    
`;

export const ColLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    >h2{
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        color:${AppTheme.header.colors.blackColor};
        margin-bottom:6px;
    }
    >span{
        color:${AppTheme.header.colors.blackColor};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
`;

export const InfoCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    >.col{
        display: flex;
        justify-content:flex-start;
        align-items: center;
        margin-bottom:10px;
        >span{
            color:${AppTheme.header.colors.blackColor};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        >strong{
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            color:${AppTheme.header.colors.blackColor};
            padding-left:6px;
        }
    }
`;

export const ColRight = styled.div`
    >div{
        margin-top:0px;
    }
`;

const ViewUser = () => {
    const {id} = useParams(); // Get the :id parameter from the route
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [departmentUsers, setDepartmentUsers] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        // If id is present, load the existing user data
        if (id) {
            setLoading(true);
            Api.getUser(id).then(response => {
                setUser(response);
                setLoading(false);
            }).catch(err => {
                setError('Failed to load user. Please try again.');
                setLoading(false);
            });
        }
    }, [id]);

    if (!user) {
        return <div>Loading...</div>
    }

    return (
        <AppLayout>
            <HeaderContainer>
                <ColLeft>
                    <InfoCol>
                        <div className="col">
                            <span>Name: </span>
                            <strong>{user.name}</strong>
                        </div>
                        <div className="col" style={{marginBottom:0}}>
                            <span>Email: </span>
                            <strong>{user.email}</strong>
                        </div>
                    </InfoCol>
                </ColLeft>
                <ColRight>
                    {
                        user.roles[0]==="ROLE_USER"?
                        <Button href={`/users/${user.id}/departments/create`} label={'Add Department'} type='link' icon={AddIcon} />
                        :
                        "You can’t assign departments to admin users as they are global admins"
                    }
                </ColRight>
                
            </HeaderContainer>
            <ListDepartmentUsers userId={user.id} />
            {error && <div style={{color: 'red'}}>{error}</div>}
        </AppLayout>
    );
};

export default ViewUser;
