import React from "react";
import styled from "styled-components";

// Assets
import Logo from '../../../../images/chitkara-logo.svg';
import AppTheme from "../../../../themes/AppTheme";

export const HeaderContainer = styled.div`
    width:100%;
    background: ${AppTheme.header.colors.whiteColor};
    height:58px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0px 60px;
    @media screen and (max-width: 991px) {
        display:none;
    }
`;

export const LogoContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-start;
    >img{
        width:110px;
    }
`;

const Header = () => {

    return (
        <HeaderContainer>
            <LogoContainer>
                <img src={Logo} alt='Logo' />
            </LogoContainer>
        </HeaderContainer>
    );
}

export default Header;
