import React from 'react';
import {SectionTab} from "polotno/side-panel";
import {observer} from "mobx-react-lite";
import FaShapes from '@meronex/icons/fa/FaShapes';
import MediaList from "./MediaList";

const ImagesSection = {
    name: 'images',
    Tab: (props) => (
        <SectionTab name="Images" {...props}>
            <FaShapes icon="list"/>
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({store}) => {
        return (
            <MediaList type={'image'} uploadEnabled={true} onClick={media=>{
                        store.activePage?.addElement({
                            type: 'image',
                            src: media.url,
                            width: parseFloat(media.width),
                            height: parseFloat(media.height),
                            x: 100,
                            y: 100,
                            custom: media.custom || {}
                        });
            }}/>
        );
    }),
};

export default ImagesSection;
