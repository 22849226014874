import React, {useEffect, useState} from 'react';
import Api from "../../helpers/Api";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import AppTheme from "../../themes/AppTheme";

// Assets
import CloseIcon from '../../images/modal-close.svg';
import ConfirmationModal from '../components/modal/Modal';
import Input from '../../components/form/Input';
import SuccessIcon from '../../images/success-icon.svg';
import jsPDF from "jspdf";
import * as PropTypes from "prop-types";
import ReadOnlyCanvasV2 from "../../components/canvas/ReadOnlyCanvasV2";
import {serverToLocalDateTime} from "../../helpers/Utils";
import {unstable_setTextOverflow} from "polotno/config";

export const Container = styled.div`
  width: 100%;
  background: ${AppTheme.header.colors.backgroundLight};
  float: left;
  height: 100%;
`;

export const BodyContainer = styled.div`
  width: 100%;
  float: left;
  height: 100%;
`;

export const ColLeft = styled.div`
  width: 50%;
  float: left;
  background: ${AppTheme.header.colors.backgroundLight};
  padding: 32px 60px;
  display: flex;
  align-items: flex-start;
  jusity-content: flex-start;

`;

export const ColRight = styled.div`
  width: 50%;
  float: left;
  background: ${AppTheme.header.colors.whiteColor};
  padding: 32px 50px;

`;

export const ListOfCertificate = styled.div`
  width: 130px;
  background: ${AppTheme.header.colors.backgroundLight};
  max-height: calc(90vh - 20px);
  overflow-y: auto;

  > ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;

    > li {
      padding: 2px;
      list-style: none;

      > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        margin-bottom: 10px;
        text-decoration: none;

        &.selected {
          border: 5px solid ${AppTheme.header.colors.blueColor};
        }

        > img {
          max-height: 120px;
          height: 100%;
          margin-bottom: 2px;
        }

        > span {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: ${AppTheme.header.colors.blackColor};
        }
      }

    }
  }
`;

export const CertificateBox = styled.div`
  width: calc(100% - 130px);
  display: flex;
  flex-direction: column;

  > .certificate-col {
    > div {
      padding-bottom: 0px;
    }
  }

  > ul.share-options {
    margin: 0;
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    border-bottom: 1px solid rgba(204, 204, 204, 0.80);

    > li {
      margin: 0;
      padding: 0 5px;
    }
  }

  > .credential-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 25px;

    > .info-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > .icon-col {
        margin-right: 15px;

        > img {

        }
      }

      > .user-info {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        > span {
          color: ${AppTheme.header.colors.blackColor};
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }

        > a {
          color: ${AppTheme.header.colors.blueColor};
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          text-decoration: none;
        }
      }
    }
  }
`;

export const MetaInfo = styled.div`
  > h3 {
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${AppTheme.header.colors.blackColor};
    text-align: left;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: wrap;

    > li {
      padding-right: 10px;
      list-style: none;

      > a {
        display: flex;
        align-items: center;
        justify-content:: center;
        height: 40px;
        padding: 0 16px;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        border: 1px solid #D5D5D5;
        background: #FCFCFC;
        color: ${AppTheme.header.colors.blackColor};

        > img {
          margin-right: 6px;
        }
      }
    }
  }
`;

export const IssuedByBOX = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid rgba(204, 204, 204, 0.80);
  padding: 0 18px;

  > .issue-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 0;
    border-bottom: 1px solid rgba(204, 204, 204, 0.80);

    > img {
      margin-right: 8px;
    }

    > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.5px;
      color: ${AppTheme.header.colors.blackColor};
    }
  }

  > .combined-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > .issue-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 10px 8px 0px;
      border-right: 1px solid rgba(204, 204, 204, 0.80);

      > img {
        margin-right: 8px;
      }

      > span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.5px;
        color: ${AppTheme.header.colors.blackColor};
      }

      &.last-col {
        border-right: none;
        padding-left: 20px;
      }
    }
  }
`;

export const VerifyButton = styled.div`
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.80);

  > a {
    background: ${AppTheme.header.colors.blueColor};
    color: ${AppTheme.header.colors.whiteColor} !important;;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    > img {
      margin-right: 20px;
    }
  }
`;

export const BarcodeBox = styled.div`
  width: 100%;
  margin-top: 20px;
  border: 1px solid rgba(204, 204, 204, 0.80);
  background: linear-gradient(90deg, #CDFFE0 0%, #55D887 100%);
  padding-bottom: 23px;
  margin-bottom: 20px;

  > .secure-box {
    margin-top: 16px;
    background: ${AppTheme.header.colors.whiteColor};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;

    > img {
      margin-right: 10px;
    }

    > span {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      color: ${AppTheme.header.colors.blackColor};
    }
  }

  > .barcode {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > span {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      color: ${AppTheme.header.colors.blackColor};
      margin-top: 13px;
      display: block;
    }
  }
`;

export const DescriptionBox = styled.div`
  width: 100%;
  border-top: 1px solid rgba(204, 204, 204, 0.80);
  margin: 20px 0;
  padding-top: 15px;

  > .icon-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > img {
      margin-right: 10px;
    }

    > span {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: ${AppTheme.header.colors.blackColor};

    }
  }

  > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${AppTheme.header.colors.blackColor};
    text-align: left;
  }
`;

export const VerifyModal = styled.div`
  > .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h4 {
      margin-bottom: 0px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 31.065px;
      color: ${AppTheme.header.colors.blackColor};
    }

    > button {
      background: transparent;
      border: none;
      height: auto;
      padding: 0;
    }
  }

  > .modal-body {
    > .credential-info {
      border-bottom: 1.109px solid #EFEFEF;
      padding: 15px 0px;

      > .col {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 5px;

        > span {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22.189px;
          color: ${AppTheme.header.colors.blackColor};
        }

        > h5 {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22.189px;
          color: ${AppTheme.header.colors.blackColor};
          margin: 0 0px 0px 10px;
        }
      }
    }

    > .form {
      padding: 15px 0;

      > div {
        > label {
          margin-bottom: 6px;
        }

        > div {
          background: transparent;
          padding: 0;

          > input {
            border-radius: 8.876px;
            border: 1.109px solid ${AppTheme.header.colors.inputBorder};
            background: ${AppTheme.header.colors.whiteColor};
            box-shadow: 0px 1.109px 2.219px 0px rgba(16, 24, 40, 0.05);
            padding: 0 10px;
          }
        }
      }

      > span {
        color: ${AppTheme.header.colors.lightGreyColor};
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.189px;
      }
    }

    > .successfull-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > img {
        margin-top: -34px;
      }

      > h4 {
        color: ${AppTheme.header.colors.greenColor};
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 8px;
      }

      > span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: ${AppTheme.header.colors.lightGreyColor};
      }

      > button {
        margin-bottom: 0px;
        height: 48px;
        padding: 0 15px;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        width: 100%;
        background: ${AppTheme.header.colors.greenColor};
        border: none;
        color: ${AppTheme.header.colors.whiteColor};
        margin-top: 20px;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  > a {
    text-decoration: none;
    border-radius: 8px;
    border: 1px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    width: 50%;
    color: ${AppTheme.header.colors.darkGrey};
  }

  > button {
    margin-bottom: 0px;
    height: 48px;
    padding: 0 15px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    width: 50%;
    background: ${AppTheme.header.colors.blueColor};
    border: none;
    color: ${AppTheme.header.colors.whiteColor};
  }

`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  max-width: 600px;
`;

export const EmbedHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};
  padding-bottom: 10px;

  > img {
    margin-bottom: 12px;
  }

  > span {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: ${AppTheme.header.colors.blackColor};
  }
`;

export const DescriptionCol = styled.div`
  padding-bottom: 20px;

  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: ${AppTheme.header.colors.lightGreyColor};
  }
`;

export const CopyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > a {
    text-decoration: none;
    border-radius: 8px;
    border: 1px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 44px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blackColor};
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${AppTheme.header.colors.whiteColor};
    height: 44px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    background: ${AppTheme.header.colors.blueColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: ${AppTheme.header.colors.blueColor};
    border: 1px solid ${AppTheme.header.colors.blueColor};
    width: 50%;

    > img {
      margin-right: 6px;
    }
  }
`;
const CodeTextarea = styled.textarea`
  width: 100%;
  height: 126px;
  margin-bottom: 10px;
  font-family: monospace;
  border-radius: 12px;
  background: ${AppTheme.header.colors.blackColor};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${AppTheme.header.colors.whiteColor};
  padding: 0 10px;
`;

function VerifyModalComponent({closeModal, certificate, openVerifyModal}) {
    const [emailEntered, setEmailEntered] = useState(false);
    const verify = (e) => {
        e.preventDefault();
        setVerifying(true);
        Api.sendVerificationOtp(certificate.quantumLedgerId, verificationPayload.email).then(response => {
            setEmailEntered(true);
        }).catch(err => {
            setVerificationError(err.response.data.message);
        }).finally(() => {
            setVerifying(false);
        })
    }
    const verifyUsingOtp = (e) => {
        e.preventDefault();
        setVerifying(true);
        Api.verifyCertificate(certificate.quantumLedgerId, verificationPayload.email, verificationPayload.otp).then(response => {
            openVerifyModal();
        }).catch(err => {
            setVerificationError(err.response.data.message);
        }).finally(() => {
            setVerifying(false);
        })
    }

    const [verificationPayload, setVerificationPayload] = useState({});
    const [verifying, setVerifying] = useState(false);
    const [verificationError, setVerificationError] = useState(null);

    return <VerifyModal>
        <div className='modal-header'>
            <h4>Verify Credential</h4>
            <button onClick={closeModal}><img src={CloseIcon}/></button>
        </div>
        <div className='modal-body'>
            <div className='credential-info'>
                <div className='col'>
                    <span>Credential Issued to :</span>
                    <h5>{certificate.awardeeName}</h5>
                </div>
                <div className='col'>
                    <span>Date of issue :</span>
                    <h5>{serverToLocalDateTime(certificate.createdAt)}</h5>
                </div>
            </div>
            {emailEntered && <div className='form'>
                <Input
                    onChange={(e) => {
                        setVerificationPayload({...verificationPayload, [e.target.name]: e.target.value});
                    }}
                    value={verificationPayload.otp}
                    name='otp'
                    type='text'
                    error={verificationError}
                    placeholder='Example: 879090'
                    label={'Enter OTP'}
                />
                <span>Please enter the otp sent to your email '{verificationPayload.email}'</span>
            </div>}
            {!emailEntered && <div className='form'>
                <Input
                    onChange={(e) => {
                        setVerificationPayload({...verificationPayload, [e.target.name]: e.target.value});
                    }}
                    value={verificationPayload.email}
                    name='email'
                    type='text'
                    error={verificationError}
                    placeholder='Example: abc@gmail.com'
                    label={'Enter email'}
                />
                <span>Please enter your Email ID to verify this credential</span>
            </div>}

        </div>
        <div className='modal-footer'>
            <ButtonContainer>
                <a onClick={closeModal}>
                    Cancel
                </a>
                <button disabled={verifying} onClick={emailEntered ? verifyUsingOtp : verify}>
                    {verifying && emailEntered && 'Verifying...'}
                    {verifying && !emailEntered && 'Sending OTP...'}
                    {!verifying && emailEntered && 'Verify'}
                    {!verifying && !emailEntered && 'Send OTP'}
                </button>
            </ButtonContainer>
        </div>
    </VerifyModal>
}

VerifyModalComponent.propTypes = {onClose: PropTypes.func};
const PublicCertificateViewer = () => {
    const {id} = useParams(); // Get the :id parameter from the route
    const [certificate, setCertificate] = useState(null); // Updated state for template
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [successModal, setSuccessMOdal] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [selectedPageIndex, setSelectedPageIndex] = useState(0);
    const [verifying, setVerifying] = useState(false);
    const [verificationError, setVerificationError] = useState(null);
    const [embedModal, setEmbedModal] = useState(false);
    const [verificationPayload, setVerificationPayload] = useState({email: '', 'otp': ''})
    const [stageRef, setStageRef] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const code = `<iframe width="560" height="315" src="https://www.youtube.com/embed/Kuts4eFyIgM?si=LEYTVyZHxPIiUlrL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;


    const [checked, setChecked] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const copyCodeToClipboard = () => {
        // Copy code to clipboard
        navigator.clipboard.writeText(code);

        // Update button text and style
        setIsCopied(true);

        // Reset button text and style after 2 seconds
        setTimeout(() => {
            setIsCopied(false);
        }, 6000);
    };

    const openVerifyModal = () => {
        setSuccessMOdal(true);
        setIsOpen(false); // Close the previous modal
    };

    const closeVerifyModal = () => setSuccessMOdal(false);
    const handleVerifySuccess = () => {
        setIsVerified(true);
        closeVerifyModal();
    };


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.getCertificate(id);
                setCertificate(response);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        }

        fetchData();
        unstable_setTextOverflow('change-font-size');
    }, [id]);

    if (loading) {
        return <div>Loading..</div>
    }

    const canvasData = certificate.templateData.canvasData;

    const download = (data, filename) => {
        const pdf = new jsPDF();
        pdf.addImage(
            stageRef.current.toDataURL(),
            0,
            0,
            500,
            500
        );
        pdf.save(`${filename}.pdf`);
    }


    const width = 900;
    const height = window.innerHeight - 200;

    return (
        <Container>

            <BodyContainer>
                <ColLeft>
                    {/* OrignalCertificate */}

                    <CertificateBox>
                    <div>
                        <input type="checkbox" id="toggle" className="toggle" onChange={() => {
                            console.log(!checked);
                            setChecked(!checked);
                        }} checked={checked}/> <label htmlFor="toggle" className="switch">Print Mode</label>
                    </div>
                        <div className='certificate-col'>
                            <ReadOnlyCanvasV2
                                viewMode={checked ? 'print' : 'view'}
                                attributes={certificate.data}
                                data={canvasData}
                                loading={loading}
                                onStageRef={ref => {
                                    setStageRef(ref)
                                }}
                                widthParent={width}
                                heightParent={height}
                                widthChild={width}
                                heightChild={height}
                            />
                        </div>

                    </CertificateBox>
                </ColLeft>
            </BodyContainer>

            {/* Verify Credemtial Modal */}
            <ConfirmationModal
                isOpen={isOpen}
                closeModal={closeModal}
            >
                <VerifyModalComponent closeModal={closeModal} certificate={certificate}
                                      openVerifyModal={openVerifyModal}/>
            </ConfirmationModal>

            {/* Verify Successfull Modal */}
            <ConfirmationModal
                isOpen={successModal}
                closeModal={closeVerifyModal}
            >
                <VerifyModal>
                    <div className='modal-header' style={{justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                        <button onClick={closeVerifyModal}><img src={CloseIcon}/></button>
                    </div>
                    <div className='modal-body'>
                        <div className='successfull-info'>
                            <img src={SuccessIcon}/>
                            <h4>Verified Successfully!</h4>
                            <span>Congratulations! Your credentials has been successfully verified.</span>
                            <button onClick={handleVerifySuccess}>Okay</button>
                        </div>
                    </div>
                </VerifyModal>
            </ConfirmationModal>


        </Container>
    );
};

export default PublicCertificateViewer;
