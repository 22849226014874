import React from 'react'
import styled from "styled-components";
import AppTheme from '../../../themes/AppTheme';

export const Container = styled.div`
    width:100%;
    height:calc(100vh - 280px);
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    >img{
        margin-bottom:40px;
    }
    >span{
        color: ${AppTheme.header.colors.blackColor};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
    }
`;

const NotFound = ({icon,message}) => {
  return (
    <Container>
        <img src={icon} />
        <span>{message}</span>
    </Container>
  )
}

export default NotFound;