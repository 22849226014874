import React, {useState} from 'react';
import {SectionTab} from "polotno/side-panel";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import AppTheme from "../../../../themes/AppTheme";
import Table from '../../../../images/table.png';
import TableIcon from '../../../../images/table-icon.png';
import QRCode from 'qrcode';
import * as svg from 'polotno/utils/svg';
import {DescriptionCol, EmbedHeader, ModalContent} from "../../../certificates/ListCertificates";
import TabIcon from "../../../../images/table-icon.svg";
import ConfirmationModal from "../../../components/modal/Modal";
import * as PropTypes from "prop-types";
import {getSampleTableUrl} from "../Utils";
import Input from "../../../../components/form/Input";


export const AttributesContainer = styled.div`
  display: block;
  margin-top: 0px; /* Adjust spacing from the list */
  padding: 10px;
  width: 100%;
  float: left;
  height: 100%;

  > h2 {
    color: ${AppTheme.header.colors.whiteColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 150% */
    letter-spacing: 1.92px;
    text-align: left;
    margin-bottom: 0px;
  }

  > h3 {
    color: ${AppTheme.header.colors.whiteColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 1.92px;
    text-align: left;
    margin-bottom: 0px;
  }

  .attributes {
    text-align: left;

    > .upload-new-container {
      margin-top: 20px;
      float: left;
      width: 100%;

      > label {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color: ${AppTheme.header.colors.blackColor};
        margin-bottom: 10px;
      }
    }

    > label {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      color: ${AppTheme.header.colors.blackColor};
      margin-bottom: 10px;
    }
  }
`;

export const ShapesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  > .col {
    cursor: pointer;
  }
`;

export const TableContainer = styled.div`
  width:100%;
  float:left;
  >.option-col{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    >.option-box-content{
        margin-bottom:15px;
        >label{
            color:#344054;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
        >input{
            border-radius: 2px;
            border: 1px solid ${AppTheme.header.colors.inputBorder};
            background: transparent;
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            width:130px;
            height:40px;
            padding:0 14px;
            font-size:14px;
            font-weoight:600;
        }
    }
    >.option-column{
        >label{
            color:${AppTheme.header.colors.blackColor};
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
        >div{
            >div{
                >div{
                    >div{
                        >input{
                            height:42px !important;
                            border-radius:8px !important;
                        }
                    }
                }
            }
        }
    }
  }
`;

export const ButtonAddContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  padding-left:0px;
  float:left;
  width:100%;
  >button{
    background:${AppTheme.header.colors.blueColor};
    border:1px solid ${AppTheme.header.colors.blueColor};
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    color:${AppTheme.header.colors.whiteColor};
    border-radius:6px;
  }
 `;

export async function getQR(text) {
    return new Promise((resolve) => {
        QRCode.toString(
            text || 'no-data',
            {
                type: 'svg',
                color: {
                    dark: '#00F', // Blue dots
                    light: '#0000', // Transparent background
                },
            },
            (err, string) => {
                resolve(svg.svgToURL(string));
            }
        );
    });
}

function TableProperties({onChange, value}) {
    //Inputs:
    return <TableContainer>
        <div className='option-col'>
            <div className='option-box-content'>
                <label>Max Rows Left Side</label>
                <input type='number' value={value.maxRowsLeftSide}
                    onChange={e => onChange({...value, maxRowsLeftSide: e.target.value})}/>
            </div>
            <div className='option-box-content'>
                <label>Max Rows Right Side</label>
                <input type='number' value={value.maxRowsRightSide}
                    onChange={e => onChange({...value, maxRowsRightSide: e.target.value})}/>
            </div>
            <div className='option-box-content'>
                <label>Font Size</label>
                <input type='number' value={value.fontSize}
                    onChange={e => onChange({...value, fontSize: e.target.value})}/>
            </div>
        </div>
        <div className='option-column'>
            <label>Columns Headings</label>
            <Input type="horizontalinput" value={value.columns} multi={true} onChange={e => onChange({...value, columns: e.target.value})}/>
        </div>
    </TableContainer>
}

TableProperties.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.shape({
        maxRowsLeftSide: PropTypes.number,
        columns: PropTypes.arrayOf(PropTypes.any),
        fontSize: PropTypes.number,
        maxRowsRightSide: PropTypes.number
    })
};
const TableSection = {
    name: 'table',
    Tab: (props) => (
        <SectionTab name="Table" {...props}>
            <img src={TableIcon} style={{background: '#fff', width: 24}}/>
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({store}) => {

        const [showModal, setShowModal] = useState(false);
        const [properties, setProperties] = useState({
            maxRowsLeftSide: 10,
            maxRowsRightSide: 5,
            fontSize: 12,
            columns: ['Column 1', 'Column 2', 'Column 3']
        });

        const addTable = async () => {

            store.activePage.addElement({
                type: 'image',
                src: await getSampleTableUrl(properties),
                name: 'table',
                width: 300,
                height: 300,
                x: 100,
                y: 100,
                custom: {...properties}
            });
        }

        return (
            <AttributesContainer>
                <ConfirmationModal
                    isOpen={showModal}
                    closeModal={() => {
                        setShowModal(false)
                    }}
                >
                    <div>
                        <ModalContent>
                            <EmbedHeader>
                                <img src={TabIcon}/>
                                <span>Add new table</span>
                            </EmbedHeader>
                            <DescriptionCol>
                                <span>Please select table properties</span>
                            </DescriptionCol>
                            <TableProperties value={properties} onChange={setProperties}/>
                            <ButtonAddContainer>
                                <button
                                    onClick={() => {
                                        addTable();
                                        setShowModal(false);
                                    }}>
                                    Add Table
                                </button>
                            </ButtonAddContainer>
                        </ModalContent>
                    </div>
                </ConfirmationModal>
                <div className='attributes'>
                    <label>Table </label>
                    <ShapesContainer>
                        <div className='col' onClick={async () => {
                            setShowModal(true);
                        }}>
                            <img src={Table} alt='shape1'/>
                        </div>
                    </ShapesContainer>
                </div>

            </AttributesContainer>
        );
    }),
};

export default TableSection;
