import {toSvg} from 'html-to-image';
import JsBarcode from "jsbarcode";


function generateLeftRightTableHTMLString(columns, leftData, rightData, width, properties) {
    const header = columns
        .map((column) => `<td style="min-width: ${column.width}px">${column.label}</td>`)
        .join("");

    const leftRows = leftData
        .map(
            (row, index) =>
                `<tr>${row
                    .map((cell) => `<td style="font-size: ${properties.fontSize}px">${cell}</td>`)
                    .join("")}                 
                </tr>`
        )
        .join("");

    const rightRows = rightData.slice(0, Math.min(rightData.length, properties.maxRowsRightSide)).map(
        (row, index) =>
            `<tr>${row
                .map((cell) => `<td style="font-size: ${properties.fontSize}px">${cell}</td>`)
                .join("")}
            </tr>`
    ).join("");

    return `
    <table style="width: ${width}" border="0" cellpadding="0" cellspacing="0">
    <tr>
        <td style="width: 50%; padding-right: 20px; vertical-align: top">

    <table style="width: 100%;" border="1" cellpadding="5" cellspacing="0">
        <thead>
            <tr>${header}</tr>
        </thead>
        <tbody>
            ${leftRows}
        </tbody>
    </table>
    
        </td>
        <td style="width: 50%; padding-left: 20px; vertical-align: top">
       
    <table style="width: 100%;" border="1" cellpadding="5" cellspacing="0">
        <thead>
            <tr>${header}</tr>
        </thead>
        <tbody>
            ${rightRows}
        </tbody>
    </table>
    </td>
    </tr>
    </table>
    `;
}

function generateTableHTMLString(columns, data, width, properties) {
    const MAX_ROWS = properties.maxRowsLeftSide;
    if (data.length > MAX_ROWS) {
        const leftData = data.slice(0, MAX_ROWS);
        const rightData = data.slice(MAX_ROWS);
        return generateLeftRightTableHTMLString(columns, leftData, rightData, width, properties);
    }
    const header = columns
        .map((column) => `<td style="min-width: ${column.width}px">${column.label}</td>`)
        .join("");


    const rows = data
        .map(
            (row) =>
                `<tr>${row
                    .map((cell) => `<td style="font-size: ${properties.fontSize}px">${cell}</td>`)
                    .join("")}</tr>`
        )
        .join("");

    return `
    <table style="width: 100%" border="1" cellpadding="5" cellspacing="0">
        <thead>
            <tr>${header}</tr>
        </thead>
        <tbody>
            ${rows}
        </tbody>
    </table>
    `;
}

// create svg image for QR code for input text
export function getTableURL(tableData, width, properties) {
    //example [[{"Code":"C1001","Subject":"Mathematics","Grade":"A"}]]

    const columns = Object.keys(tableData[0]).map((key) => ({
        label: key,
        accessor: key,
        width: 20,
    }));

    const data = tableData.map((row) => Object.values(row));

    const htmlString = generateTableHTMLString(columns, data, width, properties);
    console.log({htmlString})

    const para = document.createElement("div");
    para.innerHTML = htmlString;
    return toSvg(para, {width: 500, height: 500});
}


// create svg image for QR code for input text
export function getSampleTableUrl(properties) {
    const columns = properties.columns;
    const totalRows = properties.maxRowsLeftSide + properties.maxRowsRightSide;
    const rows = [];

    for (let i = 0; i < totalRows; i++) {
        const row = {};
        columns.forEach((column) => {
            row[column] = `${column} ${i + 1}`;
        });
        rows.push(row);
    }

    console.log({rows})
    return getTableURL(rows, 300, properties);
}

export const textToBase64Barcode = (text)=>{
    var canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {format: "CODE39"});
    return canvas.toDataURL("image/png");
}
