import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom'; // Import useParams
import Api from "../../../../helpers/Api";
import Input from "../../../../components/form/Input";
import Button from "../../../../components/form/Button";
import ReadOnlyCanvas from "../../../../components/canvas/ReadOnlyCanvas";
import AppLayout from '../../../../components/layout/AppLayout';
import styled from "styled-components";
import AppTheme from '../../../../themes/AppTheme';
import ActiveIcon from '../../../../images/zap-active.svg';
import InactiveIcon from '../../../../images/user-inactive.svg';
import LoadingPage from "../../../loading/LoadingPage";
import {getAttributesFromCanvas, toProperCase} from "../../../../helpers/Utils";
import ReadOnlyCanvasV2 from "../../../../components/canvas/ReadOnlyCanvasV2";

export const Container = styled.div`
  width: 100%;
  padding: 0px;
  float: left;

  > form {
    background: ${AppTheme.header.colors.whiteColor};
    padding: 0px;
    width: 65%;
    float: left;
    margin: 0 20px 0 0;
    border-radius: 12px;
    border: 1px solid ${AppTheme.header.colors.tableBorder};
    /* Shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  }
`;

export const Heading = styled.div`
  width: 100%;
  padding: 15px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};

  > h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: ${AppTheme.header.colors.blackColor};
    margin-bottom: 0px;
  }

  > img {
    width: 20px;
    margin-right: 10px;
  }
`;

export const StepsCol = styled.div`
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 40px;
  background: ${AppTheme.header.colors.inputBackground};
  padding: 4px;
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const Col = styled.a`
  height: 40px;
  background: ${AppTheme.header.colors.inputBackground};
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
  position: relative;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  text-decoration: none;

  > img {
    margin-right: 8px;
  }

  > span {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.15px;
    color: ${AppTheme.header.colors.greyColor};
  }

  &.active {
    background: ${AppTheme.header.colors.blueColor};
    border-radius: 32px;
  }

  &.active {
    > span {
      color: ${AppTheme.header.colors.whiteColor};
    }
  }

`;

export const FieldsCol = styled.div`
  width: 100%;
  float: left;
  padding: 20px 24px;
`;

export const FormGroup = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};
   >div{
        margin-bottom:0px;
        >span{
            width: calc(100% - 202px);
            margin: 8px 0 0 auto;
        }
    }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  > a {
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.darkGrey};
  }

  > div {
    margin-top: 0px;

    > button {
      margin-bottom: 0px;
      height: 40px;
      padding: 0 15px;
      border-radius: 6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
`;

export const ViewContainer = styled.div`
  width: 30%;
  float: left;
  border-radius: 12px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  /* Shadow/sm */
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
`;

export const AwardViewContainer = styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:10px;
  
  >img{
    width:100%;
  }
`;


const AwardCertificate = () => {
    const {id} = useParams(); // Get the :id parameter from the route
    const navigate = useNavigate();
    const [awardeeName, setAwardeeName] = useState('');
    const [awardeeEmail, setAwardeeEmail] = useState('');
    const [awardeeRollNumber, setAwardeeRollNumber] = useState('');
    const [attributes, setAttributes] = useState({
        'Name': '',
        'Email': '',
        'RollNumber': '',
    });

    useEffect(() => {
        setAttributes(a=>{
            return {
                ...a,
                'Name': awardeeName,
                'Email': awardeeEmail,
                'RollNumber': awardeeRollNumber,
            }

        });
    }, [awardeeName, awardeeEmail, awardeeRollNumber]);

    const [template, setTemplate] = useState(null);

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState('');
    const [errors, setErrors] = useState({})
    useEffect(() => {

        setLoading(true);
        Api.getTemplate(id).then(t => {
            setTemplate(t);
            setLoading(false);
        }).catch(err => {
            setError('Failed to load template. Please try again.');
            setLoading(false);
        });

    }, [id]);



    const canvasData = template?.canvasData;
    const allAttributes = ['Name', 'Email', 'RollNumber'];
    allAttributes.push(...getAttributesFromCanvas(canvasData, ['Name', 'Email', 'RollNumber']));


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const attr = {...attributes};
        attr['Name'] = awardeeName;
        attr['Email'] = awardeeEmail;
        attr['RollNumber'] = awardeeRollNumber;

        try {
            const errors = {};
            // Email validation regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            // Name validation
            if (!awardeeName.trim()) {
                errors.awardeeName = "Name cannot be empty.";
            }

            // Email validation
            if (!awardeeEmail.trim()) {
                errors.awardeeEmail = "Email cannot be empty.";
            } else if (!emailRegex.test(awardeeEmail)) {
                errors.awardeeEmail = "Invalid email format.";
            }

            // Phone number validation
            if (!awardeeRollNumber.trim()) {
                errors.awardeeRollNumber = "Roll number cannot be empty.";
            }



            setErrors(errors);

            // Check if there are any validation errors
            if (Object.keys(errors).length > 0) {
                setLoading(false);
                return;
            }
            // If no id, create a new template
            await Api.createCertificate(template.id, awardeeName, awardeeEmail, awardeeRollNumber, attr);
            navigate(`/templates/${template.id}/certificates`);
        } catch (error) {
            setError('Failed to process certificate. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (!template) {
        return <LoadingPage/>
    }


    return (
        <AppLayout>
            <Container>
                <form onSubmit={handleSubmit}>
                    <Heading>
                        {/* <img src={AwardIcon} alt='icon' /> */}
                        <h4>
                            Awarding certificate {template.name}
                        </h4>
                    </Heading>
                    <FieldsCol>
                        <StepsCol>
                            <Col href={`/templates/${id}/bulkaward`}>
                                <img src={InactiveIcon} alt='Icon'/>
                                <span>Bulk Awards</span>
                            </Col>
                            <Col className='active'>
                                <img src={ActiveIcon} alt='Icon'/>
                                <span>Single Awards</span>
                            </Col>
                        </StepsCol>
                        <FormGroup>
                            <Input
                                label="Name"
                                 astricSign='*'
                                type="horizontalinput"
                                value={awardeeName}
                                onChange={(e) => setAwardeeName(e.target.value)}
                                name="awardeeName"
                                error={errors.awardeeName}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                label="Email ID"
                                 astricSign='*'
                                type="horizontalinput"
                                value={awardeeEmail}
                                onChange={(e) => setAwardeeEmail(e.target.value)}
                                name="awardeeEmail"
                                error={errors.awardeeEmail}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                label="Roll Number"
                                 astricSign='*'
                                type="horizontalinput"
                                value={awardeeRollNumber}
                                onChange={(e) => setAwardeeRollNumber(e.target.value)}
                                name="awardeeRollNumber"
                                error={errors.awardeeRollNumber}
                            />
                        </FormGroup>


                        {allAttributes.filter(attribute => {
                            return ['Name', 'Email', 'RollNumber'].indexOf(attribute) === -1;
                        }).map((attribute, index) => {
                            return (
                                <FormGroup>
                                    <Input
                                        key={index}
                                        label={toProperCase(attribute)}
                                        type="horizontalinput"
                                        value={attributes[attribute]}
                                        onChange={(e) => {
                                            setAttributes((prev) => {
                                                const newAttributes = {...prev};
                                                newAttributes[attribute] = e.target.value;
                                                return newAttributes;
                                            });
                                        }}
                                        name={attribute}
                                    />
                                </FormGroup>
                            );
                        })}
                        <ButtonContainer>
                            <Link to={`/templates/${id}/certificates`}>
                                Cancel
                            </Link>
                            <Button
                                disabled={loading}
                                type={'submit'}
                                loading={loading}
                                label={'Award'}
                            />
                        </ButtonContainer>
                        {error && <div style={{color: 'red'}}>{error}</div>}
                    </FieldsCol>

                </form>
                <ViewContainer>
                    <Heading>
                        <h4>
                            {template.name}
                        </h4>
                    </Heading>
                    <AwardViewContainer>
                        <img
                         src={template.previewImageUrl}
                        //  style={{width:278}}
                        />
                    </AwardViewContainer>
                </ViewContainer>
            </Container>
        </AppLayout>
    );
};

export default AwardCertificate;
