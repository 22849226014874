import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom'; // Import useParams
import Api from "../../../../helpers/Api";
import Button from "../../../../components/form/Button";
import ReadOnlyCanvas from "../../../../components/canvas/ReadOnlyCanvas";
import AppLayout from '../../../../components/layout/AppLayout';
import styled from "styled-components";
import AppTheme from '../../../../themes/AppTheme';
import ActiveIcon from '../../../../images/useractive-fill.svg';
import InactiveIcon from '../../../../images/zap-inactive.svg';
import CsvIcon from '../../../../images/ms_excel.svg';
import DownloadIcon from '../../../../images/download-blue.svg';
import SuccessIcon from '../../../../images/success-icon.svg';
import ErrorIcon from '../../../../images/error-icon.svg';
import ErrorClose from '../../../../images/error-close.svg';
import {Importer, ImporterField} from 'react-csv-importer';

// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer/dist/index.css';
import * as PropTypes from "prop-types";
import LoadingPage from "../../../loading/LoadingPage";
import {getAttributesFromCanvas} from "../../../../helpers/Utils";
import ReadOnlyCanvasV2 from "../../../../components/canvas/ReadOnlyCanvasV2";

export const Container = styled.div`
  width: 100%;
  padding: 0px;
  float: left;

  > form {
    background: ${AppTheme.header.colors.whiteColor};
    padding: 0px;
    width: 65%;
    float: left;
    margin: 0 20px 0 0;
    border-radius: 12px;
    border: 1px solid ${AppTheme.header.colors.tableBorder};
    /* Shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  }
`;

export const Heading = styled.div`
  width: 100%;
  padding: 15px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};

  > h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: ${AppTheme.header.colors.blackColor};
    margin-bottom: 0px;
  }

  > img {
    width: 20px;
    margin-right: 10px;
  }
`;

export const FieldsCol = styled.div`
  width: 100%;
  float: left;
  padding: 20px 24px;
`;

export const StepsCol = styled.div`
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 40px;
  background: ${AppTheme.header.colors.inputBackground};
  padding: 4px;
  margin-top: 10px;
`;

export const Col = styled.a`
  height: 40px;
  background: ${AppTheme.header.colors.inputBackground};
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
  position: relative;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  text-decoration: none;

  > img {
    margin-right: 8px;
  }

  > span {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.15px;
    color: ${AppTheme.header.colors.greyColor};
  }

  &.active {
    background: ${AppTheme.header.colors.blueColor};
    border-radius: 32px;
  }

  &.active {
    > span {
      color: ${AppTheme.header.colors.whiteColor};
    }
  }

`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  > .download-button {
    > a {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      color: ${AppTheme.header.colors.blueColor} !important;
      text-decoration: none;

      > img {
        margin-right: 10px;
      }
    }
  }

  > .action-buttons {
    display: flex;
    align-items: center;

    > a {
      text-decoration: none;
      border-radius: 6px;
      border: 1px solid ${AppTheme.header.colors.inputBorder};
      background: ${AppTheme.header.colors.whiteColor};
      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      margin-right: 15px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: ${AppTheme.header.colors.darkGrey};
    }

    > div {
      margin-top: 0px;

      > button {
        margin-bottom: 0px;
        height: 40px;
        padding: 0 15px;
        border-radius: 6px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }

`;

export const ViewContainer = styled.div`
  width: 30%;
  float: left;
  border-radius: 12px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  /* Shadow/sm */
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
`;

export const BulkUpload = styled.div`
  width: 100%;
  border-radius: 4px;
  background: #DBEBE6;
  margin: 12px 0 24px 0px;
  padding: 24px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BulkInstruction = styled.div`
  text-align: left;
  width: 50%;
  float: left;

  > span {
    color: #05603A;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
  }

  > ul {
    margin: 0;
    list-style: none;
    padding: 0 0 0 18px;

    > li {
      color: #667085;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      list-style: auto;
      padding: 3px 0;
    }
  }
`;

export const UploadContainer = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 279px;
  float: left;
  height: 192px;
  padding: 0 15px;

  

  > label {
    color: ${AppTheme.header.colors.blackColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    border-radius: 2px;
    border: 1.5px dashed var(--Success-700, #027A48);
    background: #F0FCF7;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 279px;
    float: left;
    height: 192px;
    padding: 0 15px;
    > img {
        margin-bottom: 14px;
      }
    > strong {
      color: ${AppTheme.header.colors.lightGreyColor};
      font-size: 14px;
      font-style: normal;
      font-weight: bold !important;;
      line-height: 20px;
    }
  }
`;

export const SuccessContainer = styled.div`
  border-radius: 2px;
  border: 1.5px solid #027A48;
  background: #F0FCF7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 279px;
  float: left;
  height: 192px;
  padding: 0 15px;

  > img {
    margin-bottom: 0px;
  }

  > span {
    color: ${AppTheme.header.colors.blackColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  > .file-box {
    border-radius: 20px;
    background: #186C3C;
    padding: 3px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;

    > span {
      color: ${AppTheme.header.colors.whiteColor};
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
    }
  }

  > a {
    color: ${AppTheme.header.colors.errorTextColor} !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
    margin-top: 6px;
    display: block;
  }
`;
export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  padding: 0px;
  border-radius: 12px;
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  max-width: 500px;
  width:100%;
`;

export const ModalHeader = styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:space-between;
  height:70px;
  padding:0 24px;
  >.header-left{
    >span{
      font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: ${AppTheme.header.colors.errorTextColor};
    padding-left:10px;
    }
  }
  >.header-right{
    >img{
      cursor:pointer;
    }
  }
`;

export const ModalBody = styled.div`
  width:100%;
  padding:0 24px 24px 24px;
  >ul{
    margin:0;
    padding:15px 30px;
    background:#FFF2F1;
    list-style: decimal;
    >li{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: ${AppTheme.header.colors.errorTextColor};
      font-style:italic;
    }
  }
`;

export const CopyButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding:0px 24px 24px 24px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${AppTheme.header.colors.whiteColor};
    height: 44px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    background: ${AppTheme.header.colors.blueColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: ${AppTheme.header.colors.blueColor};
    border: 1px solid ${AppTheme.header.colors.blueColor};
    width: 139px;

    > img {
      margin-right: 6px;
    }
  }
`;

const ImportUI = ({file}) => {
    return <Importer
        dataHandler={async (rows, {startIndex}) => {
            // required, may be called several times
            // receives a list of parsed objects based on defined fields and user column mapping;
            // (if this callback returns a promise, the widget will wait for it before parsing more data)

        }}
        defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
        restartable={false} // optional, lets user choose to upload another file when import is complete
        onStart={({file, preview, fields, columnFields}) => {
            // optional, invoked when user has mapped columns and started import

        }}
        onComplete={({file, preview, fields, columnFields}) => {
            // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)

        }}
        onClose={({file, preview, fields, columnFields}) => {
            // optional, if this is specified the user will see a "Finish" button after import is done,
            // which will call this when clicked

        }}

        // CSV options passed directly to PapaParse if specified:
        // delimiter={...}
        // newline={...}
        // quoteChar={...}
        // escapeChar={...}
        // comments={...}
        // skipEmptyLines={...}
        // delimitersToGuess={...}
        // chunkSize={...} // defaults to 10000
        // encoding={...} // defaults to utf-8, see FileReader API
    >
        <ImporterField name="name" label="Name"/>
        <ImporterField name="email" label="Email"/>
        <ImporterField name="dob" label="Date of Birth" optional/>
        <ImporterField name="postalCode" label="Postal Code" optional/>
    </Importer>;
}

ImportUI.propTypes = {file: PropTypes.any};
const BulkAwardCertificate = () => {
    const {id} = useParams(); // Get the :id parameter from the route
    const navigate = useNavigate();
    const [awardeeName, setAwardeeName] = useState('');
    const [awardeeEmail, setAwardeeEmail] = useState('');

    const [template, setTemplate] = useState(null);

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState('');
    const [file, setFile] = useState(null);


    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            setFile(uploadedFile);
        }
    };

    const removeFile = () => {
        setFile(null);
        setError(null);
    };

    const downloadSampleCSV = () => {
        const columns = ['Awardee Name', 'Awardee Email', 'Roll Number'];

        columns.push(...getAttributesFromCanvas(template.canvasData, ['name', 'email','rollNumber']));
        const csvContent = columns.join(',') + '\n';
        const blob = new Blob([csvContent], {type: 'text/csv'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sample.csv';
        a.click();

    }

    const truncateFileName = (fileName, maxLength) => {
        if (fileName.length <= maxLength) {
            return fileName;
        } else {
            const truncatedFileName = fileName.substring(0, maxLength) + '...';
            return truncatedFileName;
        }
    };
    useEffect(() => {

        setLoading(true);
        Api.getTemplate(id).then(t => {
            setTemplate(t);
            setLoading(false);
        }).catch(err => {
            setError('Failed to load template. Please try again.');
            setLoading(false);
        });

    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
            const text = (e.target.result);
            const rows = text.split('\n').map(row => row.split(','));
            try {
                setLoading(true);
                const response = await Api.createCertificatesBulk(id, rows);
                navigate(`/templates/${id}/certificates`);
            } catch (err) {
                setError(err.response.data.errors);
                setLoading(false);
            }
        };
        fileReader.readAsText(file);

    };

    if (!template) {
        return <LoadingPage/>
    }

    const canvasData = template.canvasData;

    return (
        <AppLayout>
            <Container>
                <form onSubmit={handleSubmit}>
                    <Heading>
                        <h4>
                            Awarding certificate {template.name}
                        </h4>
                    </Heading>
                    <FieldsCol>
                        <StepsCol>
                            <Col className='active'>
                                <img src={ActiveIcon} alt='Icon'/>
                                <span>Bulk Awards</span>
                            </Col>
                            <Col href={`/templates/${id}/award`}>
                                <img src={InactiveIcon} alt='Icon'/>
                                <span>Single Awards</span>
                            </Col>
                        </StepsCol>

                        {/* bulk upload csv */}
                        <BulkUpload>
                            <BulkInstruction>
                                <span>Instructions</span>
                                <ul>
                                    <li>Download sample CSV, and open it in excel or other editing tools.</li>
                                    <li>Edit your csv file with actual values as per the columns to generate the
                                        documents.
                                    </li>
                                    <li>Upload CSV by clicking on the right side, and ensure the successful upload.</li>
                                    <li>Review and Award</li>
                                </ul>
                            </BulkInstruction>

                            {/* Upload Success Container */}
                            {file ? (
                                <SuccessContainer>
                                    <img src={SuccessIcon}/>
                                    <span>Successfully Uploaded</span>
                                    <div className='file-box'>
                                        <span>{truncateFileName(file.name, 25)}</span>
                                    </div>
                                    <a onClick={removeFile}>Remove this File</a>
                                </SuccessContainer>
                            ) : (

                                <UploadContainer>

                                    <label htmlFor="uploadCSV" style={{cursor: 'pointer'}}>
                                        {/* Add custom text here */}
                                        <img src={CsvIcon}/>
                                        <strong>Click to upload</strong> or drag and drop csv file
                                    </label>
                                    <input
                                        type="file"
                                        id="uploadCSV"
                                        accept=".csv"
                                        onChange={handleFileChange}
                                        style={{display: 'none'}}
                                        // Hide the input element
                                    />
                                </UploadContainer>
                            )}
                        </BulkUpload>
                        <ButtonContainer>
                            <div className='download-button'>
                                <Link to={'#'} onClick={e => {
                                    e.preventDefault();
                                    downloadSampleCSV();
                                }}>
                                    <img src={DownloadIcon}/>
                                    Download Sample CSV File
                                </Link>
                            </div>
                            <div className='action-buttons'>
                                <Link to={`/templates/${id}/certificates`}>
                                    Cancel
                                </Link>
                                <Button
                                    disabled={loading}
                                    type={'submit'}
                                    loading={loading}
                                    label={file ? 'Review and Award' : 'Save changes'}
                                />
                            </div>
                        </ButtonContainer>
                        {error && <ModalContainer>
                          <ModalContent>
                              <ModalHeader>
                                <div className='header-left'>
                                  <img src={ErrorIcon} />
                                  <span>Issue Detected:</span>
                                </div>
                                <div className='header-right'>
                                  <img src={ErrorClose} onClick={removeFile} />
                                </div>
                              </ModalHeader>
                              <ModalBody>
                                <ul>
                                    {error.map((e, i) => <li key={i}>{e}</li>)}
                                </ul>
                              </ModalBody>
                              <CopyButton>
                                <button
                                  onClick={removeFile}
                                >
                                  Okay
                                </button>
                              </CopyButton>
                            </ModalContent>
                        </ModalContainer>}
                    </FieldsCol>

                </form>
                <ViewContainer>
                    <Heading>
                        <h4>
                            {template.name}
                        </h4>
                    </Heading>
                    <div>
                        <img src={template.previewImageUrl} style={{width:278}}/>
                    </div>
                </ViewContainer>
            </Container>

        </AppLayout>
    );
};

export default BulkAwardCertificate;
