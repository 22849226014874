const theme =  {
        header: {
            colors: {
                blackColor: '#000000',
                whiteColor: '#ffffff',
                blueColor: '#2970FF',
                greyColor: '#475467',
                lightBlueColor:'#EFF4FF',
                lightGreyColor:'#667085',
                inputBackground:'#F4F4F4',
                errorBackground:'#FECDCA',
                errorTextColor:'#F04438',
                placeholderColor:'#9A9FA5',
                inputBorder:'#D0D5DD',
                bodyBackground:'#F9FAFB',
                tableBorder:'#EAECF0',
                boxBackground:'#F2F4F7',
                darkGrey:'#333333',
                backgroundLight:'#FAFAFA',
                greenColor:'#64AA53',
                disabledTextColor: '#D0D5DD'
            }

    }
}

export default theme;
