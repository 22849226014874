import React, { useEffect, useRef } from 'react';
import styled from "styled-components";
import ArrowIcon from '../../images/drop-black.svg';
import AddIcon from '../../images/add-more-blue.svg';
import AppTheme from '../../themes/AppTheme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;

  > label {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${AppTheme.header.colors.blackColor};
    margin-bottom: 8px;
    text-align: left;
  }
`;

export const Heading = styled.div`
  margin-bottom: 16px;

  > span {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
    color: ${AppTheme.header.colors.blackColor};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px;
  border-radius: 12px;
  background: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.inputBackground}`)};
  height: 48px;
  width: 100%;

  > input {
    border: none;
    height: 48px;
    color: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorTextColor}` : `${AppTheme.header.colors.blackColor}`)};;
    background: transparent;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    outline: none;
    width: 100%;

    &::placeholder {
      color: ${AppTheme.header.colors.placeholderColor};
      font-weight: 600;
    }
  }

  > .icon {
    margin-right: 12px;
  }
`;

function getBorderColor(props) {
    if (props.hasError) {
        return AppTheme.header.colors.errorBackground;
    }
    return AppTheme.header.colors.inputBorder;
}

function getColor(props) {
    if (props.hasError) {
        return AppTheme.header.colors.errorTextColor;
    }
    if (props.disabled) {
        return AppTheme.header.colors.disabledTextColor;
    }
    if(props.defaultColor){
      return AppTheme.header.colors.placeholderColor;
    }
    return AppTheme.header.colors.blackColor;
}

export const HorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  > label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${AppTheme.header.colors.blackColor};
    margin-bottom: 0px;
    text-align: left;
    width: 280px;
    padding-right: 20px;

    > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: ${AppTheme.header.colors.errorTextColor};
    }
  }

  > input {
    border-radius: 2px;
    border: 1px solid ${(props) => getBorderColor(props)};
    background: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.whiteColor}`)};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 50px;
    color: ${(props) => getColor(props)};
    background: transparent;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    outline: none;
    width: 100%;
    padding: 0 10px;

    &::placeholder {
      color: ${AppTheme.header.colors.placeholderColor};
      font-weight: 400;
    }
  }

  > .add-more {
    width: 100%;
    display: flex;
    flex-direction: column;

    > .add-more-col {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      > input {
        border-radius: 2px;
        border: 1px solid ${(props) => getBorderColor(props)};
        background: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.whiteColor}`)};
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height: 50px;
        color: ${(props) => getColor(props)};
        background: transparent;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        outline: none;
        width: 100%;
        padding: 0 10px;

        &::placeholder {
          color: ${AppTheme.header.colors.placeholderColor};
          font-weight: 400;
        }
      }

      > span {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${AppTheme.header.colors.blueColor};
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding-top: 7px;
        cursor: pointer;

        > img {
          margin-right: 8px;
        }
      }

      > span.remove {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 7px;
        color: ${AppTheme.header.colors.errorTextColor};
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;
      }
    }

  }
      > textarea {
      border-radius: 2px;
      border: 1px solid ${(props) => getBorderColor(props)};
      background: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.whiteColor}`)};
      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      height: 110px;
      color: ${(props) => getColor(props)};
      background: transparent;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      outline: none;
      width: 100%;
      padding: 10px 10px 0px 10px;

      &::placeholder {
        color: ${AppTheme.header.colors.placeholderColor};
        font-weight: 400;
      }
    }
  
`;

export const SelectBox = styled.select`
  border-radius: 8px;
  border: 1px solid ${AppTheme.header.colors.inputBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: ${(props) => getColor(props)};
  padding: 0 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 8px center;
  width: 165px;

  option[value=""] {
        color: gray;
  }

  &::placeholder {
    color: ${AppTheme.header.colors.placeholderColor};
    font-weight: 400;
  }

  &:focus {
    outline: none;
  }
  
`;

export const ErrorContainer = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  letter-spacing: -0.12px;
  color: ${AppTheme.header.colors.errorTextColor};
  margin-top: 8px;
`;

export const MultiSelectContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  border: 1px solid ${AppTheme.header.colors.inputBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 200px;
  padding: 5px 10px;
  position: absolute;
  width: 256px;
  z-index: 10000;

  > label {
    padding-bottom: 13px;
    color: ${AppTheme.header.colors.blackColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    > input {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }
  }
`;

const Input = ({
                   type,
                   value,
                   onChange,
                   name,
                   error,
                   options,
                   label,
                   Icon,
                   heading,
                   placeholder,
                   astricSign,
                   defaultText = 'Select',
                   required = false,
                   multi = false,
                   readOnly = false
               }) => {
                const selectRef = useRef(null);

    useEffect(() => {
        const selectElement = selectRef.current;
        if (selectElement) {
            const defaultText = selectElement.options[0];
            if (defaultText && defaultText.value === "") {
                defaultText.style.color = 'gray';
            }
        }
    }, []);
    const renderInput = () => {
        switch (type) {
            case 'textarea':
                return <HorizontalContainer>
                    {label && <label htmlFor={name}>{label} </label>}
                    <textarea value={value} onChange={onChange} name={name} placeholder={placeholder}/>
                </HorizontalContainer>;
            case 'select':
                return (
                    <SelectBox disabled={readOnly} value={value} ref={selectRef} required={required} onChange={onChange} name={name} style={{
                        backgroundImage: `url('${ArrowIcon}')`, color:`${value && value != "" ? '#000' : '#9A9FA5'}`
                    }}>
                        {defaultText &&
                            <option value="">{defaultText} </option>
                        }
                        {options && options.map(option => (
                            <option key={option.value}  value={option.value} >
                                {option.label}
                            </option>
                        ))}
                    </SelectBox>
                );
            case 'date':
                return <input type="date" value={value} onChange={onChange} name={name}/>;
            case 'datetime':
                return <input type="datetime-local" value={value} onChange={onChange} name={name}/>;
            case 'multiselect':
                return <MultiSelectContainer>{options && options.map(option => (
                    <label key={option.value}>
                        <input
                            type="checkbox"
                            name={name}
                            value={option.value}
                            checked={value?.includes(option.value)}
                            onChange={e => {
                                if (e.target.checked) {
                                    onChange({target: {value: [...value, option.value]}})
                                } else {
                                    onChange({target: {value: value.filter(v => v !== option.value)}});
                                }
                            }}
                        />
                        {option.label}
                    </label>
                ))};</MultiSelectContainer>
            case 'checkbox':
                return <input type="checkbox" checked={value} onChange={onChange} name={name}/>;
            case 'horizontalinput':
                if (multi) {
                    value = Array.isArray(value) ? value : [value];
                    return <HorizontalContainer disabled={readOnly} >
                        {label && <label htmlFor={name}>{label}</label>}
                        <div className='add-more'>
                            {
                                value.map((v, index) => {
                                    return <div className='add-more-col'><input type={type} disabled={readOnly} hasError={error} value={v}
                                                                                onChange={(e) => {
                                                                                    const newValues = [...value];
                                                                                    newValues[index] = e.target.value;
                                                                                    onChange({target: {value: newValues}});
                                                                                }} name={name}
                                                                                placeholder={placeholder}/>
                                        {index === value.length - 1 && !readOnly && <span onClick={() => {
                                            onChange({
                                                target: {
                                                    value: [...value, '']
                                                }
                                            });
                                        }}><img src={AddIcon}/>Add More</span>}
                                        {index < value.length - 1 && !readOnly && <span onClick={() => {
                                            const newValues = [...value];
                                            newValues.splice(index, 1);
                                            onChange({target: {value: newValues}});

                                        }}>Remove</span>}
                                    </div>
                                })
                            }
                        </div>
                    </HorizontalContainer>
                }
                return <HorizontalContainer disabled={readOnly} >
                    {label && <label htmlFor={name}>{label} <span>{astricSign}</span></label>}
                    <input disabled={readOnly} type={type} hasError={error} value={value} onChange={onChange} name={name}
                           placeholder={placeholder}/>

                </HorizontalContainer>
            case 'horizontalpasswordinput':
                return <HorizontalContainer>
                    {label && <label htmlFor={name}>{label} <span>{astricSign}</span></label>}
                    <input type='password' hasError={error} value={value} onChange={onChange} name={name}
                           placeholder={placeholder}/>

                </HorizontalContainer>
            default:
                return <InputContainer disabled={readOnly} hasError={error}>
                    {Icon &&
                        <img className='icon' src={Icon} alt='icon'/>
                    }
                    <input disabled={readOnly} type={type} value={value} onChange={onChange} name={name} placeholder={placeholder}/>
                </InputContainer>;
        }
    };

    return (
        <Container>
            {heading &&
                <Heading>
                    <span>{heading}</span>
                </Heading>
            }

            {label && type !== 'horizontalinput' && type !== 'horizontalpasswordinput' && type !== 'textarea' &&
                <label htmlFor={name}>{label}</label>}
            {renderInput()}
            {error && <ErrorContainer>{error}</ErrorContainer>}
        </Container>
    );
};

export default Input;
