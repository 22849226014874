import React from 'react';
import {SectionTab} from "polotno/side-panel";
import {observer} from "mobx-react-lite";
import FaShapes from '@meronex/icons/fa/FaShapes';
import styled from "styled-components";
import AppTheme from "../../../../themes/AppTheme";
import QrCode from '../../../../images/qr-code.svg';
import QRIcon from '../../../../images/bar-white.svg';
import QRCode from 'qrcode';
import * as svg from 'polotno/utils/svg';


export const AttributesContainer = styled.div`
  display: block;
  margin-top: 0px; /* Adjust spacing from the list */
  padding: 10px;
  width: 100%;
  float: left;
  height: 100%;

  > h2 {
    color: ${AppTheme.header.colors.whiteColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 150% */
    letter-spacing: 1.92px;
    text-align: left;
    margin-bottom: 0px;
  }

  > h3 {
    color: ${AppTheme.header.colors.whiteColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 1.92px;
    text-align: left;
    margin-bottom: 0px;
  }

  .attributes {
    text-align: left;

    > .upload-new-container {
      margin-top: 20px;
      float: left;
      width: 100%;

      > label {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color: ${AppTheme.header.colors.blackColor};
        margin-bottom: 10px;
      }
    }

    > label {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      color: ${AppTheme.header.colors.blackColor};
      margin-bottom: 10px;
    }
  }
`;

export const ShapesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  > .col {
    cursor: pointer;
  }
`;

export async function getQR(text) {
    return new Promise((resolve) => {
        QRCode.toString(
            text || 'no-data',
            {
                type: 'svg',
                color: {
                    dark: '#00F', // Blue dots
                    light: '#0000', // Transparent background
                },
            },
            (err, string) => {
                resolve(svg.svgToURL(string));
            }
        );
    });
}

const QRSection = {
    name: 'qr',
    Tab: (props) => (
        <SectionTab name="QR" {...props}>
            <img src={QRIcon} />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({store}) => {
        return (
            <AttributesContainer>
                <div className='attributes'>
                    <label>QR Code </label>
                    <ShapesContainer>
                        <div className='col' onClick={async () => {
                            const val = 'https://verify.chitkara.edu.in/certificate/123456'
                            const src = await getQR(val);

                            store.activePage.addElement({
                                type: 'svg',
                                name: 'qr',
                                colorsReplace: {'x': 'y'},
                                x: 50,
                                y: 50,
                                width: 200,
                                height: 200,
                                src,
                                custom: {
                                    value: val,
                                },
                            });
                        }}>
                            <img src={QrCode} alt='shape1'/>
                        </div>
                    </ShapesContainer>
                </div>

            </AttributesContainer>
        );
    }),
};

export default QRSection;
