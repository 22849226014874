import React from 'react';
import {SectionTab} from "polotno/side-panel";
import {observer} from "mobx-react-lite";
import FaShapes from '@meronex/icons/fa/FaShapes';
import styled from "styled-components";
import AppTheme from "../../../../themes/AppTheme";
import DeleteIcon from '../../../../images/delete-new.svg';


export const AttributesContainer = styled.div`
  display: block;
  margin-top: 0px; /* Adjust spacing from the list */
  padding: 0 10px;
  width: 100%;
  float: left;
  height: 100%;

  > h2 {
    color: ${AppTheme.header.colors.whiteColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 150% */
    letter-spacing: 1.92px;
    text-align: left;
    margin-bottom: 0px;
  }

  > h3 {
    color: ${AppTheme.header.colors.whiteColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 1.92px;
    text-align: left;
    margin-bottom: 0px;
  }

  .attributes {
    text-align: left;

    > .upload-new-container {
      margin-top: 10px;
      float: left;
      width: 100%;

      > label {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        color: ${AppTheme.header.colors.blackColor};
        margin-bottom: 10px;
      }
    }

    > label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      color: ${AppTheme.header.colors.whiteColor};
      margin-bottom: 10px;
    }
  }
`;

export const SelectBox1 = styled.div`
  > button {
    border-radius: 2px;
    background:${AppTheme.header.colors.blackColor};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${AppTheme.header.colors.whiteColor};
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    border:none;
  }
`;

export const UploadedContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: wrap;
  flex-direction: column;
  float: left;
  gap: 10px;
  margin-top:20px;
  > label {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      color: ${AppTheme.header.colors.blackColor};
      margin-bottom: 0px;
      text-align:left;
      width:100%;
    }
  > .col {
    width: 100%;
    float: left;
    position: relative;
    cursor: pointer;
    color: ${AppTheme.header.colors.blackColor};
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    text-transform: capitalize;
    height: 43px;
    background: #EAEAEA;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);

    > .overlay-hover {
      display: none;
    }

    &:hover {
      > .overlay-hover {
        display: flex;
        position: absolute;
        top: 0;
        background: rgba(0, 0, 0, 0.70);
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 111px;
        border: 1px solid ${AppTheme.header.colors.blueColor};

        > a {
          background: ${AppTheme.header.colors.blueColor};
          align-items: center;
          justify-content: center;
          display: flex;
          border: 0.5px solid ${AppTheme.header.colors.blueColor};
          padding: 4.5px 7.5px;
          font-size: 8.938px;
          font-style: normal;
          font-weight: 600;
          color: ${AppTheme.header.colors.whiteColor};
          text-decoration: none;

          > img {
            margin-right: 6px;
          }
        }
      }
    }
  }
`;

const PagesSection = {
    name: 'pages',
    Tab: (props) => (
        <SectionTab name="Pages" {...props} className={`tab-title ${props.active ? 'active' : ''}`}>
            <FaShapes icon="list"/>

        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({store}) => {
        return (
            <AttributesContainer>
                <div className='attributes'>
                  <div className='upload-new-container'>
                        <label>Add new page </label>
                        <SelectBox1>
                            <button onClick={() => {
                                store.addPage({
                                    width: 595,
                                    height: 842,
                                });
                            }}>
                                Add New Page
                            </button>
                        </SelectBox1>
                  </div>
                    {/* Display the full image preview */}

                    <UploadedContainer>
                      <label>My Pages </label>
                        {store.pages.map((page, index) => (
                            <div className='col' key={index}>
                                page {index + 1} <span onClick={e => {
                                store.deletePages([page.id])
                            }}><img src={DeleteIcon} /></span>
                            </div>
                        ))}
                    </UploadedContainer>


                </div>
            </AttributesContainer>
        );
    }),
};

export default PagesSection;
