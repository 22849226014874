import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Api from "../../helpers/Api";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';
import DownloadICon from '../../images/download-grapic.svg';
import DropIcon from '../../images/download-drop-icon.svg';
import Loader from 'react-js-loader';

export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  float: left;
  width: 100%;
  padding: 0;
  display:flex;
  align-items:center;
  justify-content:center;
  height:calc(100vh - 110px);
  >.export-box{
    display:flex;
    flex-direction:column;
    >span{
        color: ${AppTheme.header.colors.blackColor};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
        margin-top:15px;
        display:block;
        text-align:center;
    }
  }
  >.download-box{
    display:flex;
    flex-direction:column;
    >img{
        margin-bottom:15px;
    }
    >span{
        color: ${AppTheme.header.colors.blackColor};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
        margin-bottom:15px;
        display:block;
    }
    >a{
        width:157px;
        height:40px;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius: 6px;
        border: 1px solid ${AppTheme.header.colors.blueColor};
        background: ${AppTheme.header.colors.blueColor};
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        font-size:14px;
        color:${AppTheme.header.colors.whiteColor};
        font-weight:600;
        margin:0 auto;
        >img{
            margin-right:5px;
        }
    }
  }
`;

const ViewExport = () => {
    const {id} = useParams();

    const [job, setJob] = useState(null);



    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.getExportStatus(id);
                setJob(response);
                if(response.status === 'pending' || response.status === 'processing' ){
                    setTimeout(fetchData, 2000);
                }
            } catch (err) {
                console.error(err);
            }
        }

        fetchData();
    }, [id]);

    if(!job){
        return <div>Loading...</div>
    }

    if (job.status === 'completed') {
        return (
            <Container>
                <div className="download-box">
                    <img src={DownloadICon} alt='Icon' />
                    <span>Download your certificate now.</span>
                    <a href={job.downloadURL}>
                        <img src={DropIcon} alt='Icon' />
                        Download
                    </a>
                </div>
            </Container>
        )
    }

    if (job.status === 'failed') {
        return (
            <div>
                Export failed
            </div>
        )
    }

    return (
        <Container>
            <div className="export-box">
                <Loader
                    type="bubble-scale"
                    bgColor={'#000000'}
                    color={'#000000'}
                    size={64}
                />
                <span>Exporting</span>
            </div>
        </Container>
    )
}

export default ViewExport;
