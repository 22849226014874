import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./pages/auth/login/Login";
import ListTemplates from "./pages/templates/ListTemplates";
import CreateTemplate from "./pages/templates/create/CreateTemplate";
import AwardCertificate from "./pages/templates/certificates/award/AwardCertificate";
import PublicCertificateViewer from "./pages/certificates/PublicCertificateViewer";
import AuthCheck from "./pages/auth/AuthCheck";
import ListDepartments from "./pages/departments/ListDepartments";
import CreateDepartment from "./pages/departments/create/CreateDepartment";
import ListCertificates from "./pages/certificates/ListCertificates";
import ListUsers from "./pages/users/ListUsers";
import CreateUser from "./pages/users/create/CreateUser";
import ListRoles from "./pages/roles/ListRoles";
import CreateRole from "./pages/roles/create/CreateRole";
import ViewUser from "./pages/users/view/ViewUser";
import CreateDepartmentUser from "./pages/departments/users/create/CreateDepartmentUser";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/profile/edit/EditProfile";
import Dashboard from "./pages/dashboard/Dashboard";
import ChangePassword from './pages/changePassword/ChangePassword';
import BulkAwardCertificate from './pages/templates/certificates/award/BulkAwardCertificate';
import ListVerifications from "./pages/verifications/ListVerifications";
import PreviewCertificateViewer from "./pages/certificates/PreviewCertificateViewer";
import AppRoutes from "./routes/AppRoutes";
import React from "react";
import ExportCertificate from "./pages/certificates/ExportCertificate";
import DesignV2 from "./pages/templates/design/DesignV2";
import DownloadCertificateV2 from "./pages/certificates/DownloadCertificateV2";
import ViewExport from "./pages/exports/ViewExport";
import ConfigureVerification from "./pages/templates/create/ConfigureVerification";
import ListSchools from "./pages/schools/ListSchools";
import CreateSchool from "./pages/schools/create/CreateSchool";
import ViewVerification from "./pages/verifications/ViewVerification";

function App() {


    return (
        <Router>
            <div className="App">
                <Routes>

                    <Route path="/" element={<AuthCheck/>}/>
                    <Route path="/" element={<AppRoutes/>}>
                        <Route path="dashboard" element={<Dashboard/>}/>
                        <Route path="/templates" element={<ListTemplates/>}/>
                        <Route path="/exports/:id" element={<ViewExport/>}/>
                        <Route path="/templates/:id" element={<CreateTemplate/>}/>
                        <Route path="/templates/create" element={<CreateTemplate/>}/>

                        <Route path="/templates/:id/verification" element={<ConfigureVerification/>}/>

                        <Route path="/templates/:id/award" element={<AwardCertificate/>}/>
                        <Route path="/templates/:id/bulkaward" element={<BulkAwardCertificate/>}/>

                        <Route path="/departments" element={<ListDepartments/>}/>
                        <Route path="/departments/create" element={<CreateDepartment/>}/>
                        <Route path="/departments/:id" element={<CreateDepartment/>}/>

                        <Route path="/schools" element={<ListSchools/>}/>
                        <Route path="/schools/create" element={<CreateSchool/>}/>
                        <Route path="/schools/:id" element={<CreateSchool/>}/>



                        <Route path="/certificates" element={<ListCertificates/>}/>
                        <Route path="/certificates/:type" element={<ListCertificates/>}/>
                        <Route path="/templates/:templateId/certificates/" element={<ListCertificates/>}/>
                        <Route path="/templates/:templateId/certificates/:type" element={<ListCertificates/>}/>

                        <Route path="/users" element={<ListUsers/>}/>
                        <Route path="/users/:id" element={<CreateUser/>}/>
                        <Route path="/users/:id/view" element={<ViewUser/>}/>
                        <Route path="/users/create" element={<CreateUser/>}/>

                        <Route path="/users/:userId/departments/create" element={<CreateDepartmentUser/>}/>
                        <Route path="/users/:userId/departments/:id" element={<CreateDepartmentUser/>}/>

                        <Route path="/roles" element={<ListRoles/>}/>
                        <Route path="/roles/create" element={<CreateRole/>}/>
                        <Route path="/roles/:id" element={<CreateRole/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/profile/edit" element={<EditProfile/>}/>

                        <Route path="/certificates/:id/preview" element={<PreviewCertificateViewer/>}/>


                        <Route path="/password/change" element={<ChangePassword />} />

                        <Route path="/verifications" element={<ListVerifications/>}/>
                        <Route path="/verifications/:id" element={<ViewVerification/>}/>
                    </Route>
                    <Route path="/templates/:id/design" element={<DesignV2/>}/>
                    <Route path="/auth/" element={<AuthCheck/>}/>
                    <Route path="/auth/login" element={<Login/>}/>
                    <Route path="/credential/:id" element={<PublicCertificateViewer/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
