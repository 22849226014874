import React from 'react'
import styled from "styled-components";
import Header from '../header/Header';
import Sidebar from '../../pages/components/sidebar/Sidebar';
import AppTheme from '../../themes/AppTheme';

export const Layout = styled.div`
    width:100%;
`

export const ScreensBodyContainer = styled.div`
    width:calc(100% - 267px);
    max-height:calc(100vh - 0px);
    position:relative;
    float:right;
    overflow:auto;
    height:100vh;
    background:${AppTheme.header.colors.bodyBackground}; 
    @media screen and (max-width: 1240px) {
        width:calc(100% - 220px);
    }
`
export const ScreenBody = styled.div`
    background:${AppTheme.header.colors.bodyBackground}; 
    padding:20px;
    float:left;
    width:100%;
`

const AppLayout = ({ children}) => {
    return <>{children}</>
}

export default AppLayout;
