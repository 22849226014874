import React from 'react';
import {SectionTab} from "polotno/side-panel";
import {observer} from "mobx-react-lite";
import FaShapes from '@meronex/icons/fa/FaShapes';
import MediaList from "./MediaList";
import SignIcon from '../../../../images/sign-white.svg';


const SignsSection = {
    name: 'signs',
    Tab: (props) => (
        <SectionTab name="Signs" {...props}>
            <img src={SignIcon} />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({store}) => {
        return (
                <MediaList type={'sign'} uploadEnabled={true} onClick={media=>{
                    store.activePage?.addElement({
                        type: 'image',
                        src: media.url,
                        width: parseFloat(media.width),
                        height: parseFloat(media.height),
                        x: 100,
                        y: 100
                    });
                }}/>
        );
    }),
};

export default SignsSection;
