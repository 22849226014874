import axios from 'axios';

let domain = window.location.hostname;
let clientId = window.location.hostname;

if(domain === 'localhost'){
    domain = 'icertify.itmatic.io';
    clientId = 'icertify.itmatic.io';
}
export const BASE_URL = 'https://'+domain + '/';
export const API_BASE_URL = BASE_URL + 'api/v1';

function createAxiosInstance(needsAuth = true, needsClientId = true) {
    const headers = {};
    if (needsAuth) headers['Authorization'] = Api.ACCESS_TOKEN;
    if (needsClientId) headers['client'] = clientId;

    return axios.create({
        baseURL: API_BASE_URL,
        headers
    });
}

class Api {
// Common method to create an Axios instance with appropriate headers
    static ACCESS_TOKEN = localStorage.getItem('accessToken'); // This will be set dynamically by getAccessToken method.

// Auth APIs
    static register = async (user, client) => {
        const api = createAxiosInstance(false, false);
        const response = await api.post('/auth/register', {user, client});
        return response.data;
    };

    static login = async (email, password, captcha) => {
        const api = createAxiosInstance(false, true);
        const response = await api.post('/auth/login', {email, password, captcha});
        return response.data;
    };

    static getAccessToken = async (email, password, otp) => {
        const api = createAxiosInstance(false, true);
        const response = await api.post('/auth/token', {email, password, otp});
        Api.ACCESS_TOKEN = response.data.token; // Set the access token for future requests
        return response.data;
    };

    static getProfile = async () => {
        try {
            const api = createAxiosInstance(true, true);
            const response = await api.get('/auth/profile');
            return response.data;
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.removeItem('accessToken');
                window.location.href = '/auth/login';
            }
        }
    };

// Client API
    static getClient = async (subdomain) => {
        const api = createAxiosInstance(false, true);
        const response = await api.get(`/clients/${subdomain}`);
        return response.data;
    };

// Template APIs
    static listTemplates = async () => {
        const api = createAxiosInstance(true, true);
        const response = await api.get('/templates');
        return response.data;
    };

    static getTemplate = async (templateId) => {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/templates/${templateId}`);
        return response.data;
    };

    static createTemplate = async (name, departmentId, schoolId, data, canvasData) => {
        const api = createAxiosInstance(true, true);
        const response = await api.post('/templates', {name, data, canvasData, schoolId, departmentId});
        return response.data;
    };

    static updateTemplate = async (templateId, name, departmentId, schoolId, data, canvasData, previewImageBase64) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/templates/${templateId}`, {
            name,
            data,
            departmentId,
            schoolId,
            canvasData,
            previewImageBase64
        });
        return response.data;
    };

    static publishTemplate = async (templateId) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/templates/${templateId}/publish`);
        return response.data;
    };

    static unpublishTemplate = async (templateId) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/templates/${templateId}/unpublish`);
        return response.data;
    };

    static deleteTemplate = async (templateId) => {
        const api = createAxiosInstance(true, true);
        const response = await api.delete(`/templates/${templateId}`);
        return response.data;
    };

    static duplicateTemplate = async (templateId, name) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/templates/${templateId}/duplicate`, {name});
        return response.data;
    };

// Department APIs
    static listDepartments = async () => {
        const api = createAxiosInstance(true, true);
        const response = await api.get('/departments');
        return response.data;
    };
    static listSchools = async (includeReadOnly = false) => {
        const api = createAxiosInstance(true, true);
        const response = await api.get('/schools',{params: {includeReadOnly}});
        return response.data;
    };
    static listYears = async () => {
        const api = createAxiosInstance(true, true);
        const response = await api.get('/templates/years');
        return response.data;
    };
    static listPrograms = async () => {
        const api = createAxiosInstance(true, true);
        const response = await api.get('/templates/programs');
        return response.data;
    };

    static getDepartment = async (departmentId) => {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/departments/${departmentId}`);
        return response.data;
    };

    static getSchool = async (schoolId) => {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/schools/${schoolId}`);
        return response.data;
    };

    static createDepartment = async (name) => {
        const api = createAxiosInstance(true, true);
        const response = await api.post('/departments', {name});
        return response.data;
    };

    static updateDepartment = async (departmentId, name) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/departments/${departmentId}`, {name});
        return response.data;
    };

    static createSchool = async (name) => {
        const api = createAxiosInstance(true, true);
        const response = await api.post('/schools', {name});
        return response.data;
    };

    static updateSchool = async (schoolId, name) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/schools/${schoolId}`, {name});
        return response.data;
    };

    static deleteDepartment = async (departmentId) => {
        const api = createAxiosInstance(true, true);
        const response = await api.delete(`/departments/${departmentId}`);
        return response.data;
    };

    static deleteSchool = async (schoolId) => {
        const api = createAxiosInstance(true, true);
        const response = await api.delete(`/schools/${schoolId}`);
        return response.data;
    };


    static createCertificate = async (template, awardeeName, awardeeEmail, awardeeRollNumner, attributes) => {
        const api = createAxiosInstance(true, true);
        const response = await api.post('/certificates', {
            template,
            awardeeName,
            awardeeEmail,
            awardeeRollNumner,
            attributes
        });
        return response.data;
    };
    static createCertificatesBulk = async (template, awardees) => {
        const api = createAxiosInstance(true, true);
        const response = await api.post('/certificates/bulk', {template, awardees});
        return response.data;
    };

    static awardCertificate = async (id) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/certificates/${id}/issue`);
        return response.data;
    };
    static awardCertificateMulti = async (ids) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/certificates/issue/multi`, {ids});
        return response.data;
    };
    static exportCertificates = async (ids, type) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/certificates/exports`, {ids, type});
        return response.data;
    };
    static getExportStatus = async (id) => {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/certificates/exports/${id}`);
        return response.data;
    };

    static deleteCertificateMulti = async (ids) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/certificates/delete/multi`, {ids});
        return response.data;
    };

    static revokeCertificate = async (id) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/certificates/${id}/revoke`);
        return response.data;
    };

    static duplicateCertificate = async (id) => {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/certificates/${id}/duplicate`);
        return response.data;
    };


    static getPublicCertificate = async (id) => {
        const api = createAxiosInstance(false, true);
        const response = await api.get(`/public/certificates/${id}`);
        return response.data;
    };

    static getPublicFullCertificate = async (id) => {
        const api = createAxiosInstance(false, true);
        const response = await api.get(`/public/certificates/${id}/full`);
        return response.data;
    };

    static verifyCertificate = async (id, email, otp) => {

        const api = createAxiosInstance(false, true);
        const response = await api.post(`/public/certificates/${id}/verification/verify`, {
            method: 'otp',
            email: email,
            otp: otp,
        });
        return response.data;


    };
    static verifyCertificateFields = async (id, verificationPayload) => {

        const api = createAxiosInstance(false, true);
        const response = await api.post(`/public/certificates/${id}/verification/verify/fields`, verificationPayload);
        return response.data;


    };
    static sendVerificationOtp = async (id, email) => {
        const api = createAxiosInstance(false, true);
        const response = await api.post(`/public/certificates/${id}/verification/otp`, {email});
        return response.data;
    };


    static async listCertificatesForTemplate(id) {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/templates/${id}/certificates`);
        return response.data;
    }

    static async listCertificates(type, templateId) {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/certificates`, {params: {type, templateId}});
        return response.data;
    }

    static async listUsers() {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/users`);
        return response.data;
    }

    static async createUser(email, role, name, phone, departments, disabled, employeeId) {
        const api = createAxiosInstance(true, true);
        const response = await api.post('/users', {email, role, name, phone, departments, disabled, employeeId});
        return response.data;
    }

    static async updateUser(id, email, role, name, phone, departments, disabled, employeeId) {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/users/${id}`, {email, role, name, phone, departments, disabled, employeeId});
        return response.data;
    }

    static async getUser(id) {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/users/${id}`);
        return response.data;
    }


    static async listDepartmentUsers(userId) {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/department-users`, {params: {userId}});
        return response.data;
    }

    static async listRoles() {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/roles`);
        return response.data;
    }

    static async createDepartmentUser(departmentId, userId, roleId) {
        const api = createAxiosInstance(true, true);
        const response = await api.post('/department-users', {departmentId, userId, roleId});
        return response.data;
    }

    static async updateDepartmentUser(id, roleId) {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/department-users/${id}`, {roleId});
        return response.data;
    }

    static async createRole(name, acl = {}) {
        const api = createAxiosInstance(true, true);
        const response = await api.post('/roles', {name, acl});
        return response.data;
    }

    static async updateRole(id, name, acl) {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/roles/${id}`, {name, acl});
        return response.data;
    }

    static async getRole(id) {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/roles/${id}`);
        return response.data;
    }

    static async getDepartmentUser(id) {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/department-users/${id}`);
        return response.data;
    }

    static async updateProfile(user) {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/auth/profile`, user);
        return response.data;
    }

    static async getDashboardAnalytics() {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/analytics/dashboard`);
        return response.data;
    }

    static async listVerifications() {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/verifications`);
        return response.data;
    }


    static async getVerification(id) {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/verifications/${id}`);
        return response.data;
    }

    static async getCertificate(id) {
        const api = createAxiosInstance(true, true);
        const response = await api.get(`/certificates/${id}`);
        return response.data;
    }

    static async uploadFile(data, file, type) {
        const formData = new FormData();
        if (data) {
            Object.keys(data).forEach((k) => {
                formData.append(k, data[k]);
            });
        }
        formData.append('file', file);
        const api = createAxiosInstance(true, true);
        const response = await api.post(`/medias`, formData);
        return response.data;
    }

    static async changePassword(formData) {
        const api = createAxiosInstance(true, true);
        return api.put('/auth/password/change', formData);
    }

    static downloadCertificate = async (id) => {
        const api = createAxiosInstance(false, true);
        const response = await api.get(`/public/certificates/${id}/download`);
        return response.data;
    };

    static listMedias = async (type) => {
        const api = createAxiosInstance(true, true);
        const response = await api.get('/medias', {params: {type}});
        return response.data;
    }

    static scheduleCertificateMulti(ids, scheduledAt) {
        const api = createAxiosInstance(true, true);
        return api.put(`/certificates/issue/schedule`, {ids, scheduledAt});
    }

    static cancelScheduleCertificateMulti(ids) {
        const api = createAxiosInstance(true, true);
        return api.put(`/certificates/issue/schedule/cancel`, {ids});
    }

    static async updateTemplateVerificationConfiguration(id, verificationConfiguration) {
        const api = createAxiosInstance(true, true);
        const response = await api.put(`/templates/${id}/verification`, {verificationConfiguration});
        return response.data;
    }
}

export default Api;
