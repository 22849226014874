import React from "react";
import {BackgroundSection, SectionTab, SidePanel, TextSection} from "polotno/side-panel";
import {ElementsSection} from "polotno/side-panel/side-panel";
import {SidePanelWrap} from "polotno";
import FaShapes from '@meronex/icons/fa/FaShapes';
import {observer} from 'mobx-react-lite';
import ImagesSection from "./ImagesSection";
import SignsSection from "./SignsSection";
import QRSection from "./QRSection";
import PagesSection from "./PagesSection";
import SizesSection from "./SizesSection";
import TableSection from "./TableSection";


const SidePanelCustom = ({store}) => {
    return <SidePanelWrap>
        <SidePanel store={store} defaultSection={'pages'} sections={[
            PagesSection,
            TextSection,
            ElementsSection,
            BackgroundSection,
            ImagesSection,
            SignsSection,
            QRSection,
            TableSection,
            SizesSection
        ]}/>
    </SidePanelWrap>
}

export default SidePanelCustom;
