import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import AppTheme from '../../../../themes/AppTheme';
import { useLocation } from 'react-router-dom';

// Assets
import DashActive from '../../../../images/menu-icons/dashboard-active.svg';
import DashInActive from '../../../../images/menu-icons/dashboard-inactive.svg';
import TemplateActive from '../../../../images/menu-icons/template-active.svg'
import TemplateInactive from '../../../../images/menu-icons/template-inactive.svg';
import SecurityInactive from '../../../../images/menu-icons/secure-inactive.svg';
import SecurityActive from '../../../../images/menu-icons/secure-active.svg';
import Useractive from '../../../../images/menu-icons/user-active.svg';
import UserInactive from '../../../../images/menu-icons/user-inactive.svg';
import DepartmentActive from '../../../../images/menu-icons/department-active.svg';
import DepartmentInactive from '../../../../images/menu-icons/department-inactive.svg';
import CertificateInactive from '../../../../images/menu-icons/certificate-inactive.svg';
import CertificateActive from '../../../../images/menu-icons/certificate-active.svg';
import ProfileActive from '../../../../images/menu-icons/profile-active.svg';
import ProfileInactive from '../../../../images/menu-icons/profile-inactive.svg';
import VerificationActive from '../../../../images/menu-icons/verification-active.svg';
import VerificationInactive from '../../../../images/menu-icons/verification-inactive.svg';
import RoleInactive from '../../../../images/menu-icons/roles-inactive.svg';
import RoleActive from '../../../../images/menu-icons/roles-active.svg';
import NavigationItems from './ChildComponents/NavigationItems';
import AppContext from "../../../../context/AppContext";

export const ModuleBoxes = styled.div``;

export const NavBar = styled.ul`
 margin:0;
 padding:10px 0;
 list-style:none;
 display:flex;
 flex-direction:column;
 justify-content:flex-start;
 flex:1;
 max-height:calc(100vh - 200px);
 overflow:auto;

 &.border-bottom{
    border-bottom:1px solid #98A2B3 !important;
    padding:40px 0;
 }
 &.space-around{
    padding-top:30px;
 }
`;

export const NavBarItem = styled.li`
    padding:0px 16px 4px 16px;
    &.active {
        >a{
            background-color: ${AppTheme.header.colors.blueColor};
            border-radius: 6px;
            padding: 8px;
            >span{
                color:${AppTheme.header.colors.whiteColor};
                font-weight: 600;
            }
            >.inactive-icons{
                display:none;
            }
            >.active-icons{
                display:flex;
                width:20px;
            }           
        }
    }
    >a{
        text-decoration:none;
        height:40px;
        display:flex;
        align-items:center;
        width:198px;
        padding:8px;
        >.active-icons{
            display:none;
        }
        >.inactive-icons{
            display:flex;
            width:20px;
        }

        &:focus{
            outline:none;
        }
    }
    &:last-child{
        border-bottom:none;
    }
`;

const SidebarListItem = () => {

    const [currentPathName, setCurrentPathName] = useState(window.location.pathname);
    const {user} = useContext(AppContext);
    const { pathname } = useLocation();

    const getMenuItems = () => {
        return [
            {
                url: '/dashboard',
                tabname: 'Dashboard',
                inactiveIcon: DashInActive,
                activeIcon: DashActive,
            },
            {
                url: '/departments',
                tabname: 'Departments',
                inactiveIcon: DepartmentInactive,
                activeIcon: DepartmentActive,
            },
            {
                url: '/schools',
                tabname: 'Schools',
                inactiveIcon: DepartmentInactive,
                activeIcon: DepartmentActive,
            },
            {
                url: '/users',
                tabname: 'Users',
                inactiveIcon: UserInactive,
                activeIcon: Useractive,
            },
            {
                url: '/templates',
                tabname: 'Templates',
                inactiveIcon: TemplateInactive,
                activeIcon: TemplateActive,
            },
            {
                url: '/certificates',
                tabname: 'Certificates',
                inactiveIcon: CertificateInactive,
                activeIcon: CertificateActive,
            },
            {
                url: '/verifications',
                tabname: 'Verifications',
                inactiveIcon: VerificationInactive,
                activeIcon: VerificationActive,
            },

        ];
    };

    const getMenuItems1 = () => {
        return [

            {
                url: '/roles',
                tabname: 'Roles',
                inactiveIcon: RoleInactive,
                activeIcon: RoleActive,
            },
            {
                url: '/password/change',
                tabname: 'Security',
                inactiveIcon: SecurityInactive,
                activeIcon: SecurityActive,
            },
            {
                url: '/profile',
                tabname: 'Profile',
                inactiveIcon: ProfileInactive,
                activeIcon: ProfileActive,
            }
        ];
    };


    return (
        <ModuleBoxes>
            <NavBar className='border-bottom'>
                {getMenuItems().filter(({url})=>{
                        if(!user || !user.roles){
                            return true;
                        }
                       if(user.roles.includes('ROLE_USER')){
                            if(url === '/users'){
                                return false;
                            }
                            if(url === '/departments'){
                                return false;
                            }
                        }
                        return true;
                }).map((item, index) => (
                    <NavBarItem key={index} className={pathname.startsWith(item.url) ? 'active' : ''}>
                        <NavigationItems
                            url={item.url}
                            onClick={() => {
                                setCurrentPathName(item.url);
                            }}
                            tabname={item.tabname}
                            inactiveIcon={item.inactiveIcon}
                            activeIcon={item.activeIcon}
                        />
                    </NavBarItem>
                ))}
            </NavBar>
            <NavBar className='space-around'>
                {getMenuItems1().filter(({url})=>{
                    if(!user || !user.roles){
                        return true;
                    }
                    if(user.roles.includes('ROLE_USER')){
                        if(url === '/roles'){
                            return false;
                        }
                        if(url === '/departments'){
                            return false;
                        }
                    }
                    return true;
                }).map((item, index) => (
                    <NavBarItem key={index} className={pathname.startsWith(item.url) ? 'active' : ''}>
                        <NavigationItems
                            url={item.url}
                            onClick={() => {
                                setCurrentPathName(item.url);
                            }}
                            tabname={item.tabname}
                            inactiveIcon={item.inactiveIcon}
                            activeIcon={item.activeIcon}
                        />
                    </NavBarItem>
                ))}
            </NavBar>
        </ModuleBoxes>
    );
};

export default SidebarListItem;
