import React, {useEffect, useState} from "react";
import Api from "../../../../helpers/Api";
import {ProgressBar} from "react-bootstrap";
import styled from "styled-components";
import AppTheme from "../../../../themes/AppTheme";
import { EmbedHeader, ModalContent} from "../../../certificates/ListCertificates";
import DynamicIcon from "../../../../images/dynamic-icon.svg";
import ConfirmationModal from "../../../components/modal/Modal";
import Placeholder from '../../../../images/icons8-image-placeholder-100.png';

export const AttributesContainer = styled.div`
  display: block;
  margin-top: 0px; /* Adjust spacing from the list */
  padding: 10px;
  width: 290px;
  float: left;
  height: 100%;

  > h2 {
    color: ${AppTheme.header.colors.whiteColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 150% */
    letter-spacing: 1.92px;
    text-align: left;
    margin-bottom: 0px;
  }

  > h3 {
    color: ${AppTheme.header.colors.whiteColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 1.92px;
    text-align: left;
    margin-bottom: 0px;
  }

  .attributes {
    text-align: left;

    > .upload-new-container {
      float: left;
      width: 100%;
      margin-top: 0px;

      > label {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        color: ${AppTheme.header.colors.blackColor};
        margin-bottom: 10px;
      }
    }

    > label {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      color: ${AppTheme.header.colors.blackColor};
      margin-bottom: 10px;
      margin-top: 20px;
    }

    > .add-custom-att {
      padding-top: 15px;

      > label {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        color: ${AppTheme.header.colors.whiteColor};
        margin-bottom: 5px;
      }

      > .input-group {
        border-radius: 2px;
        background: #44403C;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        cursor: pointer;
        margin-bottom: 5px;

        > input {
          height: 37px;
          background: transparent;
          border: none;
          width: auto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          color: ${AppTheme.header.colors.whiteColor};
          outline: none;

          &::placeholder {
            color: ${AppTheme.header.colors.placeholderColor};
          }
        }

        > span {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          color: ${AppTheme.header.colors.whiteColor};
        }

        > img {
          width: 20px;
        }
      }

      > .att-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;

        > a {
          color: ${AppTheme.header.colors.whiteColor};
          margin-right: 10px;
          font-size: 12px;
          font-weight: 600;
        }

        > button {
          background: ${AppTheme.header.colors.blueColor};
          color: ${AppTheme.header.colors.whiteColor};
          height: 34px;
          border: none;
          font-size: 12px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          padding: 0 15px;
          border-radius: 4px;
        }
      }
    }

    > .input-group {
      border-radius: 2px;
      background: #44403C;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      cursor: pointer;
      margin-bottom: 5px;

      > input {
        height: 37px;
        background: transparent;
        border: none;
        width: auto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: ${AppTheme.header.colors.whiteColor};
        outline: none;
      }

      > span {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: ${AppTheme.header.colors.whiteColor};
      }

      > img {
        width: 20px;
      }
    }

    > .input-group1 {
      > input {
        height: 37px;
        background: ${AppTheme.header.colors.whiteColor};
        width: 100%;
        outline: none;
      }
    }
  }
`;


export const UploadedContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: wrap;
  float: left;
  gap: 15px;

  > .col {
    width: 46%;
    float: left;
    position: relative;
    cursor: pointer;

    > img {
      width: 120px;
      position: relative;
    }

    > .overlay-hover {
      display: none;
    }

    &:hover {
      > .overlay-hover {
        display: flex;
        position: absolute;
        top: 0;
        background: rgba(0, 0, 0, 0.70);
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 111px;
        border: 1px solid ${AppTheme.header.colors.blueColor};

        > a {
          background: ${AppTheme.header.colors.blueColor};
          align-items: center;
          justify-content: center;
          display: flex;
          border: 0.5px solid ${AppTheme.header.colors.blueColor};
          padding: 4.5px 7.5px;
          font-size: 8.938px;
          font-style: normal;
          font-weight: 600;
          color: ${AppTheme.header.colors.whiteColor};
          text-decoration: none;

          > img {
            margin-right: 6px;
          }
        }
      }
    }
  }
`;

export const SelectBox = styled.div`
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: ${AppTheme.header.colors.boxBackground};
  height: 314px;

  > label {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blueColor};
    cursor: pointer;

  }
`;

export const SelectBox1 = styled.div`
  width: 100%;

  > label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${AppTheme.header.colors.whiteColor};
    border-radius: 2px;
    background: ${AppTheme.header.colors.blackColor};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    height: 43px;
    border: none;

    > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: ${AppTheme.header.colors.whiteColor};
    }
    
  }
  >button{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: ${AppTheme.header.colors.whiteColor};
        border-radius: 2px;
        background: ${AppTheme.header.colors.blackColor};
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        height: 43px;
        border: none;
        width:100%;
    }
`;

export const DescriptionCol = styled.div`
  padding-bottom: 20px;
  display:flex;
  flex-direction:column;
  padding-left:33px;
  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: ${AppTheme.header.colors.lightGreyColor};
  }
  >input{
    height:40px;
    background:transparent;
    border-radius:6px;
    border:1px solid ${AppTheme.header.colors.inputBorder};
    padding:0px 10px;
  }
`;

 export const ButtonAddContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  padding-left:33px;
  >button{
    background:${AppTheme.header.colors.blueColor};
    border:1px solid ${AppTheme.header.colors.blueColor};
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    color:${AppTheme.header.colors.whiteColor};
    border-radius:6px;
  }
 `;

const MediaList = ({type = 'image', uploadEnabled = false, onClick}) => {
    const [medias, setMedias] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [customProperties, setCustomProperties] = useState({name: ''});

    const listMedias = () => {
        Api.listMedias(type).then((response) => {
            setMedias(response);
            setLoading(false);
        });

    }

    const addCustomImage = () => {
        onClick({
            url: Placeholder,
            width: 100,
            height: 100,
            custom: {
                name: customProperties.name,
                type: 'dynamic'
            }
        })
    }

    useEffect(() => {
        listMedias();
    }, []);

    if (loading) {
        return <ProgressBar/>;
    }

    return (
        <AttributesContainer>
            <ConfirmationModal
                isOpen={showModal}
                closeModal={() => {
                    setShowModal(false)
                }}
            >
                <div>
                    <ModalContent>
                        <EmbedHeader>
                            <img src={DynamicIcon}/>
                            <span>Add Dynamic Image</span>
                        </EmbedHeader>
                        <DescriptionCol>
                            <span>Please enter the name of the image</span>
                            <input type="text" value={customProperties.name} onChange={e => {
                            setCustomProperties({...customProperties, name: e.target.value});
                        }} placeholder="Enter name"/>
                        </DescriptionCol>
                        <ButtonAddContainer>
                            <button
                                onClick={() => {
                                    addCustomImage();
                                    setShowModal(false);
                                }}>
                                Add Image
                            </button>
                        </ButtonAddContainer>
                        
                    </ModalContent>
                </div>
            </ConfirmationModal>
            <div className='attributes'>
                <div className='upload-new-container'>
                    <label>Add new image </label>
                    {uploadEnabled && (
                        <SelectBox1>
                            <label htmlFor="multiupload" style={{cursor: 'pointer'}}>
                                <span>Click to upload</span>
                            </label>
                            <input
                                type="file"
                                id="multiupload"  // Allow both images and PDFs
                                style={{display: 'none'}}
                                onChange={(e) => {
                                    setLoading(true);
                                    Api.uploadFile({type}, e.target.files[0]).then(() => {
                                        listMedias()
                                    });
                                }}
                            />
                        </SelectBox1>
                    )}
                </div>

                <div className='upload-new-container' style={{marginTop:20}}>
                    <label>Dynamic Image </label>
                    <SelectBox1>
                        <button onClick={() => {
                            setShowModal(true);
                            }}>Insert Dynamic Image
                        </button>
                    </SelectBox1>
                    
                </div>

                <label>Media List</label>
                <UploadedContainer style={{
                    maxHeight: medias.length > 10 ? '550px' : 'auto',
                    overflowY: medias.length > 8 ? 'auto' : 'visible'
                }}>

                    {medias.map((media) => {
                        return (
                            <div className='col' key={media.id} onClick={e => {
                                onClick && onClick(media);
                            }}>
                                <img src={media.url} alt={media.name}/>
                                {/* <div className='overlay-hover'>
                                <a>
                                    <img src={RemoveIcon} alt="Icon"/>
                                    Remove
                                </a>
                            </div> */}
                            </div>
                        );
                    })}
                </UploadedContainer>

            </div>
        </AttributesContainer>
    );
}

export default MediaList;
