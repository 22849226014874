import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom'; // Import useParams
import Api from "../../../../helpers/Api";
import Input from "../../../../components/form/Input";
import Button from "../../../../components/form/Button";
import AppLayout from '../../../../components/layout/AppLayout';
import styled from "styled-components";
import AppTheme from '../../../../themes/AppTheme';
import SubHeader from '../../../../components/subheader/SubHeader';

export const Container = styled.div`
    width:100%;
    padding:10px 15px 0px 15px;
    >form{
        background:${AppTheme.header.colors.whiteColor};
        padding:0px 30px 20px 30px;
    }
`;

export const FormGroup = styled.div`
    padding:20px 0px;
    border-bottom:1px solid ${AppTheme.header.colors.tableBorder};
    >div{
        margin-bottom:0px;
    }
    >.select-col{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:flex-start;
        >div{
            margin-bottom:0px;
            >select{
                width:100%;
                border-radius: 2px;
                border: 1px solid ${AppTheme.header.colors.inputBorder};
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                height: 50px;
            }
        }
        >label{
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: ${AppTheme.header.colors.blackColor};
            margin-bottom: 0px;
            text-align: left;
            width: 280px;
            padding-right: 20px;
        }
        >strong{
            width:100%;
            text-align:left;
        }
    }
`;

export const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    margin-top:20px;
    >a{
        text-decoration:none;
        border-radius: 6px;
        border: 1px solid  ${AppTheme.header.colors.inputBorder};
        background:${AppTheme.header.colors.whiteColor};
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height:40px;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:0 10px;
        margin-right:15px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color:${AppTheme.header.colors.blackColor};
    }
    >div{
        margin-top:0px;
        >button{
            margin-bottom:0px;
            height:40px;
            padding:0 15px;
            border-radius:6px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
        }
    }
    
`;

const CreateDepartmentUser = () => {
    const {id, userId} = useParams(); // Get the :id parameter from the route


    const navigate = useNavigate();
    const [departmentId, setDepartmentId] = useState('');
    const [roleId, setRoleId] = useState('');
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [user, setUser] = useState([]);

    const [departmentUser, setDepartmentUser] = useState(null);

    const [error, setError] = useState('');

    useEffect(() => {
        fetchUsers();
        fetchDepartments();
        fetchRoles();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await Api.getUser(userId);
            setUser(response);
        } catch (error) {
            setError('Failed to load users. Please try again.');
        }
    }

    const fetchDepartments = async () => {
        try {
            const departments = await Api.listDepartments();
            setDepartments(departments);
        } catch (error) {
            setError('Failed to load departments. Please try again.');
        }
    }

    const fetchRoles = async () => {
        try {
            const roles = await Api.listRoles();
            setRoles(roles);
        } catch (error) {
            setError('Failed to load roles. Please try again.');
        }

    }

    useEffect(() => {
        // If id is present, load the existing departmentUser data
        if (id) {
            setLoading(true);
            Api.getDepartmentUser(id).then(response => {
                setDepartmentUser(response);
                setDepartmentId(response.department.id);
                setRoleId(response.role.id);
                setLoading(false);
            }).catch(err => {
                setError('Failed to load departmentUser. Please try again.');
                setLoading(false);
            });
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let departmentUser;
        try {
            if (id) {
                // If id is present, update the existing departmentUser
                departmentUser = await Api.updateDepartmentUser(id, roleId);
            } else {
                // If no id, create a new departmentUser
                departmentUser = await Api.createDepartmentUser(departmentId, userId, roleId);
            }
            navigate(`/users/${userId}/view`);
        } catch (error) {
            setError('Failed to process departmentUser. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>
    }
    if (id && !departmentUser) {
        return <div>Loading...</div>
    }
    return (
        <AppLayout>
            <SubHeader
                heading={'Komal Garg'}
            />
            <Container>
            <form onSubmit={handleSubmit}>
                <FormGroup>
                        {id ?

                            <div className='select-col'>
                                <label>Department:</label> 
                                <strong>{departmentUser.department.name}</strong>
                            </div>
                        :
                        <div className='select-col'>
                            <label>Department</label>
                            <Input type={'select'} options={
                                departments.map(department => {
                                    return {value: department.id, label: department.name}
                                })
                            } required value={departmentId} onChange={(e) => setDepartmentId(e.target.value)} name="departmentId"
                                />
                            </div>
                        }
                </FormGroup>
                <FormGroup>
                    <div className='select-col'>
                        <label>Role</label>
                        <Input type={'select'} options={
                            roles.map(role => {
                                return {value: role.id, label: role.name}
                            })
                            } required value={roleId} onChange={(e) => setRoleId(e.target.value)} name="roleId"
                        />
                    </div>
                </FormGroup>
                <ButtonContainer>
                    <Link to={`/users/${userId}/view`}>
                        Cancel
                    </Link>
                    <Button 
                        disabled={loading} 
                        type={'submit'} 
                        loading={loading}
                        label={id ? "Update" : "Submit"}
                    />
                </ButtonContainer>
                {error && <div style={{color: 'red'}}>{error}</div>}
            </form>
            </Container>
        </AppLayout>
    );
};

export default CreateDepartmentUser;
