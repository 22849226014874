import React, {useEffect, useState} from "react";
import Api from "../../../helpers/Api";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import {useNavigate,Link} from "react-router-dom";
import AppLayout from "../../../components/layout/AppLayout";
import SubHeader from "../../../components/subheader/SubHeader";
import styled from "styled-components";
import AppTheme from '../../../themes/AppTheme';
import LoadingPage from "../../loading/LoadingPage";

export const Container = styled.div`
    border-radius: 5px;
    border: 1px solid  ${AppTheme.header.colors.tableBorder};
    background:  ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    float:left;
    width:100%;
    padding:0;
    >form{
        background:${AppTheme.header.colors.whiteColor};
        padding:0px 15px 20px 15px;
    }
`;

export const FormGroup = styled.div`
    padding:20px 0px;
    border-bottom:1px solid ${AppTheme.header.colors.tableBorder};
    >div{
        margin-bottom:0px;
        >span{
            width: calc(100% - 224px);
            margin: 8px 0 0 auto;
        }
    }
`;

export const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    margin-top:20px;
    >a{
        text-decoration:none;
        border-radius: 6px;
        border: 1px solid  ${AppTheme.header.colors.inputBorder};
        background:${AppTheme.header.colors.whiteColor};
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height:40px;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:0 10px;
        margin-right:15px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color:${AppTheme.header.colors.darkGrey};
    }
    >div{
        margin-top:0px;
        >button{
            margin-bottom:0px;
            height:40px;
            padding:0 15px;
            border-radius:6px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
        }
    } 
`;

const EditProfile = () => {

    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState('');
    const [errors, setErrors] = useState({})
    const [initialising, setInitialising] = useState(true);

    const fetchUser = async () => {
        try {
            const data = await Api.getProfile();
            setUser(data);
        } catch (err) {
            setError('Failed to fetch user. Please try again later.');
        } finally {
            setInitialising(false);
        }
    }

    useEffect(() => {
        fetchUser();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const errors = {};

        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!user.name) {
            errors.name = "Name cannot be empty.";
        }
        // Check if the email is empty
        if (!user.email) {
            errors.email = "Email  cannot be empty.";
        } else if (!emailRegex.test(user.email)) {
            // Check if the email format is invalid
            errors.email = "Invalid email format.";
        }

        // Phone number validation regex
        const phoneRegex = /^\d+$/;

         
        // Check if the phone number is empty
        if (!user.phone) {
            errors.phone = "Phone number  cannot be empty.";
        } else if (!phoneRegex.test(user.phone)) {
            // Check if the phone number format is invalid
            errors.phone = "Phone number must contain only numeric characters.";
        }

        setErrors(errors);

        // If there are any validation errors, stop the form submission
        if (Object.keys(errors).length > 0) {
            setLoading(false);
            return;
        }
            await Api.updateProfile(user);
            setError('');
            navigate('/profile');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
            setErrors(error.response.data.errors);
            return;
        }
        setError('Failed to update profile. Please try again.');
        } finally {
            setLoading(false);
        }
    }

    if(initialising) return <LoadingPage/>

    return (
        <AppLayout departementSelection={false}>
            <Container>
                <SubHeader
                    heading={'Edit Profile'}
                    description={'Update your information and personalize your profile.'}
                />
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Input value={user?.name} astricSign='*' label="Name" type="horizontalinput" onChange={e => {
                            setUser({...user, name: e.target.value})
                        }} error={errors.name}/>
                    </FormGroup>
                    <FormGroup>
                        <Input value={user?.email} astricSign='*' label="Email" type="horizontalinput" onChange={e => {
                            setUser({...user, email: e.target.value})
                        }} error={errors.email}/>
                    </FormGroup>
                    <FormGroup>
                        <Input value={user?.phone} astricSign='*' label="Phone" type="horizontalinput" onChange={e => {
                            setUser({...user, phone: e.target.value})
                        }} error={errors.phone}/>
                    </FormGroup>
                    <ButtonContainer>
                        <Link to={`/profile`}>
                            Cancel
                        </Link>
                        <Button label="Save" type={'submit'} loading={loading}/>
                    </ButtonContainer>
                    {error && <div>{error}</div>}
                </form>
            </Container>
        </AppLayout>
    );
}

export default EditProfile;
